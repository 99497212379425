import { useMutation, UseMutationOptions } from 'react-query';
import { ScoringAPI } from 'api';

export const useUpdateScoring = (config?: UseMutationOptions) => {
  return useMutation(ScoringAPI.updateScoring, { ...config });
};

export const useAddScoring = (config?: UseMutationOptions) => {
  return useMutation(ScoringAPI.addScoring, { ...config });
};
