import { useQuery } from 'react-query';
import { user, HasuraJwtToken } from '../services/auth.service';
import { graphql } from '../services/request.service';

const QUERY_FEATURE_TOGGLE = `
  query featureToggle($teamid: Int, $costcenterid: Int) {
    feature(
      where: {
        _or: [
          {
            costcenter_feature_toggles: {
              costcenter_id: { _eq: $costcenterid }
              _and: { is_deleted: { _eq: false } }
            }
          }
          {
            user_feature_toggles: {
              team_id: { _eq: $teamid }
              _and: { is_deleted: { _eq: false } }
            }
          }
        ]
        is_active: { _eq: true }
        is_deleted: { _eq: false }
      }
    ) {
      name
    }
  }
`;

const fetchFeatures = (TeamID, CostcenterID) => {
  return graphql(QUERY_FEATURE_TOGGLE, {
    teamid: TeamID,
    costcenterid: CostcenterID
  });
};

const useFeature = featureName => {
  const feature = useQuery(
    'features',
    async (user, featureName, HasuraJwtToken) => {
      if (HasuraJwtToken && user.TeamId && user.CostCenter && featureName) {
        const {
          data: {
            data: { feature }
          }
        } = await fetchFeatures(user.TeamId, user.CostCenter);
        return feature;
      }
    },
    [user, featureName, HasuraJwtToken]
  );

  const isAvailable = Boolean(
    feature.data && feature.data.map(el => el.name).includes(featureName)
  );

  return { ...feature, data: { isAvailable } };
};

export default useFeature;
