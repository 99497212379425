export const AUDIT_LOG_QUERY_SORT = { ['sort.col']: 'timeStamp', ['sort.dir']: 2 };

export const AUDIT_LOG_QUERY_FILTER = {
  teamIds: null,
  description: '',
  editedBy: [],
  timeStampFrom: null,
  timeStampTo: null,
  entryCategories: null,
  sourceIds: null
};

export const PROGRAMS_AUDIT_LOG_QUERY_SORT = { ['sort.col']: 'timeStamp', ['sort.dir']: 2 };

export const PROGRAMS_AUDIT_LOG_QUERY_FILTER = {
  programIds: [],
  teamIds: null,
  description: '',
  editedBy: [],
  timeStampFrom: null,
  timeStampTo: null,
  sourceIds: []
};
