import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PNGIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117782" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117782)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#6C757D"
    />
    <path
      d="M6.09779 15.2612V16.7811H6.5294C7.11388 16.7811 7.4062 16.5245 7.4062 16.0129C7.4062 15.5118 7.11388 15.2612 6.5294 15.2612H6.09779ZM6.09779 17.6137V19.292H5V14.419H6.71967C7.94822 14.419 8.56167 14.9367 8.56167 15.972C8.56167 16.461 8.38558 16.8568 8.03323 17.1596C7.68148 17.4623 7.2108 17.6137 6.6211 17.6137H6.09779Z"
      fill="white"
    />
    <path
      d="M13.6745 19.292H12.5667L10.5581 16.2304C10.4409 16.0512 10.3593 15.9163 10.3135 15.8259H10.2998C10.3185 15.9981 10.3271 16.2609 10.3271 16.6141V19.292H9.29053V14.419H10.4732L12.4069 17.3858C12.4953 17.5198 12.5768 17.652 12.6517 17.7834H12.6652C12.6476 17.6694 12.638 17.4467 12.638 17.1135V14.419H13.6745V19.292Z"
      fill="white"
    />
    <path
      d="M18.8797 18.9645C18.4039 19.2385 17.8125 19.376 17.1057 19.376C16.3212 19.376 15.7038 19.1585 15.2517 18.7252C14.7998 18.2911 14.5737 17.6934 14.5737 16.9321C14.5737 16.1665 14.8201 15.5427 15.3146 15.0598C15.8085 14.577 16.4651 14.336 17.2857 14.336C17.8024 14.336 18.2577 14.4073 18.6519 14.55V15.5801C18.2754 15.3626 17.8159 15.2538 17.2722 15.2538C16.8168 15.2538 16.4453 15.4017 16.1592 15.6967C15.872 15.9925 15.7293 16.3875 15.7293 16.8816C15.7293 17.3828 15.8573 17.7699 16.115 18.044C16.3716 18.3181 16.7193 18.4546 17.1566 18.4546C17.4193 18.4546 17.6273 18.4172 17.7819 18.3424V17.3915H16.8066V16.5145H18.8797V18.9645Z"
      fill="white"
    />
  </SvgIcon>
);

export default PNGIcon;
