import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IdentityIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      d="M9.00808 7.60106C10.2764 6.90829 11.135 5.58144 11.135 4.05654C11.135 1.8156 9.28418 0 7.00016 0C4.71646 0 2.86503 1.8156 2.86503 4.0567C2.86503 5.5816 3.7238 6.90829 4.99192 7.60121C2.43212 8.42816 0.583733 10.7913 0.583252 13.58C0.583252 13.812 0.774856 14 1.01136 14C1.24786 14 1.4393 13.812 1.4393 13.58C1.44459 10.567 3.92855 8.1296 7.00016 8.12425C10.0714 8.1296 12.5552 10.567 12.5605 13.58C12.5605 13.812 12.7521 14 12.9886 14C13.2251 14 13.4166 13.812 13.4166 13.58C13.4161 10.7916 11.5677 8.42847 9.00808 7.60106ZM3.72076 4.0567C3.72364 2.27964 5.1893 0.84236 7.00016 0.838899C8.8115 0.84236 10.2764 2.27964 10.2796 4.0567C10.2762 5.83312 8.8115 7.27088 7.00016 7.27402C5.18946 7.27088 3.72364 5.83312 3.72076 4.0567Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default IdentityIcon;
