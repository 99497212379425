import React, { useState, createContext, useContext } from 'react';

type TAppContext = {
  isClientStorageReload: boolean;
  setIsClientStorageReload: () => void;
};

export const AppContext = createContext({} as TAppContext);

type TAppProvider = {
  children: React.ReactNode;
};

export const AppProvider: React.FC<TAppProvider> = ({ children, ...restProps }) => {
  const [isClientStorageReload, setIsClientStorageReload] = useState(false);

  const value = { isClientStorageReload, setIsClientStorageReload, ...restProps };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
