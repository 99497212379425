import { get, post, put, patch, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/scoring`;

export const getScoreCardBySearch = async ({ requestTypeId, projectTypeId, isAdmin }: any) => {
  return (
    await get({
      url: `${BASE_URL}/score-card-by-search?requestTypeId=${requestTypeId}&projectTypeId=${projectTypeId}&isAdmin=${isAdmin}`
    })
  ).data;
};

export const getScoreCardType = async ({ id, params }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/score-card-types/${id}`, params: { ...params } })).data;
};

export const getScoreTabCardType = async ({ requestId, params }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/score-card-type/${requestId}`, params: { ...params } }))
    .data;
};

export const getRequestScoreCard = async ({ requestId }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/${requestId}` })).data;
};

export const getScoringEnums = async () => {
  return (await get({ url: `${BASE_URL}/score-card-enums` })).data;
};

export const updateScoring = async ({ requestId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/${requestId}`,
      data
    })
  ).data;
};

export const addScoring = async ({ requestId, data }: any) => {
  return (
    await post({
      url: `${BASE_URL}/${requestId}`,
      data
    })
  ).data;
};
