export const mapToOptions = (data: any) => {
  return data.map((el: any) => ({
    value: el.id,
    name: el.name || el.displayValue,
    label: el.displayValue,
    description: el.description || el.displayValue,
    ...el
  }));
};

export const reduceToStatusSideDrawerIntakeFilter = (
  data: { departmentId: number }[],
  departmentId: number
) => {
  return data.reduce((acc: { departmentId: number }[], el) => {
    if (el.departmentId !== departmentId) return acc;
    return [...acc, el];
  }, []);
};
export const mapToOptionsDescription = (data: any) => {
  return data.map((el: any) => ({
    value: el.id,
    name: el.name,
    label: el.description,
    description: el.description,
    ...el
  }));
};

export const mapToOptionsScore = (data: any) => {
  return data.map((el: any) => ({
    value: el.scoreCardTypeId,
    name: el.scoreCardTypeName,
    label: el.description,
    description: el.description,
    ...el
  }));
};

export const mapToOptionsGroup = (data: any, associationId?: any) => {
  return data.map((el: any) => ({
    value: el.groupID,
    name: el.groupName,
    label: el.groupName,
    description: el.groupName,
    ...el,
    associationId: associationId
  }));
};

export const mapToOptionsTeamMember = (data: any) => {
  return data.map((el: any) => ({
    value: el.teamMemberId,
    label: el.displayName,
    ...el
  }));
};

export const mapToOptionsTeamMemberWithId = (data: any) => {
  return data.map((el: any) => ({
    value: el.teamMemberId,
    label: `${el.displayName} (${el.teamMemberId})`,
    ...el
  }));
};

export const mapToOptionsDependencies = (data: any) => {
  return data.map((el: any) => ({
    value: el.prereqId,
    label: el.rowOrder,
    ...el
  }));
};

export const mapToOptionsSprint = (data: any) => {
  return data.map((el: any) => ({
    value: el.sprintNumber,
    label: el.sprintNumber,
    rowVersion: el.rowVersion,
    taskID: el.taskID,
    taskSprintMappingId: el.taskSprintMappingId,
    ...el
  }));
};

export const reduceToOptions = (data: any) => {
  return Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: mapToOptions(data[key]) }), {});
};

export const reduceToOptionsDescription = (data: any) => {
  return Object.keys(data).reduce(
    (acc, key) => ({ ...acc, [key]: mapToOptionsDescription(data[key]) }),
    {}
  );
};

export const reduceToOptionsScore = (data: any) => {
  return Object.keys(data).reduce(
    (acc, key) => ({ ...acc, [key]: mapToOptionsScore(data[key]) }),
    {}
  );
};

export const JSONPatch = (data: any) => {
  return Object.keys(data).reduce((acc, key) => {
    const newVal = data[key];

    if (newVal === undefined) return acc;

    const fieldToPatch = { op: 'replace', path: `/${key}`, value: newVal };

    return [...acc, fieldToPatch];
  }, []);
};

export const mapColorToOptions = (constant: any, data: any) => {
  return data.map((el: any) => ({
    value: el.id,
    label: el.displayValue,
    color: constant[el.id].color
  }));
};
