export const TASK_QUERY_FILTERS = {
  taskName: '',
  percentCompleteLow: '',
  percentCompleteHigh: '',
  durationLow: '',
  durationHigh: '',
  duration: '',
  plannedStartDateFrom: null,
  plannedStartDateTo: null,
  plannedEndDateFrom: null,
  plannedEndDateTo: null,
  teams: [],
  teamMemberIds: [],
  taskStatusIds: [],
  showCompleted: true,
  showDependenciesOnly: false,
  showNotesOnly: false,
  durationComparison: 'equals',
  sprints: [],
  showLateStartOnly: false,
  showPastDueOnly: false,
  showMilestonesOnly: false
};

export const STATUS_COLORS = {
  1: '#AEB3B7',
  2: '#21824D',
  3: '#FFB526',
  4: '#008BC7'
};

export const durationRegexNumberWithLetter = /^([0-9]?)+(\.[0-9]{0,2})?[dwh]?$/;
export const durationRegexNumber = /^([0-9]?)+(\.[0-9]{0,2})?$/;

// export const APPROVED_MEMBERS_TASKS = [
//   16484, // Leo Soloveichik
//   6663, // Henning Visser
//   15766, // Michael Staubly
//   9505, // Josh Boucher
//   9583, // Shashank Amin
//   3969, // Jose Cueva
//   2974, // Dave Brown
//   14543, // Victor Suarez
//   17356, // Kamiar Nazem
//   15987, // Diane Soloveichik
//   14964, // Lou DeSimone
//   16611, // Alan Li
//   16784, // Seth Peelle
//   16616, //Chad Chotikasatien
//   18057, // Miguel De La Torre
//   17830, // Miguel De La Torre
//   15405, // Patrick Moles
//   19099, // Rujuta Soni
//   19705, // Edwin Tan
//   16795, // Samuel Yeaw
//   16727, // David Reid
//   16486, // Pat Hall
//   16174, // Jackson Watkins
//   18851, // Aaron Gumabong
//   16970, // James Hartzell
//   14584, // David Fordyce
//   16430, // Brian Lucero
//   18229, // Carlos Del Valle
//   18239, // Nasrin Omedi
//   16963, // Jennifer Bolander
//   18773, // Jaclyn Wong
//   16601, // Ryan Medes
//   18042, // Mike Pauper
//   16673, // Mark Santos
//   16682, // Otto Sgro
//   15104, // William Huang
//   16962, // Bhavesh Thakkar
//   7787, // Imran Makki
//   8575, // Eric Hu
//   11797, // Tony Bolander
//   10682, // Karen Mitchell
//   15859, // Cynthia Shoff
//   13378, // Danielle Derman
//   13902, // Xiadani Miranda
//   20278 // Christina Gordon
// ];
