import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MSWordXIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117778" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117778)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#185ABD"
    />
    <path
      d="M3.09784 14.9763V18.0656H3.64072C4.11679 18.0656 4.49078 17.9227 4.76083 17.6369C5.03181 17.352 5.1673 16.9631 5.1673 16.4713C5.1673 16.0073 5.03274 15.6416 4.76454 15.3753C4.49635 15.109 4.11957 14.9763 3.63515 14.9763H3.09784ZM2 18.9556V14.0826H3.72609C5.45683 14.0826 6.32267 14.8742 6.32267 16.4583C6.32267 17.2174 6.08696 17.8225 5.61367 18.2763C5.14224 18.7291 4.51212 18.9556 3.72609 18.9556H2Z"
      fill="white"
    />
    <path
      d="M9.36839 14.9447C8.98327 14.9447 8.67795 15.0895 8.45152 15.3781C8.22415 15.6667 8.11093 16.0491 8.11093 16.5251C8.11093 17.0068 8.22415 17.3891 8.45152 17.6703C8.67795 17.9515 8.97491 18.0916 9.34147 18.0916C9.7201 18.0916 10.0199 17.9552 10.2426 17.6824C10.4644 17.4095 10.5748 17.03 10.5748 16.5446C10.5748 16.0407 10.4672 15.6473 10.2519 15.3661C10.0375 15.0849 9.74238 14.9447 9.36839 14.9447ZM9.32106 19.04C8.6232 19.04 8.05433 18.8127 7.61538 18.3589C7.1755 17.9042 6.95557 17.3121 6.95557 16.5827C6.95557 15.8124 7.17828 15.1897 7.62466 14.7137C8.07103 14.2376 8.6631 14 9.39994 14C10.095 14 10.6574 14.2274 11.0861 14.683C11.5158 15.1387 11.7302 15.7391 11.7302 16.4843C11.7302 17.2499 11.5084 17.8671 11.0629 18.3366C10.6175 18.8053 10.0375 19.04 9.32106 19.04Z"
      fill="white"
    />
    <path
      d="M16.1635 18.7811C15.8071 18.9537 15.3431 19.04 14.7696 19.04C14.0226 19.04 13.4342 18.8201 13.0055 18.3802C12.5776 17.9413 12.3633 17.3548 12.3633 16.6235C12.3633 15.844 12.6046 15.212 13.0862 14.7267C13.5669 14.2422 14.1924 14 14.9598 14C15.4359 14 15.8368 14.0604 16.1635 14.1801V15.2371C15.8368 15.0422 15.4656 14.9447 15.048 14.9447C14.5905 14.9447 14.2212 15.0886 13.9409 15.3763C13.6597 15.6639 13.5196 16.0537 13.5196 16.5446C13.5196 17.017 13.6523 17.3919 13.9168 17.6722C14.1822 17.9515 14.5385 18.0916 14.9877 18.0916C15.4155 18.0916 15.8071 17.9877 16.1635 17.7789V18.7811Z"
      fill="white"
    />
    <path
      d="M21.1914 18.9556H19.8904L19.0505 17.3687C19.019 17.3102 18.9865 17.2016 18.9521 17.042H18.9382C18.9224 17.1172 18.8853 17.2304 18.8259 17.3826L17.9833 18.9556H16.6748L18.2283 16.5186L16.8075 14.0826H18.1429L18.8399 15.5433C18.8946 15.6593 18.9429 15.7966 18.9865 15.9553H18.9995C19.0273 15.8597 19.0784 15.7178 19.1526 15.5303L19.9275 14.0826H21.1506L19.6899 16.4991L21.1914 18.9556Z"
      fill="white"
    />
  </SvgIcon>
);

export default MSWordXIcon;
