import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PDFIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117548" maskUnits="userSpaceOnUse" x="1" y="0" width="20" height="24">
      <path
        d="M1.95409 23.6221C1.74726 23.6221 1.5791 23.4541 1.5791 23.2471V0.747768C1.5791 0.540775 1.74726 0.37278 1.95409 0.37278H14.5485L20.3285 6.15286V23.2471C20.3285 23.4541 20.1604 23.6221 19.9535 23.6221H1.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117548)">
      <path
        d="M1.95409 23.6221C1.74726 23.6221 1.5791 23.4541 1.5791 23.2471V0.747768C1.5791 0.540775 1.74726 0.37278 1.95409 0.37278H14.5485L20.3285 6.15286V23.2471C20.3285 23.4541 20.1604 23.6221 19.9535 23.6221H1.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M14.7037 0.000736237H1.95408C1.53984 0.000736237 1.2041 0.336725 1.2041 0.750713V23.25C1.2041 23.664 1.53984 24 1.95408 24H19.9535C20.3677 24 20.7035 23.664 20.7035 23.25V6.00055L14.7037 0.000736237ZM14.3931 0.750713L19.9535 6.31104V23.25H1.95408V0.750713H14.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M20.7039 6.75H14.7041C14.2899 6.75 13.9541 6.41401 13.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M21.2143 21H0.785714C0.35173 21 0 20.552 0 20V14C0 13.448 0.35173 13 0.785714 13H21.2143C21.6482 13 22 13.448 22 14V20C22 20.552 21.6482 21 21.2143 21Z"
      fill="#B52817"
    />
    <path
      d="M6.1355 15.092V16.6634H6.58196C7.18662 16.6634 7.48895 16.3988 7.48895 15.8687C7.48895 15.3512 7.18662 15.092 6.58196 15.092H6.1355ZM6.1355 17.5247V19.2617H5V14.2199H6.77882C8.04933 14.2199 8.68425 14.7563 8.68425 15.8273C8.68425 16.3331 8.50174 16.7426 8.13752 17.0558C7.77335 17.3681 7.28646 17.5247 6.67687 17.5247H6.1355Z"
      fill="white"
    />
    <path
      d="M10.575 15.1451V18.341H11.1375C11.6296 18.341 12.0159 18.1925 12.2958 17.8973C12.5756 17.6021 12.7159 17.1998 12.7159 16.6913C12.7159 16.2116 12.5771 15.8336 12.2993 15.5582C12.0216 15.2819 11.6321 15.1451 11.1304 15.1451H10.575ZM9.43945 19.2617V14.2199H11.2253C13.0162 14.2199 13.9112 15.0398 13.9112 16.6778C13.9112 17.4635 13.6668 18.0899 13.1781 18.5588C12.6896 19.0277 12.0388 19.2617 11.2253 19.2617H9.43945Z"
      fill="white"
    />
    <path
      d="M17.7581 15.1451H15.9792V16.3826H17.6139V17.3033H15.9792V19.2617H14.8438V14.2199H17.7581V15.1451Z"
      fill="white"
    />
  </SvgIcon>
);

export default PDFIcon;
