import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ApprovedCheckmark: React.FC<SvgIconProps> = props => (
  <SvgIcon
    viewBox="0 0 16 16"
    style={{ height: '16px', width: '16px', marginRight: '8px' }}
    {...props}
  >
    <circle cx="8" cy="8" r="7.5" fill="#EBFBF2" stroke="#21824D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9608 7.95699C4.45338 7.4242 3.6415 8.23607 4.17429 8.7435C4.83394 9.42852 5.49359 10.1389 6.17861 10.7986C6.48307 11.0776 6.7114 11.0776 6.99049 10.7986L11.8364 5.97805C12.3438 5.44525 11.5573 4.63338 11.0245 5.16617L6.60992 9.58074L4.9608 7.95699Z"
      fill="#21824D"
    />
  </SvgIcon>
);

export default ApprovedCheckmark;
