import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const EpicIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path d="M4.5 11.5H13L9 2.5L4.5 11.5Z" fill="#E5A322" />
    <path d="M0.5 11.5H7L3.5 5.5L0.5 11.5Z" fill="#E5A322" />
    <path
      d="M10.4286 7.375L11 6.75L9.28571 3L7 7.375L8.14286 8L9.28571 6.75L10.4286 7.375Z"
      fill="white"
    />
    <path
      d="M13.4974 11.75L9.16406 2"
      stroke="#E5A322"
      strokeWidth="0.920833"
      strokeLinecap="round"
    />
    <path
      d="M9.16406 2L4.28906 11.75"
      stroke="#E5A322"
      strokeWidth="0.920833"
      strokeLinecap="round"
    />
    <path d="M0.5 11.75H13.5" stroke="#E5A322" strokeWidth="0.920833" strokeLinecap="round" />
    <path
      d="M5.91406 8.5L4.28906 11.75"
      stroke="#E5A322"
      strokeWidth="0.920833"
      strokeLinecap="round"
    />
    <path d="M3.5 5L5.91406 8.5" stroke="#E5A322" strokeWidth="0.920833" strokeLinecap="round" />
    <path d="M3.5 5L0.5 11.75" stroke="#E5A322" strokeWidth="0.920833" strokeLinecap="round" />
    <path d="M6.99609 4.5L4.99609 8.5" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
  </SvgIcon>
);

export default EpicIcon;
