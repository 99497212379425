import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IssueIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <circle cx="7" cy="7" r="7" fill="#CC4A4A" />
    <path
      d="M7.59982 9.89972C7.59982 10.2313 7.33086 10.5 6.99929 10.5C6.66765 10.5 6.39893 10.2313 6.39893 9.89972C6.39893 9.56784 6.66765 9.29919 6.99929 9.29919C7.33086 9.29927 7.59982 9.56792 7.59982 9.89972Z"
      fill="white"
    />
    <path
      d="M7.82454 4.29839C7.80968 4.12775 7.74396 3.96706 7.64378 3.83522C7.49064 3.63378 7.25668 3.49961 6.99971 3.5C6.75035 3.49969 6.52277 3.62592 6.36955 3.81733C6.26144 3.95236 6.19035 4.11989 6.17479 4.29839C6.17067 4.34552 6.17114 4.39662 6.17479 4.44243L6.51632 8.35419C6.52666 8.47225 6.57846 8.57655 6.65601 8.65418C6.74467 8.74292 6.86694 8.79682 6.99971 8.79667C7.11917 8.79682 7.23016 8.75311 7.31572 8.67977C7.40851 8.60028 7.47151 8.48563 7.48302 8.35411L7.82454 4.44236C7.82827 4.39654 7.82866 4.34552 7.82454 4.29839Z"
      fill="white"
    />
  </SvgIcon>
);

export default IssueIcon;
