import { useMutation, UseMutationOptions } from 'react-query';
import { RequestFilesAPI } from 'api';

export const useUploadFiles = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.uploadFiles, { ...config });
};

export const useCreateFolder = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.createFolder, { ...config });
};

export const useDeleteFileNode = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.deleteFileNode, { ...config });
};

export const useRenameFile = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.renameFile, { ...config });
};

export const useBatch = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.batch, { ...config });
};

export const useMoveFile = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.moveFile, { ...config });
};

export const useCopyFile = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.copyFile, { ...config });
};

export const useSendInvite = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.sendInvite, { ...config });
};

export const useModifyPermissions = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.modifyPermissions, { ...config });
};

export const useDeletePermission = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestFilesAPI.deletePermission, { ...config });
};
