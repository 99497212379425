import { useEffect } from 'react';
import { useLatestRef } from 'hooks';

const useBeforeUnload = fn => {
  const cbRef = useLatestRef(fn);

  useEffect(() => {
    const handleBeforeUnload = e => {
      let returnValue;
      if (cbRef.current != null) returnValue = cbRef.current(e);

      // Chrome requires `returnValue` to be set.
      if (e.defaultPrevented) e.returnValue = '';

      if (typeof returnValue === 'string') {
        e.returnValue = returnValue;
        return returnValue;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export default useBeforeUnload;
