import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 16, // keep right padding when drawer closed
    minHeight: 52,
    backgroundColor: theme.palette.toolbar.main,
    width: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none'
  },
  iconButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.background.paper
  },
  title: {
    flexGrow: 1
  },
  avatar: {
    width: 29,
    height: 29
  },
  playgroundTitle: {
    flexGrow: 1,
    fontWeight: 600
  },
  playgroundToolbar: {
    paddingRight: 16, // keep right padding when drawer closed
    minHeight: 52,
    backgroundColor: '#146390',
    width: '100%'
  },
  devToolbar: {
    paddingRight: 16, // keep right padding when drawer closed
    minHeight: 52,
    backgroundColor: '#A24E9B',
    width: '100%'
  },
  qaToolbar: {
    paddingRight: 16, // keep right padding when drawer closed
    minHeight: 52,
    backgroundColor: '#007983',
    width: '100%'
  },
  pocOneToolbar: {
    paddingRight: 16, // keep right padding when drawer closed
    minHeight: 52,
    backgroundColor: '#7164C3',
    width: '100%'
  },
  pocTwoToolbar: {
    paddingRight: 16, // keep right padding when drawer closed
    minHeight: 52,
    backgroundColor: '#21824D',
    width: '100%'
  },
  avatarBtn: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));
