import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MPPIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_13589_142901" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13589_142901)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#49894B"
    />
    <path
      d="M15.9013 19.52H14.7794V16.5043C14.7794 16.1795 14.7938 15.8196 14.8217 15.4256H14.7938C14.7363 15.7351 14.6832 15.9582 14.6355 16.0932L13.4551 19.52H12.5276L11.3256 16.1282C11.2923 16.0374 11.2401 15.8034 11.1672 15.4256H11.1358C11.1663 15.9231 11.1807 16.3586 11.1807 16.7328V19.52H10.1587V14.48H11.8204L12.8505 17.4679C12.9333 17.7063 12.9927 17.9474 13.0296 18.1876H13.0512C13.1141 17.9087 13.1807 17.6667 13.2509 17.4607L14.281 14.48H15.9013V19.52Z"
      fill="white"
    />
    <path
      d="M8.91013 19.52H7.78824V16.5043C7.78824 16.1795 7.80263 15.8196 7.83052 15.4256H7.80263C7.74505 15.7351 7.69196 15.9582 7.64428 16.0932L6.46391 19.52H5.53634L4.33436 16.1282C4.30107 16.0374 4.24889 15.8034 4.17602 15.4256H4.14454C4.17513 15.9231 4.18952 16.3586 4.18952 16.7328V19.52H3.16748V14.48H4.82918L5.85933 17.4679C5.9421 17.7063 6.00148 17.9474 6.03837 18.1876H6.05996C6.12294 17.9087 6.18951 17.6667 6.25969 17.4607L7.28981 14.48H8.91013V19.52Z"
      fill="white"
    />
    <path
      d="M18.2848 15.3518V16.9227H18.7311C19.3356 16.9227 19.6379 16.6581 19.6379 16.1282C19.6379 15.6109 19.3356 15.3518 18.7311 15.3518H18.2848ZM18.2848 17.7836V19.52H17.1494V14.48H18.9281C20.1984 14.48 20.8327 15.0162 20.8327 16.0869C20.8327 16.5925 20.6501 17.0018 20.2857 17.3149C19.9222 17.6271 19.4355 17.7836 18.8255 17.7836H18.2848Z"
      fill="white"
    />
  </SvgIcon>
);

export default MPPIcon;
