import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ProjectsIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 25 26" {...props}>
    <path d="M23.5208 0.875H1.47917C1.17195 0.875 0.875 1.08034 0.875 1.3871V24.6129C0.875 24.9197 1.17195 25.125 1.47917 25.125H17.7708C17.9302 25.125 18.0782 25.073 18.1887 24.9833C18.1888 24.9833 18.1888 24.9833 18.1889 24.9832L23.9374 20.3393C23.9375 20.3392 23.9376 20.3391 23.9376 20.3391C24.0489 20.2496 24.125 20.1192 24.125 19.9677V1.3871C24.125 1.08033 23.8281 0.875 23.5208 0.875ZM17.1667 19.9677V24.1008H2.08333V1.89919H22.9167V19.4556H17.7708C17.4636 19.4556 17.1667 19.661 17.1667 19.9677ZM18.375 20.4798H22.0105L18.375 23.4168V20.4798Z" />
    <path d="M4 7H21V8H4V7Z" />
    <path d="M4 5H21V6H4V5Z" />
    <path d="M4 17H12V18H4V17Z" />
    <path d="M4 15H21V16H4V15Z" />
    <path d="M4 13H21V14H4V13Z" />
  </SvgIcon>
);

export default ProjectsIcon;
