import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ExcelIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117777" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117777)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#21824D"
    />
    <path
      d="M10.5171 19.292H9.2156L8.37602 17.7051C8.34383 17.6459 8.31165 17.5372 8.27685 17.3788H8.26378C8.24725 17.4536 8.20986 17.5668 8.15157 17.719L7.30851 19.292H6L7.55299 16.8559L6.13312 14.419H7.4686L8.16548 15.8798C8.21942 15.9955 8.26814 16.133 8.31164 16.2913H8.3247C8.35167 16.1965 8.40301 16.0547 8.47783 15.8667L9.253 14.419H10.4763L9.01462 16.8351L10.5171 19.292Z"
      fill="white"
    />
    <path d="M14.0763 19.292H11.1748V14.419H12.2719V18.402H14.0763V19.292Z" fill="white" />
    <path
      d="M14.6587 19.1037V18.0162C14.8562 18.1815 15.0702 18.3059 15.3016 18.3885C15.5322 18.472 15.7654 18.5129 16.0011 18.5129C16.1395 18.5129 16.2595 18.5008 16.3631 18.4755C16.4675 18.4503 16.5518 18.4155 16.6214 18.372C16.691 18.3276 16.7424 18.2754 16.7763 18.2154C16.8102 18.1554 16.8276 18.0901 16.8276 18.0196C16.8276 17.9248 16.7998 17.8395 16.7458 17.7647C16.691 17.6899 16.6162 17.6212 16.5231 17.5577C16.43 17.4941 16.3178 17.4332 16.1882 17.3741C16.0594 17.3149 15.9202 17.2549 15.7706 17.194C15.3895 17.0356 15.1059 16.8416 14.9188 16.6128C14.7318 16.384 14.6387 16.1082 14.6387 15.7837C14.6387 15.5305 14.69 15.3113 14.7918 15.1294C14.8936 14.9476 15.0328 14.7971 15.2077 14.6796C15.3825 14.5613 15.587 14.4752 15.8175 14.4195C16.049 14.3638 16.2934 14.336 16.5518 14.336C16.805 14.336 17.0312 14.3516 17.227 14.3821C17.4219 14.4125 17.6037 14.4595 17.769 14.523V15.5392C17.6872 15.4827 17.5985 15.4322 17.5019 15.3896C17.4053 15.3469 17.3061 15.3113 17.2043 15.2825C17.1026 15.2538 17.0008 15.233 16.9007 15.2199C16.7998 15.206 16.7032 15.199 16.6136 15.199C16.4892 15.199 16.3752 15.2112 16.2734 15.2347C16.1716 15.2591 16.0846 15.2921 16.015 15.3348C15.9454 15.3783 15.8906 15.4296 15.8523 15.4896C15.8132 15.5497 15.7941 15.6175 15.7941 15.6923C15.7941 15.7733 15.8158 15.8463 15.8584 15.9116C15.9011 15.976 15.962 16.0369 16.042 16.0943C16.1221 16.1526 16.2169 16.2091 16.3308 16.2648C16.4448 16.3205 16.5727 16.377 16.7154 16.4362C16.9103 16.518 17.0852 16.6041 17.24 16.6963C17.3949 16.7877 17.5289 16.8912 17.6394 17.0069C17.7499 17.1226 17.8351 17.2549 17.8943 17.4028C17.9543 17.5516 17.983 17.7247 17.983 17.9213C17.983 18.1936 17.9317 18.4216 17.8282 18.606C17.7246 18.7913 17.5854 18.941 17.4088 19.0549C17.2313 19.1689 17.026 19.2516 16.792 19.3012C16.5571 19.3508 16.3091 19.376 16.049 19.376C15.7819 19.376 15.527 19.3534 15.286 19.3081C15.045 19.262 14.8362 19.1942 14.6587 19.1037Z"
      fill="white"
    />
  </SvgIcon>
);

export default ExcelIcon;
