import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MilestoneIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      d="M3.2227 3.49868H5.66167C7.84635 -0.102355 11.1986 -0.2336 13.2329 0.143729C13.55 0.203883 13.7989 0.449967 13.8563 0.767142C14.2336 2.80144 14.1024 6.15365 10.5013 8.33833V10.78C10.5013 11.4745 10.1349 12.1198 9.53613 12.4725L7.1163 13.908C6.91397 14.0283 6.66241 14.0311 6.45734 13.9135C6.25227 13.7959 6.1265 13.5799 6.1265 13.342V10.2058C6.1265 9.58789 5.88041 8.99455 5.44293 8.55707C5.00545 8.11959 4.41211 7.8735 3.79416 7.8735H0.65796C0.422813 7.8735 0.204071 7.74773 0.0864977 7.54266C-0.0310758 7.33759 -0.0283415 7.08604 0.0919663 6.8837L1.52746 4.46387C1.88291 3.86507 2.52546 3.49868 3.21997 3.49868H3.2227ZM11.595 3.49868C11.595 3.20861 11.4798 2.93042 11.2747 2.72531C11.0696 2.5202 10.7914 2.40497 10.5013 2.40497C10.2113 2.40497 9.93307 2.5202 9.72796 2.72531C9.52285 2.93042 9.40762 3.20861 9.40762 3.49868C9.40762 3.78874 9.52285 4.06693 9.72796 4.27204C9.93307 4.47715 10.2113 4.59238 10.5013 4.59238C10.7914 4.59238 11.0696 4.47715 11.2747 4.27204C11.4798 4.06693 11.595 3.78874 11.595 3.49868ZM4.55429 12.8499C3.61917 13.7877 1.80635 13.9709 0.775533 13.9955C0.33805 14.0065 -0.00646737 13.6619 0.0044697 13.2245C0.0290781 12.1936 0.212274 10.3808 1.15013 9.44571C2.09072 8.50512 3.6137 8.50512 4.55429 9.44571C5.49488 10.3863 5.49488 11.9093 4.55429 12.8499ZM3.27739 11.8546C3.58909 11.5429 3.58909 11.0343 3.27739 10.7226C2.96568 10.4109 2.45711 10.4109 2.1454 10.7226C1.86924 10.9988 1.78995 11.5019 1.77081 11.8519C1.75714 12.0706 1.93213 12.2429 2.15087 12.2319C2.50086 12.2128 3.00396 12.1308 3.28012 11.8573L3.27739 11.8546Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default MilestoneIcon;
