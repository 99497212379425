import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const APIErrorIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 400 367" {...props}>
    <path
      d="M25.9341 108.861L27.611 106.268C27.1137 104.894 26.5393 103.549 25.8906 102.239L24.8063 103.115L25.6649 101.788C24.8466 100.176 24.2028 99.1613 24.2028 99.1613C24.2028 99.1613 20.8396 104.463 19.716 110.077L21.8679 113.404L19.4857 111.479C19.4008 112.133 19.3572 112.791 19.355 113.451C19.355 120.099 21.5254 125.488 24.2028 125.488C26.8801 125.488 29.0505 120.099 29.0505 113.451C29.0003 111.335 28.6263 109.24 27.9415 107.239L25.9341 108.861Z"
      fill="#00BFA6"
    />
    <path
      d="M37.8044 114.625L40.8227 113.977C41.4425 112.654 41.9871 111.296 42.454 109.912L41.0679 109.764L42.6133 109.433C43.1736 107.715 43.436 106.541 43.436 106.541C43.436 106.541 37.3104 107.911 32.5481 111.086L31.7182 114.96L31.3942 111.914C30.872 112.317 30.3756 112.751 29.908 113.216C25.2089 117.917 22.9342 123.263 24.8274 125.157C26.7206 127.051 32.0647 124.776 36.7638 120.075C38.2233 118.543 39.4397 116.797 40.3703 114.898L37.8044 114.625Z"
      fill="#00BFA6"
    />
    <path
      d="M38.9068 140.35C31.9077 140.798 24.2813 140.324 16.1682 140.35V121.767C23.3172 122.88 30.941 122.766 38.9068 121.767V140.35Z"
      fill="#3F3D56"
    />
    <path d="M16.0615 162.204H8.90308V331.211H16.0615V162.204Z" fill="#E6E6E6" />
    <path d="M263.62 154.72H255.887V331.241H263.62V154.72Z" fill="#E6E6E6" />
    <path d="M394.376 154.72H255.887V248.255H394.376V154.72Z" fill="#E6E6E6" />
    <path d="M393.909 154.72H386.177V331.241H393.909V154.72Z" fill="#E6E6E6" />
    <path d="M400 140.655H0V154.72H400V140.655Z" fill="#E6E6E6" />
    <path d="M19.6399 155.043H5.323V169.365H19.6399V155.043Z" fill="#E6E6E6" />
    <path
      d="M336.137 163.278L335.331 166.501H315.288L314.08 163.278H263.031V196.221H387.588V163.278H336.137Z"
      fill="#3F3D56"
      fillOpacity="0.25"
    />
    <path
      d="M336.137 204.814L335.331 208.037H315.288L314.08 204.814H263.031V237.756H387.588V204.814H336.137Z"
      fill="#3F3D56"
      fillOpacity="0.25"
    />
    <path d="M224.805 138.572H182.57L184.001 119.953H223.373L224.805 138.572Z" fill="#E6E6E6" />
    <path d="M227.668 136.423H180.423V140.72H227.668V136.423Z" fill="#E6E6E6" />
    <path
      d="M284.578 0H123.155C122.016 0 120.923 0.452692 120.118 1.2585C119.312 2.06431 118.86 3.15723 118.86 4.29681V102.765H288.873V4.29681C288.873 3.15723 288.42 2.06431 287.615 1.2585C286.809 0.452692 285.717 1.86519e-06 284.578 0Z"
      fill="#3F3D56"
    />
    <path
      d="M118.86 102.765V118.878C118.86 120.017 119.312 121.11 120.118 121.916C120.923 122.722 122.016 123.175 123.155 123.175H284.578C285.717 123.175 286.809 122.722 287.615 121.916C288.42 121.11 288.873 120.017 288.873 118.878V102.765H118.86Z"
      fill="#E6E6E6"
    />
    <path d="M280.641 8.95172H127.45V94.8878H280.641V8.95172Z" fill="white" />
    <path
      d="M204.045 117.088C206.417 117.088 208.34 115.164 208.34 112.791C208.34 110.418 206.417 108.494 204.045 108.494C201.673 108.494 199.75 110.418 199.75 112.791C199.75 115.164 201.673 117.088 204.045 117.088Z"
      fill="#3F3D56"
    />
    <path
      d="M271.354 22.5036H255.303C254.208 15.9881 254.208 9.32745 255.303 2.81195H271.354C269.545 9.24397 269.545 16.0716 271.354 22.5036Z"
      fill="#B2DFE3"
    />
    <path
      d="M100.361 80.5375L91.4437 78.363C88.0409 78.3673 84.6797 77.6147 81.6033 76.1598C78.527 74.7049 75.8124 72.5841 73.656 69.9507C71.4996 67.3173 69.9553 64.2373 69.1349 60.9335C68.3145 57.6297 68.2386 54.1849 68.9125 50.8481C68.9025 42.8866 72.0543 35.2472 77.6746 29.6105C83.2949 23.9738 90.9233 20.8015 98.8816 20.7915H98.8816C106.84 20.7815 114.476 23.9346 120.111 29.5571C125.745 35.1797 128.916 42.8112 128.926 50.7727V50.7727C133.982 68.3744 118.147 84.8747 100.361 80.5375Z"
      fill="#2F2E41"
    />
    <path
      d="M110.102 83.6753C122.279 83.6753 132.15 73.7999 132.15 61.6181C132.15 49.4362 122.279 39.5609 110.102 39.5609C97.9248 39.5609 88.0535 49.4362 88.0535 61.6181C88.0535 73.7999 97.9248 83.6753 110.102 83.6753Z"
      fill="#FFB8B8"
    />
    <path
      d="M95.7224 73.1268C95.7224 73.1268 89.012 96.143 85.1775 98.061C81.3431 99.979 115.853 102.856 115.853 102.856C115.853 102.856 114.895 80.7988 116.812 78.8808C118.729 76.9628 95.7224 73.1268 95.7224 73.1268Z"
      fill="#FFB8B8"
    />
    <path
      d="M130.233 215.06C130.233 215.06 173.37 211.224 179.122 225.609C184.874 239.994 175.288 325.346 175.288 325.346H157.074V255.338C157.074 255.338 79.4131 233.254 79.4131 225.582C79.4131 217.91 130.233 215.06 130.233 215.06Z"
      fill="#2F2E41"
    />
    <path
      d="M172.412 321.51L181.999 325.346C181.999 325.346 193.502 324.387 188.709 333.018C187.337 335.523 185.418 337.687 183.094 339.347C180.77 341.007 178.102 342.122 175.288 342.608C175.288 342.608 157.074 349.321 156.116 342.608C155.157 335.895 160.909 322.469 160.909 322.469L172.412 321.51Z"
      fill="#2F2E41"
    />
    <path
      d="M106.267 215.06C106.267 215.06 149.405 211.224 155.157 225.609C160.908 239.994 151.322 325.346 151.322 325.346H133.108V255.338C133.108 255.338 77.9815 242.564 77.9815 234.892C77.9815 231.287 74.2251 212.97 80.8448 209.111C88.3162 204.755 106.267 215.06 106.267 215.06Z"
      fill="#2F2E41"
    />
    <path
      d="M148.447 321.51L158.033 325.346C158.033 325.346 169.536 324.387 164.743 333.018C163.371 335.523 161.452 337.687 159.128 339.347C156.805 341.007 154.137 342.122 151.323 342.608C151.323 342.608 133.109 349.321 132.15 342.608C131.192 335.895 136.943 322.469 136.943 322.469L148.447 321.51Z"
      fill="#2F2E41"
    />
    <path
      d="M86.1357 95.1838L114.944 98.3165C119.39 98.8 123.57 100.677 126.887 103.678C130.203 106.68 132.487 110.653 133.411 115.031L144.612 168.069C144.612 168.069 148.446 220.814 140.777 223.691C133.108 226.568 122.563 212.183 108.184 214.101C93.8047 216.019 75.1116 220.335 75.1116 220.335C75.1116 220.335 72.143 125.922 71.7564 118.2C70.9834 102.763 86.1357 95.1838 86.1357 95.1838Z"
      fill="#575A89"
    />
    <path
      d="M154.377 217.8L166.204 224.768C166.204 224.768 187.422 221.722 186.76 229.183C186.097 236.644 162.936 235.202 162.936 235.202L148.683 226.416L154.377 217.8Z"
      fill="#FFB8B8"
    />
    <path
      d="M67.305 43.6789C75.1708 43.6789 81.5473 37.2998 81.5473 29.4308C81.5473 21.5618 75.1708 15.1827 67.305 15.1827C59.4392 15.1827 53.0627 21.5618 53.0627 29.4308C53.0627 37.2998 59.4392 43.6789 67.305 43.6789Z"
      fill="#2F2E41"
    />
    <path
      d="M80.4469 21.7714C80.4452 19.6638 79.976 17.5829 79.073 15.6786C78.17 13.7744 76.8558 12.0945 75.2252 10.7599C73.5946 9.42541 71.6882 8.46958 69.6436 7.96141C67.599 7.45324 65.4672 7.4054 63.4019 7.82133C65.2242 6.95985 67.2085 6.49531 69.2237 6.45844C71.2389 6.42157 73.2389 6.81321 75.0915 7.60746C76.9441 8.4017 78.607 9.58043 79.9701 11.0657C81.3332 12.5509 82.3656 14.3088 82.9988 16.2231C83.6321 18.1373 83.8518 20.1642 83.6434 22.1698C83.435 24.1753 82.8034 26.1137 81.7901 27.8567C80.7769 29.5998 79.4053 31.1077 77.766 32.2808C76.1266 33.4538 74.257 34.2652 72.2808 34.6614C74.7245 33.5097 76.79 31.6861 78.2361 29.4035C79.6822 27.121 80.449 24.4738 80.4469 21.7714Z"
      fill="#2F2E41"
    />
    <path
      d="M92.6657 29.8517L111.866 26.3149L126.633 35.5735C130.263 37.8496 133.165 41.1197 134.993 44.9957C136.822 48.8718 137.502 53.1912 136.951 57.4418L122.162 53.6918L118.85 45.3847L114.906 51.852L94.2847 78.4007L82.7067 71.0247L72.3848 54.8429L92.6657 29.8517Z"
      fill="#2F2E41"
    />
    <path
      d="M108.477 103.341L118.063 98.5463C118.063 98.5463 138.194 101.423 142.029 114.849C145.863 128.276 163.118 221.299 163.118 221.299L146.822 228.972L123.815 172.39L108.477 103.341Z"
      fill="#575A89"
    />
    <path
      opacity="0.2"
      d="M116.639 141.079L126.233 178.335L143.247 220.176L142.657 221.625L122.365 179.034L116.639 141.079Z"
      fill="black"
    />
    <path
      d="M45.6452 344.851C45.3162 345.785 44.2949 346.279 43.3585 345.956C42.4225 345.634 41.922 344.616 42.2378 343.678L73.6446 250.353C74.0852 249.044 75.5078 248.344 76.8138 248.794C78.1193 249.244 78.8088 250.671 78.3502 251.973L45.6452 344.851Z"
      fill="#797789"
    />
    <path
      d="M169.733 344.851C170.062 345.785 171.084 346.279 172.02 345.956C172.957 345.634 173.457 344.616 173.141 343.677L141.734 250.353C141.294 249.043 139.871 248.344 138.565 248.794C137.26 249.244 136.571 250.67 137.029 251.973L169.733 344.851Z"
      fill="#797789"
    />
    <path
      d="M101.063 364.864C101.066 365.854 101.867 366.657 102.857 366.66C103.847 366.664 104.655 365.867 104.664 364.877L105.734 255.317C105.748 253.936 104.635 252.808 103.254 252.803C101.872 252.799 100.751 253.919 100.755 255.3L101.063 364.864Z"
      fill="#797789"
    />
    <path
      d="M36.7659 190.748L46.5837 230.444C47.9586 236.003 50.8211 241.083 54.864 245.137C58.9068 249.191 63.9772 252.067 69.5307 253.456H69.5308C96.9114 260.304 125.578 260.12 152.868 252.919L167.526 249.052C176.043 246.805 182.448 239.486 183.054 230.695C183.3 227.588 182.737 224.469 181.421 221.643C181.364 221.521 181.306 221.401 181.248 221.281C179.337 217.353 176.388 214.022 172.72 211.65C169.053 209.277 164.806 207.954 160.441 207.822L144.913 207.32L88.3613 182.256C88.3613 182.256 68.9117 172.373 52.9225 171.266C42.3631 170.536 34.2237 180.469 36.7659 190.748Z"
      fill="#66BFC6"
    />
    <path
      d="M184.901 70.327H222.842C223.209 70.325 223.569 70.2284 223.887 70.0466C224.205 69.8648 224.471 69.6039 224.658 69.2893C224.846 68.9748 224.948 68.6172 224.956 68.2515C224.964 67.8857 224.876 67.5241 224.703 67.2019L205.741 32.0582C205.56 31.7213 205.29 31.4398 204.96 31.2436C204.631 31.0473 204.255 30.9437 203.871 30.9437C203.488 30.9437 203.111 31.0473 202.782 31.2436C202.453 31.4398 202.183 31.7213 202.002 32.0582L183.031 67.2019C182.857 67.5248 182.769 67.8872 182.778 68.2538C182.786 68.6203 182.889 68.9785 183.078 69.2933C183.266 69.6081 183.533 69.8688 183.852 70.05C184.172 70.2312 184.533 70.3266 184.901 70.327ZM206.285 63.9052H201.457V59.0911H206.285V63.9052ZM206.285 55.8802H201.457V46.2498H206.285V55.8802Z"
      fill="#F19333"
    />
  </SvgIcon>
);

export default APIErrorIcon;
