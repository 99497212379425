import React, { useContext, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container } from '@material-ui/core';
import { useStyles } from './AppLayout.styles';
import AppToolbar from './AppToolbar/AppToolbar';
import AppDrawer from './AppSidebar/AppDrawer';
import { AppLayoutProvider } from './AppLayout.context';
//@ts-ignore
import { Async } from 'react-query-async';
import Loading from 'components/common/Loading/Loading';
import { UserContext } from 'context/User.context';

interface IAppLayout {
  title?: string;
  children: React.ReactNode;
  user?: any;
  meQuery?: any;
  tokenQuery?: any;
  isStaticSite?: boolean | undefined;
}

const AppLayout: React.FC<IAppLayout> = ({
  user,
  meQuery,
  title,
  children,
  tokenQuery,
  isStaticSite,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppLayoutProvider title={title} user={user} meQuery={meQuery}>
      <div className={classes.root} style={isStaticSite ? { paddingRight: '8px' } : {}}>
        <CssBaseline />
        <AppToolbar />
        {!isStaticSite && <AppDrawer />}

        <main className={classes.content}>
          {/* <Scrollbars
            autoHide
            universal
            style={{ height: '100%', overflowX: 'hidden' }}
            renderTrackHorizontal={props => (
              <div {...props} className="track-horizontal" style={{ display: 'none' }} />
            )}
          > */}
          <div className={classes.appBarSpacer} />

          <Container
            maxWidth={false}
            className={
              isStaticSite ? ' h-screen p-0 m-0 mt-[-52px] overflow-hidden' : classes.container
            }
          >
            {children({ user, title, meQuery, ...rest })}
          </Container>
          {/* </Scrollbars> */}
        </main>
      </div>
    </AppLayoutProvider>
  );
};

export default AppLayout;
