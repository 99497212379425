import { useQuery, useQueryClient } from 'react-query';
import { RequestTasksAPI } from 'api';
import { TQuery } from 'api/api.types';
import { reduceToOptions } from 'api/api.utils';

export const useGetAllTasks = ({ args, ...config }: TQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async () => {
    const { items, ...headers } = await RequestTasksAPI.getAllTasks(args);

    queryClient.setQueryData(['task-headers'], headers);

    return items;
  };

  return useQuery(['requests-all-tasks', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
    cacheTime: 0,
    enabled: config.enabled
  });
};

export const useGetTaskFilterEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestTasksAPI.getTaskFilterEnums());

  return useQuery(['task-filter-enums'], queryFn, {
    staleTime: Infinity,
    ...config
  });
};

export const useGetTaskImplementationMethod = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-impl-method', args],
    () => RequestTasksAPI.getTaskImplementationMethod(args),
    {
      staleTime: 1000 * 60,
      ...config
    }
  );
};

export const useGetTaskNotes = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['task-notes', args], () => RequestTasksAPI.getTaskNotes(args), {
    staleTime: 1000 * 60,
    ...config
  });
};

export const useGetTaskTeams = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['task-teams', args], () => RequestTasksAPI.getTaskTeams(args), {
    staleTime: 1000 * 60,
    ...config
  });
};

export const useGetTaskTeamMembers = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['task-team-members', args], () => RequestTasksAPI.getTaskTeamMembers(args), {
    staleTime: 1000 * 60,
    ...config
  });
};

export const useGetTaskDetailsPercent = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-details-percent', args],
    () => RequestTasksAPI.getTaskDetailsPercent(args),
    {
      staleTime: 0,
      ...config
    }
  );
};

export const useGetTaskDetailsStatus = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['task-details-status', args], () => RequestTasksAPI.getTaskDetailsStatus(args), {
    staleTime: 0,
    ...config
  });
};

export const useGetTaskDetailsDuration = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-details-duration', args],
    () => RequestTasksAPI.getTaskDetailsDuration(args),
    {
      staleTime: 0,
      ...config
    }
  );
};

export const useGetTaskDetailsPSD = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-details-planned-start-date', args],
    () => RequestTasksAPI.getTaskDetailsPSD(args),
    {
      staleTime: 0,
      ...config
    }
  );
};

export const useGetTaskDetailsPED = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-details-planned-end-date', args],
    () => RequestTasksAPI.getTaskDetailsPED(args),
    {
      staleTime: 0,
      ...config
    }
  );
};

export const useGetTaskDetailsDependency = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-details-dependency', args],
    () => RequestTasksAPI.getTaskDetailsDependency(args),
    {
      staleTime: 0,
      ...config
    }
  );
};

export const useGetTaskDetailsTeam = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['task-details-team', args], () => RequestTasksAPI.getTaskDetailsTeam(args), {
    staleTime: 0,
    ...config
  });
};

export const useGetTaskDetailsTeamsOptions = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-details-teams-options', args],
    () => RequestTasksAPI.getTaskDetailsTeamsOptions(args),
    {
      staleTime: 0,
      ...config
    }
  );
};

export const useGetTaskDetailsTeamMembersOptions = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-details-team-members-options', args],
    () => RequestTasksAPI.getTaskDetailsTeamMembersOptions(args),
    {
      staleTime: 0,
      ...config
    }
  );
};

export const useGetSpecailWorkingDays = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['task-special-working-days', args],
    () => RequestTasksAPI.getSpecialWorkingDays(args),
    {
      staleTime: 0,
      ...config
    }
  );
};
// export const useSearchDependencies = ({ args, ...config }: TQuery = {}) => {
//   return useQuery(
//     ['task-dependency-candidates', args],
//     () => RequestTasksAPI.searchDependency(args),
//     {
//       staleTime: 1000 * 60,
//       ...config
//     }
//   );
// };
