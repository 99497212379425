import { UseMutationOptions, useMutation } from 'react-query';
import { DraftAPI } from 'api';

export const useCreateDraft = (config?: UseMutationOptions<any>) => {
  return useMutation(DraftAPI.createDraft, { ...config });
};

export const useUpdateDraft = (config?: UseMutationOptions<any>) => {
  return useMutation(DraftAPI.updateDraft, { ...config });
};

export const useDeleteDraft = (config?: UseMutationOptions<any>) => {
  return useMutation(DraftAPI.deleteDraft, { ...config });
};
