import { useMutation, UseMutationOptions } from 'react-query';
import { RequestTasksAPI } from 'api';

export const useUpdateTaskPercent = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskPercent, { ...config });
};

export const useUpdateTaskStatus = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskStatus, { ...config });
};

export const useUpdateTaskName = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskName, { ...config });
};

export const useUpdateTaskWorkItemType = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskWorkItemType, { ...config });
};

export const useAddTask = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.addTask, { ...config });
};

export const useAddTaskNote = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.addTaskNote, { ...config });
};

export const useUpdateTaskPlannedStartDate = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskPlannedStartDate, { ...config });
};

export const useUpdateTaskPlannedEndDate = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskPlannedEndDate, { ...config });
};

export const useUpdateTaskDuration = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskDuration, { ...config });
};

export const useUpdateTaskSprints = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskSprints, { ...config });
};

export const useUpdateTaskTeam = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskTeam, { ...config });
};

export const useUpdateTaskTeamMembers = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskTeamMembers, { ...config });
};

export const useUpdateTaskNote = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTaskNote, { ...config });
};

export const useDeleteTaskNote = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestTasksAPI.deleteTaskNote, { ...config });
};

export const useDeleteTask = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.deleteTask, { ...config });
};

export const useUpdateDependencies = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateDependencies, { ...config });
};

export const useIndentTask = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.indentTask, { ...config });
};

export const useOutdentTask = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.outdentTask, { ...config });
};

export const usePasteTask = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.pasteTask, { ...config });
};

export const useMoveTask = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.moveTask, { ...config });
};

export const useUpdateTask = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateTask, { ...config });
};

export const useUpdateMethodology = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateMethodology, { ...config });
};

export const useAddMethodology = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.addMethodology, { ...config });
};

export const useAddSpecialWorkingDay = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.addSpecialWorkingDay, { ...config });
};

export const useDeleteSpecialWorkingDay = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.deleteSpecialWorkingDay, { ...config });
};

export const useUpdateMilestone = (config?: UseMutationOptions) => {
  return useMutation(RequestTasksAPI.updateMilestone, { ...config });
};
