import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const TeamPlannerIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M20.1757 15.4679C21.1239 14.8021 21.7443 13.712 21.7443 12.477C21.7443 10.4422 20.0648 8.8 18.0002 8.8C15.9359 8.8 14.2558 10.4422 14.2558 12.4772C14.2558 13.7122 14.8764 14.8021 15.8245 15.468C13.758 16.3065 12.3004 18.3037 12.3 20.64V20.64C12.3 20.9529 12.5574 21.2 12.8669 21.2C13.1765 21.2 13.4336 20.953 13.4338 20.6404C13.4381 18.1716 15.4742 16.1682 18.0002 16.1636C20.526 16.1682 22.5619 18.1716 22.5662 20.6404C22.5664 20.9531 22.8238 21.2 23.1332 21.2C23.4428 21.2 23.7 20.9528 23.7 20.64V20.64C23.6996 18.304 22.2419 16.3067 20.1757 15.4679ZM15.3893 12.4772C15.3918 11.0681 16.5548 9.92202 18.0002 9.91906C19.4459 9.92202 20.6084 11.068 20.6111 12.4772C20.6083 13.8858 19.4459 15.0322 18.0002 15.0349C16.555 15.0322 15.3918 13.8858 15.3893 12.4772Z" />
    <path d="M8.17566 9.46791C9.12389 8.80206 9.74433 7.71204 9.74433 6.47703C9.74433 4.44218 8.06477 2.8 6.00021 2.8C3.93594 2.8 2.25581 4.44215 2.25581 6.47717C2.25581 7.71218 2.87635 8.80207 3.82446 9.46797C1.75804 10.3065 0.300403 12.3037 0.3 14.64V14.64C0.3 14.9529 0.557396 15.2 0.866946 15.2C1.17648 15.2 1.43356 14.953 1.43375 14.6404C1.43809 12.1716 3.47415 10.1682 6.00021 10.1636C8.52597 10.1682 10.5619 12.1716 10.5662 14.6404C10.5664 14.9531 10.8238 15.2 11.1332 15.2C11.4428 15.2 11.7 14.9528 11.7 14.64V14.64C11.6996 12.304 10.2419 10.3067 8.17566 9.46791ZM3.38929 6.47717C3.39176 5.06807 4.55483 3.92202 6.00021 3.91906C7.44592 3.92202 8.60837 5.068 8.61112 6.47715C8.60825 7.88583 7.44588 9.03218 6.00021 9.03488C4.55499 9.03218 3.39176 7.88575 3.38929 6.47717Z" />
  </SvgIcon>
);

export default TeamPlannerIcon;
