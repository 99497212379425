import { useMutation, UseMutationOptions } from 'react-query';
import { ServiceNowAPI } from 'api';

export const useAddTransferIncident = (config?: UseMutationOptions) => {
  return useMutation(ServiceNowAPI.addTransferIncident, { ...config });
};

export const useAddTransferRequest = (config?: UseMutationOptions) => {
  return useMutation(ServiceNowAPI.addTransferRequest, { ...config });
};
