import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ClearIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M9.72199 7.998L14.9725 2.75973C15.2024 2.5298 15.3316 2.21795 15.3316 1.89279C15.3316 1.56762 15.2024 1.25577 14.9725 1.02585C14.7425 0.79592 14.4307 0.666748 14.1055 0.666748C13.7804 0.666748 13.4685 0.79592 13.2386 1.02585L8.00033 6.27633L2.76205 1.02585C2.53213 0.79592 2.22028 0.666748 1.89511 0.666748C1.56995 0.666748 1.2581 0.79592 1.02817 1.02585C0.798248 1.25577 0.669076 1.56762 0.669076 1.89279C0.669076 2.21795 0.798248 2.5298 1.02817 2.75973L6.27866 7.998L1.02817 13.2363C0.913728 13.3498 0.82289 13.4848 0.760899 13.6336C0.698908 13.7824 0.666992 13.942 0.666992 14.1032C0.666992 14.2644 0.698908 14.424 0.760899 14.5728C0.82289 14.7216 0.913728 14.8566 1.02817 14.9701C1.14169 15.0846 1.27673 15.1754 1.42553 15.2374C1.57433 15.2994 1.73392 15.3313 1.89511 15.3313C2.05631 15.3313 2.2159 15.2994 2.3647 15.2374C2.51349 15.1754 2.64854 15.0846 2.76205 14.9701L8.00033 9.71967L13.2386 14.9701C13.3521 15.0846 13.4872 15.1754 13.636 15.2374C13.7847 15.2994 13.9443 15.3313 14.1055 15.3313C14.2667 15.3313 14.4263 15.2994 14.5751 15.2374C14.7239 15.1754 14.859 15.0846 14.9725 14.9701C15.0869 14.8566 15.1778 14.7216 15.2398 14.5728C15.3017 14.424 15.3337 14.2644 15.3337 14.1032C15.3337 13.942 15.3017 13.7824 15.2398 13.6336C15.1778 13.4848 15.0869 13.3498 14.9725 13.2363L9.72199 7.998Z"
      fill="#7D858C"
    ></path>
  </SvgIcon>
);

export default ClearIcon;
