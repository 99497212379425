import { useMutation } from 'react-query';
import { TeamPlannerAPI } from 'api';

export const useUnAssignTeamMember = (config?: any) => {
  return useMutation(TeamPlannerAPI.unAssignTeamMember, { ...config });
};

export const useAssignTeamMembers = (config?: any) => {
  return useMutation(TeamPlannerAPI.assignTeamMembers, {
    ...config
  });
};

export const useEditTeamMembers = (config?: any) => {
  return useMutation(TeamPlannerAPI.editTeamMembers, {
    ...config
  });
};

export const useMarkWorkComplete = (config?: any) => {
  return useMutation(TeamPlannerAPI.markWorkComplete, {
    ...config
  });
};
