import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getEnv } from 'services/apiEnv.service';
import { opts } from '../../../config/auth0.config';

export const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = async (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };
  const domain = getEnv('AUTH0DOMAIN');
  const audience = getEnv('AUTH0AUDIENCE');
  const clientId = getEnv('AUTH0APPCLIENTID'); // Front End SPA POC App
  const arc_domain = getEnv('ARC_AUTH0_DOMAIN');
  const arc_clientid = getEnv('ARC_AUTH0_CLIENTID');
  const arc_webapi_audience = getEnv('ARC_WEBAPI_AUDIENCE');

  const redirectUri = window.location.origin;

  let connection = 'Applied';
  if (window?.Cypress) {
    connection = 'Test-Users';
  }

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain} // Front End SPA POC
      clientId={clientId} // Front End SPA POC
      //   domain={arc_domain} // ARC Web API
      //   clientId={arc_clientid} // ARC Web API
      //   audience={arc_webapi_audience} // ARC Web API
      // redirectUri={redirectUri}
      cacheLocation="localstorage"
      // Auth0-React 2.0 version and up
      authorizationParams={{
        // audience: audience,
        // cacheMode: 'off',
        redirect_uri: redirectUri,
        connection: connection,
        ...opts
      }}
      useRefreshTokens={true}
      // useRefreshTokensFallback={true} //Safari issues
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
