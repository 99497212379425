import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ProgramsIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_2077_9716)">
      <path
        d="M23.78 6.86508C23.64 6.54508 23.42 6.24508 23.15 5.99508L23.13 5.97508C22.58 5.48508 21.86 5.21508 21.1 5.20508H2.9C2.52 5.20508 2.14 5.27508 1.79 5.40508C1.44 5.54508 1.12 5.73508 0.85 5.98508C0.58 6.23508 0.36 6.53508 0.22 6.85508C0.07 7.17508 0 7.53508 0 7.88508V20.5251C0 21.2251 0.31 21.9151 0.85 22.4151C1.39 22.9251 2.13 23.2051 2.9 23.2051H21.1C21.48 23.2051 21.86 23.1451 22.21 23.0051C22.56 22.8751 22.88 22.6751 23.15 22.4251C23.69 21.9251 24 21.2451 24 20.5351V7.89508C24 7.54508 23.93 7.19508 23.78 6.86508ZM22.58 20.5351C22.58 20.8951 22.42 21.2351 22.15 21.4951C22.01 21.6251 21.85 21.7251 21.67 21.7951C21.49 21.8651 21.3 21.8951 21.1 21.8951H2.9C2.51 21.8951 2.13 21.7551 1.85 21.4951C1.58 21.2351 1.42 20.8951 1.42 20.5351V7.89508C1.42 7.71508 1.46 7.53508 1.53 7.36508C1.6 7.19508 1.71 7.05508 1.85 6.92508C1.99 6.80508 2.15 6.70508 2.33 6.62508C2.51 6.56508 2.7 6.52508 2.9 6.53508H21.1C21.49 6.53508 21.87 6.67508 22.15 6.93508C22.28 7.05508 22.39 7.21508 22.47 7.37508C22.54 7.54508 22.58 7.71508 22.58 7.89508V20.5351Z"
        // fill="#007983"
      />
      <path
        d="M0 18.8149V20.2349H1.42V18.8149H0ZM17.79 2.57492C17.74 2.45492 17.68 2.33492 17.61 2.22492C17.61 2.22492 17.61 2.21492 17.6 2.21492C17.48 2.00492 17.34 1.81492 17.16 1.64492L17.14 1.61492C16.66 1.15492 16.05 0.874922 15.39 0.804922C15.3 0.794922 15.2 0.794922 15.11 0.794922H8.89C8.51 0.794922 8.13 0.864922 7.78 1.01492C7.43 1.15492 7.11 1.37492 6.84 1.64492C6.67 1.81492 6.53 2.00492 6.41 2.20492V2.21492H15.12C15.12 2.21492 15.19 2.21492 15.22 2.22492C15.57 2.24492 15.91 2.38492 16.17 2.64492C16.3 2.78492 16.41 2.94492 16.49 3.12492C16.56 3.30492 16.6 3.49492 16.6 3.68492V6.48492H18.02V3.68492C18.02 3.30492 17.95 2.93492 17.8 2.57492H17.79Z"
        // fill="#007983"
      />
      <path
        d="M22.58 18.8249V20.2449H24V18.8249H22.58ZM17.16 1.64492L17.14 1.61492C16.66 1.15492 16.05 0.874922 15.39 0.804922C15.3 0.794922 15.2 0.794922 15.11 0.794922H8.89C8.51 0.794922 8.13 0.864922 7.78 1.01492C7.43 1.15492 7.11 1.37492 6.84 1.64492C6.67 1.81492 6.53 2.00492 6.41 2.20492V2.21492C6.34 2.33492 6.28 2.45492 6.22 2.58492C6.08 2.93492 6 3.31492 6 3.69492V6.48492H7.42V3.69492C7.42 3.49492 7.46 3.30492 7.53 3.12492C7.61 2.94492 7.71 2.78492 7.85 2.64492C7.99 2.50492 8.15 2.39492 8.33 2.32492C8.51 2.25492 8.71 2.21492 8.9 2.21492H17.61C17.49 2.00492 17.35 1.81492 17.17 1.64492H17.16Z"
        // fill="#007983"
      />
    </g>
    <defs>
      <clipPath id="clip0_2077_9716">
        <rect
          width="24"
          height="24"
          // fill="white"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ProgramsIcon;
