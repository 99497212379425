import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const BMPIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117781" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117781)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#6C757D"
    />
    <path
      d="M6.13514 16.8754V18.2033H6.75015C7.01233 18.2033 7.21826 18.1422 7.36693 18.0207C7.51593 17.8993 7.59008 17.7328 7.59008 17.5214C7.59008 17.3199 7.51699 17.1624 7.37044 17.0473C7.22424 16.933 7.01936 16.8754 6.75718 16.8754H6.13514ZM6.13514 14.8367V16.0315H6.63418C6.86859 16.0315 7.05274 15.9748 7.18769 15.8615C7.32265 15.7472 7.38977 15.5907 7.38977 15.3918C7.38977 15.0221 7.11354 14.8367 6.56038 14.8367H6.13514ZM5 19.04V14H6.8345C7.3968 14 7.82907 14.1035 8.1313 14.3095C8.43353 14.5156 8.58465 14.8062 8.58465 15.1813C8.58465 15.453 8.49293 15.6905 8.30877 15.8948C8.12498 16.0981 7.89022 16.2402 7.60415 16.3203V16.3338C7.96261 16.3779 8.24904 16.5111 8.46341 16.7315C8.67779 16.951 8.78497 17.22 8.78497 17.5358C8.78497 17.9973 8.6198 18.3635 8.28945 18.6343C7.9591 18.9051 7.50785 19.04 6.93641 19.04H5Z"
      fill="white"
    />
    <path
      d="M15.4657 19.04H14.3446V16.0243C14.3446 15.6995 14.3586 15.3397 14.3867 14.9456H14.3586C14.3003 15.2551 14.2475 15.4773 14.2005 15.6132L13.0197 19.04H12.0919L10.8899 15.6483C10.8569 15.5574 10.8042 15.3235 10.7318 14.9456H10.7002C10.7304 15.4422 10.7459 15.8786 10.7459 16.2528V19.04H9.72314V14H11.3855L12.4152 16.9879C12.4974 17.2263 12.5571 17.4665 12.5944 17.7085H12.6155C12.6788 17.4287 12.7455 17.1867 12.8158 16.9807L13.8456 14H15.4657V19.04Z"
      fill="white"
    />
    <path
      d="M17.8168 14.8718V16.4427H18.2631C18.8676 16.4427 19.1698 16.1782 19.1698 15.6483C19.1698 15.1309 18.8676 14.8718 18.2631 14.8718H17.8168ZM17.8168 17.3037V19.04H16.6816V14H18.4599C19.7297 14 20.3647 14.5363 20.3647 15.6069C20.3647 16.1125 20.1823 16.5218 19.8183 16.8349C19.4542 17.1471 18.9674 17.3037 18.358 17.3037H17.8168Z"
      fill="white"
    />
  </SvgIcon>
);

export default BMPIcon;
