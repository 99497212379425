import { get, post, put, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';

const BASE_URL = `${getEnv('IT_BASE_URL')}/request-resources`;

export const getResourceGroups = async ({ requestId }) => {
  return (await get({ url: `${BASE_URL}/${requestId}/resource-groups` })).data;
};

export const getDepartmentGroupDataset = async ({ deptId }: any) => {
  return (await get({ url: `${BASE_URL}/departments/${deptId}/group-dataset` })).data;
};

export const getRequestTeamDataset = async ({ requestId }: any) => {
  return (await get({ url: `${BASE_URL}/${requestId}/request-team-dataset` })).data;
};
/*
export const getResourceSubDepartments = async ({ requestId }) => {
  return (await get({ url: `${BASE_URL}/${requestId}/resource-sub-depts` })).data;
};*/

export const addResourceSubDepartments = async ({ requestId, subDeptId }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/resource-sub-depts/${subDeptId}` })).data;
};

export const deleteResourceSubDepartments = async ({ requestId, mappingId }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/resource-sub-depts/${mappingId}` })).data;
};

export const addResourceAreas = async ({ requestId, areaId }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/resource-areas/${areaId}` })).data;
};

export const deleteResourceAreas = async ({ requestId, mappingId }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/resource-areas/${mappingId}` })).data;
};

export const addResourceTeams = async ({ requestId, teamId }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/resource-teams/${teamId}` })).data;
};

export const deleteResourceTeams = async ({ requestId, mappingId }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/resource-teams/${mappingId}` })).data;
};

export const getResourceTeam = async ({ requestId }: any) => {
  const response = await get({ url: `${BASE_URL}/${requestId}/resource-team` });
  return {
    items: response.data,
    count: JSON.parse(response.headers['x-total-count'])
  };
};

export const deleteResourceTeam = async ({ requestId, requestTeamId, data }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/resource-team/${requestTeamId}`, data })).data;
};

export const getProjectManager = async ({ requestId }: any) => {
  return (await get({ url: `${BASE_URL}/${requestId}/project-manager` })).data;
};

export const getProjectLead = async ({ requestId }: any) => {
  return (await get({ url: `${BASE_URL}/${requestId}/project-lead` })).data;
};

export const updateProjectManager = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/project-manager`, data })).data;
};

export const updateProjectLead = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/project-lead`, data })).data;
};

export const updateRequestTeamDataset = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/request-team`, data })).data;
};

export const addResourceTeamMember = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/resource-team`, data })).data;
};

export const getResourceTeamCandidates = (
  requestId: number,
  teamMemberTypeId: number,
  input: string
) => {
  return get({
    url: `${BASE_URL}/${requestId}/resource-team-candidates?teamMemberTypeId=${teamMemberTypeId}&searchTerm=${input.trim()}`
  });
};

export const getTeamMemberGroupSearch = (input: string) => {
  return get({
    url: `${BASE_URL}/team-member-group-search?searchTerm=${input.trim()}`
  });
};

export const sendNotifications = async ({ requestId }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/send-notifications` })).data;
};

export const getIndividualContributors = async ({
  requestId,
  teamMemberId
}: {
  requestId: number;
  teamMemberId: number;
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/request-team/${teamMemberId}/individual-contributors`
    })
  ).data;
};

export const markRequestComplete = async ({
  requestId,
  data
}: {
  requestId: number;
  data: { requestId: number; requestTeamIds: number[]; completed: boolean };
}) => {
  return (await put({ url: `${BASE_URL}/${requestId}/resource-team/mark-complete`, data })).data;
};
