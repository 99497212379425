import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const LogoIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <path d="M1.95858 16.6577C1.25132 18.348 0 20.8544 0 20.8544C0.435241 22.6904 2.61145 23.7979 6.71904 23.7396C10.8538 23.7104 18.2801 22.6904 20.9732 23.1276C22.6053 23.3899 23.7206 23.5356 23.9927 23.7396C22.1701 22.4864 19.0418 21.9327 16.5936 21.8744C13.8189 21.8161 2.74746 21.9327 1.95858 16.6577Z" />
    <path d="M11.969 0.25C11.969 0.25 6.96374 11.3537 6.74612 11.7908C6.5013 12.7526 7.53499 14.6469 8.81351 15.5212C11.5882 17.4155 13.846 17.095 17.5999 19.4847C18.1984 19.8636 15.4237 18.6979 13.7916 18.3481C11.425 17.8527 10.3641 17.8527 7.94303 16.3955C5.16837 14.7343 4.73313 10.1879 4.73313 10.1879L2.61133 15.1715C2.88335 17.4447 5.71242 20.6213 16.0494 21.0585C18.6881 21.1751 21.1363 21.5248 23.9926 23.7397C24.3462 24.002 11.969 0.25 11.969 0.25Z" />
  </SvgIcon>
);

export default LogoIcon;
