export const SCORE_CARD_CONSTANTS = {
  '1': 'Process Improvement',
  '2': 'Team Value',
  '3': 'Annual Cost Savings',
  '4': 'Risk',
  '5': 'Senior Staff Directive - App Dev',
  '6': 'Complexity - App Dev',
  '7': 'Number of Users Affected',
  '8': 'Risk/Urgency',
  '9': 'Senior Staff Directive - Infrastructure',
  '10': 'Complexity - Infrastructure'
};

export const DEFAULT_VALUES = {
  priorityScore: '',
  scorecardType: { value: null, label: '' },
  criteria: {
    '% Users Affected': '',
    'Annual Cost Savings': '',
    'Risk/Urgency': '',
    'Senior Staff Directive - Infrastructure': '',
    'Team Value': '',
    'Complexity - Infrastructure': '',
    'Complexity - App Dev': '',
    'Process Improvement': '',
    Risk: '',
    'Senior Staff Directive - App Dev': ''
  }
};
