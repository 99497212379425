type REQUEST_COST = {
  er: string;
  auc: string;
  capitalized: string;
  assetControlFormCompleted: string;
  authorizationApproved: string;
  authorizationApprovedDate: string | null | Date | undefined;
  assetControlFormApprovalDate: string | null | Date | undefined;
};

export const DEFAULT_REQUEST_COST: REQUEST_COST = {
  er: '',
  auc: '',
  capitalized: '1',
  assetControlFormCompleted: '1',
  authorizationApproved: '1',
  authorizationApprovedDate: '',
  assetControlFormApprovalDate: ''
};
