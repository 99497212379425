module.exports = {
  DEV_MODE: true,
  IS_PREVIEW: false,
  AUTH_BASE_URL: 'https://apidev.appliedmed.com/Authdev',
  // AUTH_BASE_URL: 'https://my-api-dev.appliedmed.com/Auth',
  // BASE_URL: "https://apidev.appliedmed.com/MyApplied",
  // SWITCH TO THIS ENDPOINT IF NOT ON COMPANY NETWORK
  // BASE_URL: 'https://api.appliedmedical.com/MyApplied',
  BASE_URL: 'https://my-api-dev.appliedmed.com/MyApplied',
  CONTENT_URL: 'https://webassetsdev.appliedmedical.com/content',

  // Auth0
  AUTH0DOMAIN: 'dev-kwk94vpz.us.auth0.com',
  AUTH0APPCLIENTID: 'GwXhjxVhk68wWRmVe2sE1uFUMzybkX44',
  AUTH0AUDIENCE: 'https://dev-kwk94vpz.us.auth0.com/api/v2/',
  ARC_AUTH0_DOMAIN: 'dev-kwk94vpz.us.auth0.com',
  ARC_AUTH0_CLIENTID: '9UnnoXpcg6S70WwSuxz9z9leogPgsAEJ',
  ARC_WEBAPI_AUDIENCE: 'https://api.appliedmedical.com/ARC',

  // IT_BASE_URL: 'https://apidev.appliedmed.com/ITProjects',
  IT_BASE_URL: 'https://requests-poc1.appliedmed.com',
  IT_MVC_BASE_URL: 'https://appqa.appliedmed.com/ITProjects',
  GRAPHQL_ENDPOINT: 'https://corp-feserverless-funcapp-prod-eastus2.azurewebsites.net/api',
  DECISIONS_URL: 'https://decisionsdev2.appliedmed.com/Primary',
  SHAREPOINT_DRIVE_ID: 'b!FsZQ2jwwB0icMs1_hhQEgNs4eWYw5wxFqyo4vr5Pt6xsw4evcrHBTYq1SCIAVHNT',
  SHAREPOINT_FILE_PATH: 'POC1'
};
