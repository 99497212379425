import { useQuery } from 'react-query';
import { TQuery } from 'api/api.types';
import { RequestCostAPI } from 'api';

export const useGetCapitalAsset = ({ args, ...config }) => {
  return useQuery(['request-capital-asset', args], () => RequestCostAPI.getCapitalAsset(args), {
    staleTime: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true
  });
};

export const useGetCostEnum = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['request-cost-enum', args], () => RequestCostAPI.getCostEnum(args), {
    staleTime: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true
  });
};
