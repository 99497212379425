import { ProgramsAPI } from 'api';
import { UseMutationOptions, useMutation } from 'react-query';

export const useCreateProgram = (config?: UseMutationOptions) => {
  return useMutation(ProgramsAPI.createProgram, { ...config });
};

export const useDeleteProgram = (config?: UseMutationOptions<any>) => {
  return useMutation(ProgramsAPI.deleteProgram, { ...config });
};

export const useUpdateProgram = (config?: UseMutationOptions<any>) => {
  return useMutation(ProgramsAPI.updateProgram, { ...config });
};

export const useAddProgramRequests = (config?: UseMutationOptions) => {
  return useMutation(ProgramsAPI.addProgramRequests, { ...config });
};

export const useDeleteProgramRequests = (config?: UseMutationOptions) => {
  return useMutation(ProgramsAPI.deleteProgramRequests, { ...config });
};
