import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const TransferIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      d="M11.1801 13.5731L11.1774 13.5757H11.1747L11.1588 13.5731L1.37401 13.5439C1.27816 13.5439 1.18763 13.5068 1.11841 13.4379C1.18231 13.5042 1.27017 13.5386 1.3607 13.5386C10.5118 13.5704 11.1108 13.5678 11.1694 13.5651C11.1667 13.5651 11.1641 13.5651 11.1641 13.5651C11.1667 13.5651 11.1694 13.5651 11.1721 13.5651L11.1801 13.5731Z"
      fill="#7D858C"
    />
    <path
      d="M11.4704 13.2602L11.4465 13.2337L11.3027 13.1568L11.2681 13.1515C11.2361 13.1462 11.1776 13.1383 11.1243 13.1436C10.8501 13.1462 4.7396 13.1277 1.44074 13.1144V0.853792L10.7755 0.864398V6.67652C10.7782 6.78788 10.8235 6.89394 10.906 6.97349C10.9859 7.05038 11.0924 7.09546 11.2042 7.09546C11.2601 7.09811 11.316 7.0875 11.3693 7.06629C11.4225 7.04508 11.4704 7.01326 11.5104 6.97349C11.5503 6.93372 11.5823 6.88599 11.6036 6.83296C11.6249 6.77993 11.6329 6.7269 11.6329 6.67122V0.771595C11.6329 0.57008 11.553 0.37652 11.4119 0.230686L11.4092 0.228035C11.2628 0.0848529 11.0711 0.00265595 10.8661 4.43892e-06H1.37418C1.26768 -0.00264708 1.1665 0.0159135 1.06799 0.0556863C0.969476 0.095459 0.884275 0.153792 0.812387 0.228035C0.735174 0.299626 0.676599 0.387126 0.636661 0.48258C0.596723 0.580686 0.578086 0.684095 0.583411 0.787504V13.1833C0.583411 13.3928 0.665949 13.589 0.81505 13.7375C0.964151 13.886 1.16118 13.9682 1.37152 13.9682L11.127 13.9974C11.143 14 11.1589 14 11.1776 14C11.2175 14 11.2548 13.9947 11.2921 13.9841C11.3506 13.9682 11.4092 13.9364 11.4571 13.8966C11.5051 13.8568 11.5423 13.8064 11.569 13.7508C11.5956 13.6924 11.6089 13.6341 11.6089 13.5705C11.6089 13.5068 11.5956 13.4458 11.569 13.3902C11.5423 13.3371 11.4971 13.2867 11.4704 13.2602ZM11.1776 13.5758C11.1749 13.5758 11.1749 13.5758 11.1776 13.5758L11.1589 13.5731L1.37418 13.5439C1.27833 13.5439 1.1878 13.5068 1.11858 13.4379C1.18248 13.5042 1.27034 13.5386 1.36087 13.5386C10.5119 13.5705 11.111 13.5678 11.1696 13.5652C11.1669 13.5652 11.1643 13.5652 11.1643 13.5652C11.1669 13.5652 11.1696 13.5652 11.1722 13.5652L11.1829 13.5705L11.1776 13.5758Z"
      fill="#7D858C"
    />
    <path
      d="M13.4166 10.2137C13.4166 10.2667 13.4086 10.3198 13.3873 10.3675C13.366 10.4179 13.334 10.4656 13.2941 10.5027L11.6567 12.1493C11.6194 12.1864 11.5741 12.2183 11.5262 12.2395C11.4756 12.2607 11.425 12.2713 11.3691 12.2713C11.3159 12.2713 11.2626 12.2607 11.212 12.2395C11.1614 12.2183 11.1188 12.1891 11.0789 12.1493L11.0762 12.1467C11.0043 12.0698 10.9644 11.969 10.9644 11.8629C10.9644 11.7569 11.0043 11.6561 11.0762 11.5792L11.0789 11.5766L12.0534 10.6141H7.65222C7.59631 10.622 7.54039 10.6167 7.48714 10.6035C7.42857 10.5876 7.37532 10.5611 7.33006 10.5213C7.28479 10.4842 7.24752 10.4338 7.22355 10.3807C7.19959 10.3251 7.18628 10.2694 7.18628 10.2084C7.18628 10.1474 7.19959 10.0917 7.22355 10.036C7.24752 9.98302 7.28479 9.93529 7.33006 9.89552C7.37532 9.8584 7.42857 9.82923 7.48714 9.81332C7.54039 9.79741 7.59897 9.79476 7.65222 9.80272H12.0427L11.0789 8.84286C11.007 8.76597 10.9671 8.66521 10.9671 8.55915C10.9671 8.45309 11.007 8.35233 11.0789 8.27544L11.0815 8.27279C11.1188 8.23301 11.1641 8.20385 11.2147 8.18264C11.3159 8.14021 11.4277 8.14021 11.5289 8.18264C11.5794 8.20385 11.622 8.23301 11.662 8.27279L13.2994 9.90347C13.342 9.94325 13.374 9.99362 13.3953 10.0467C13.4113 10.0997 13.4192 10.158 13.4166 10.2137Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default TransferIcon;
