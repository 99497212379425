import { useQuery } from 'react-query';
import { HelpCenterAPI } from 'api';
import { TQuery } from 'api/api.types';

export const useCurrentProductVersion = (config: TQuery = {}) => {
  return useQuery(['current-product-version'], () => HelpCenterAPI.getCurrentProductVersion(), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useCurrentReleaseNotes = (config: TQuery = {}) => {
  return useQuery(['current-releaseNotes'], () => HelpCenterAPI.getCurrentReleaseNotes(), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};
