import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ICSIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_13639_153791" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13639_153791)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#3170C0"
    />
    <path
      d="M8.37181 14.48V15.3583H7.94979V18.6418H8.37181V19.52H6.39307V18.6418H6.81418V15.3583H6.39307V14.48H8.37181Z"
      fill="white"
    />
    <path
      d="M13.2172 19.2616C12.8614 19.4339 12.3968 19.52 11.8235 19.52C11.0761 19.52 10.488 19.3008 10.0599 18.8606C9.63103 18.4212 9.41699 17.8357 9.41699 17.104C9.41699 16.3244 9.65886 15.692 10.1391 15.2074C10.6211 14.7228 11.2458 14.48 12.014 14.48C12.4899 14.48 12.891 14.5401 13.2172 14.6601V15.7172C12.891 15.5223 12.5195 15.4249 12.1019 15.4249C11.6451 15.4249 11.2754 15.5684 10.9943 15.8564C10.7142 16.1444 10.5732 16.5341 10.5732 17.0257C10.5732 17.4964 10.7055 17.8722 10.9709 18.1524C11.2353 18.4316 11.592 18.5717 12.041 18.5717C12.469 18.5717 12.8614 18.4673 13.2172 18.2594V19.2616Z"
      fill="white"
    />
    <path
      d="M14.2822 19.2486V18.1611C14.4797 18.3255 14.6937 18.4499 14.9243 18.5326C15.1557 18.6152 15.3898 18.657 15.6247 18.657C15.763 18.657 15.8839 18.6448 15.9866 18.6196C16.0901 18.5943 16.1762 18.5595 16.245 18.516C16.3146 18.4716 16.3659 18.4195 16.3998 18.3594C16.4338 18.2994 16.4503 18.2341 16.4503 18.1637C16.4503 18.0688 16.4233 17.9836 16.3694 17.9088C16.3146 17.8348 16.2406 17.7652 16.1467 17.7017C16.0527 17.6382 15.9405 17.5773 15.8117 17.5181C15.6829 17.459 15.5429 17.3998 15.3941 17.338C15.013 17.1797 14.7294 16.9857 14.5423 16.7569C14.3553 16.528 14.2622 16.2522 14.2622 15.9277C14.2622 15.6746 14.3127 15.4562 14.4153 15.2735C14.5171 15.0916 14.6563 14.9411 14.8312 14.8237C15.0069 14.7054 15.2105 14.6192 15.4411 14.5635C15.6725 14.5079 15.917 14.48 16.1754 14.48C16.4294 14.48 16.6547 14.4957 16.8496 14.5261C17.0463 14.5566 17.2272 14.6036 17.3916 14.6671V15.6833C17.3107 15.6267 17.222 15.5762 17.1254 15.5336C17.0289 15.491 16.9297 15.4553 16.8279 15.4266C16.7261 15.3979 16.6252 15.377 16.5234 15.364C16.4233 15.35 16.3276 15.3431 16.2363 15.3431C16.1127 15.3431 15.9988 15.3553 15.897 15.3788C15.7943 15.4031 15.709 15.4362 15.6386 15.4788C15.569 15.5223 15.5142 15.5736 15.475 15.6337C15.4367 15.6937 15.4176 15.7616 15.4176 15.8364C15.4176 15.9173 15.4393 15.9904 15.482 16.0556C15.5255 16.12 15.5864 16.1809 15.6656 16.2392C15.7456 16.2966 15.8413 16.3532 15.9544 16.4088C16.0684 16.4645 16.1954 16.5211 16.3381 16.5802C16.5338 16.662 16.7087 16.7481 16.8636 16.8404C17.0193 16.9317 17.1524 17.0361 17.2629 17.151C17.3742 17.2667 17.4595 17.3989 17.5178 17.5468C17.577 17.6956 17.6065 17.8687 17.6065 18.0654C17.6065 18.3377 17.5552 18.5656 17.4517 18.7501C17.349 18.9354 17.2081 19.085 17.0315 19.199C16.8549 19.313 16.6495 19.3956 16.4146 19.4452C16.1806 19.4948 15.9335 19.52 15.6725 19.52C15.4054 19.52 15.1505 19.4974 14.9095 19.4522C14.6685 19.4069 14.4588 19.3391 14.2822 19.2486Z"
      fill="white"
    />
  </SvgIcon>
);

export default ICSIcon;
