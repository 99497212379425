import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RequirementIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M12.6653 9.03125V1.71875C12.6653 1.04564 12.1212 0.5 11.4506 0.5H3.75753C2.41579 0.5 1.32812 1.59129 1.32812 2.9375V11.0625C1.32812 12.4087 2.41579 13.5 3.75753 13.5H11.8555C12.3027 13.5 12.6653 13.1362 12.6653 12.7104C12.6653 12.4128 12.4981 12.1639 12.2604 12.0223V9.95648C12.5084 9.71172 12.6653 9.3918 12.6653 9.03125ZM4.94945 3.75H9.80826C10.0537 3.75 10.2359 3.93281 10.2359 4.15625C10.2359 4.37969 10.0537 4.5625 9.83103 4.5625H4.94945C4.74953 4.5625 4.56733 4.37969 4.56733 4.15625C4.56733 3.93281 4.74953 3.75 4.94945 3.75ZM4.94945 5.375H9.80826C10.0537 5.375 10.2359 5.55781 10.2359 5.78125C10.2359 6.00469 10.0537 6.1875 9.83103 6.1875H4.94945C4.74953 6.1875 4.56733 6.00469 4.56733 5.78125C4.56733 5.55781 4.74953 5.375 4.94945 5.375ZM11.0457 11.875H3.75753C3.31037 11.875 2.94773 11.5112 2.94773 11.0625C2.94773 10.6138 3.31037 10.25 3.75753 10.25H11.0457V11.875Z"
      fill="#21824D"
    />
  </SvgIcon>
);

export default RequirementIcon;
