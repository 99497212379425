import { get, post, put, patch, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/request-overview`;

export const getRequestOverview = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/${id}` })).data;
};

export const updateRequestOverview = async ({ id, data, headers }: any) => {
  return (await patch({ url: `${BASE_URL}/${id}`, data, headers })).data;
};

export const getRequestOverviewEnums = async () => {
  return (await get({ url: `${BASE_URL}/enums` })).data;
};

export const addOverviewRegion = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/regions`, data })).data;
};

export const removeOverviewRegion = async ({ requestId, regionId }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/regions/${regionId}` })).data;
};

export const addOverviewReference = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/references`, data })).data;
};

export const removeOverviewReference = async ({ requestId, referenceId, data }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/references/${referenceId}`, data })).data;
};

export const editOverviewReference = async ({ requestId, referenceId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/references/${referenceId}`, data })).data;
};

export const getRequestDependencies = async ({ requestId, dependencyId }: any) => {
  return (await get({ url: `${BASE_URL}/${requestId}/dependencies/${dependencyId}` })).data;
};

export const addOverviewDependency = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/dependencies`, data })).data;
};

export const removeOverviewDependency = async ({ requestId, dependencyId, data }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/dependencies/${dependencyId}`, data })).data;
};

export const editOverviewDependency = async ({ requestId, dependencyId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/dependencies/${dependencyId}`, data })).data;
};

export const searchRequests = (input: string) => {
  return get({ url: `${BASE_URL}/request-search?searchTerm=${input.trim()}` });
};
