import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const InfoIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M7 0.903226C10.3482 0.903226 13.0968 3.61502 13.0968 7C13.0968 10.3671 10.3699 13.0968 7 13.0968C3.63424 13.0968 0.903226 10.3712 0.903226 7C0.903226 3.6354 3.62989 0.903226 7 0.903226ZM7 0C3.13428 0 0 3.13541 0 7C0 10.8668 3.13428 14 7 14C10.8657 14 14 10.8668 14 7C14 3.13541 10.8657 0 7 0Z"
      fill={props.fill ?? '#7D858C'}
    />
    <path
      d="M7.71101 5.75555V10.2C7.71101 10.4369 7.53323 10.6147 7.29628 10.6147H6.70378C6.46684 10.6147 6.28906 10.4369 6.28906 10.2V5.75555C6.28906 5.5186 6.46684 5.34083 6.70378 5.34083H7.29628C7.53323 5.34083 7.71101 5.5186 7.71101 5.75555Z"
      fill={props.fill ?? '#7D858C'}
    />
    <path
      d="M7.00003 4.80751C7.39281 4.80751 7.71114 4.48918 7.71114 4.0964C7.71114 3.70362 7.39281 3.38528 7.00003 3.38528C6.60725 3.38528 6.28892 3.70362 6.28892 4.0964C6.28892 4.48918 6.60725 4.80751 7.00003 4.80751Z"
      fill={props.fill ?? '#7D858C'}
    />
  </SvgIcon>
);

export default InfoIcon;
