import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const InsertArrowIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      d="M9.30874 2.61304C9.26889 2.65054 9.22905 2.69976 9.22905 2.77008C9.22905 2.79587 9.26655 2.84275 9.30171 2.87791L12.377 6.14544H2.66585C1.75661 6.14544 0.999268 6.88146 0.999268 7.83312V12.125C0.999268 12.2284 1.08351 12.3125 1.16569 12.3125C1.29016 12.3125 1.35321 12.2284 1.35321 12.125V7.83312C1.35321 7.08773 1.96312 6.52048 2.66585 6.52048H12.377L9.30171 9.74582C9.22905 9.82083 9.23373 9.94037 9.30874 10.0107C9.38375 10.081 9.50329 10.0787 9.57361 10.0037L12.949 6.44078C13.0169 6.36812 13.0169 6.25561 12.949 6.18294L9.57361 2.62007C9.50329 2.54506 9.38375 2.54272 9.30874 2.61304Z"
      fill="#7D858C"
      stroke="#7D858C"
      strokeWidth="0.5"
    />
  </SvgIcon>
);

export default InsertArrowIcon;
