import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PowerPointXIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117578" maskUnits="userSpaceOnUse" x="1" y="0" width="20" height="24">
      <path
        d="M1.95409 23.6221C1.74726 23.6221 1.5791 23.4541 1.5791 23.2471V0.747768C1.5791 0.540775 1.74726 0.37278 1.95409 0.37278H14.5485L20.3285 6.15286V23.2471C20.3285 23.4541 20.1604 23.6221 19.9535 23.6221H1.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117578)">
      <path
        d="M1.95409 23.6221C1.74726 23.6221 1.5791 23.4541 1.5791 23.2471V0.747768C1.5791 0.540775 1.74726 0.37278 1.95409 0.37278H14.5485L20.3285 6.15286V23.2471C20.3285 23.4541 20.1604 23.6221 19.9535 23.6221H1.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M14.7037 0.000736237H1.95408C1.53984 0.000736237 1.2041 0.336725 1.2041 0.750713V23.25C1.2041 23.664 1.53984 24 1.95408 24H19.9535C20.3677 24 20.7035 23.664 20.7035 23.25V6.00055L14.7037 0.000736237ZM14.3931 0.750713L19.9535 6.31104V23.25H1.95408V0.750713H14.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M20.7039 6.75H14.7041C14.2899 6.75 13.9541 6.41401 13.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M21.2143 21H0.785714C0.35173 21 0 20.552 0 20V14C0 13.448 0.35173 13 0.785714 13H21.2143C21.6482 13 22 13.448 22 14V20C22 20.552 21.6482 21 21.2143 21Z"
      fill="#CF5230"
    />
    <path
      d="M3.13429 15.3945V16.9658H3.58058C4.18525 16.9658 4.48863 16.7015 4.48863 16.1719C4.48863 15.6536 4.18525 15.3945 3.58058 15.3945H3.13429ZM3.13429 17.8266V19.5635H2V14.5235H3.77701C5.04807 14.5235 5.68357 15.0582 5.68357 16.1298C5.68357 16.6357 5.50054 17.045 5.13753 17.3576C4.77143 17.6703 4.28502 17.8266 3.67623 17.8266H3.13429Z"
      fill="white"
    />
    <path
      d="M7.60553 15.3945V16.9658H8.05182C8.65547 16.9658 8.95781 16.7015 8.95781 16.1719C8.95781 15.6536 8.65547 15.3945 8.05182 15.3945H7.60553ZM7.60553 17.8266V19.5635H6.47021V14.5235H8.24826C9.51725 14.5235 10.1538 15.0582 10.1538 16.1298C10.1538 16.6357 9.97178 17.045 9.60672 17.3576C9.24268 17.6703 8.75524 17.8266 8.14542 17.8266H7.60553Z"
      fill="white"
    />
    <path
      d="M14.6593 15.448H13.2217V19.5635H12.0833V15.448H10.6528V14.5235H14.6593V15.448Z"
      fill="white"
    />
    <path
      d="M19.7246 19.5635H18.3785L17.5095 17.9222C17.4776 17.8615 17.4426 17.7484 17.4077 17.5849H17.3943C17.3768 17.662 17.3388 17.7793 17.2781 17.9356L16.4071 19.5635H15.0527L16.6601 17.043L15.1895 14.5235H16.5716L17.2925 16.0342C17.3491 16.1545 17.3984 16.2954 17.4426 16.4599H17.457C17.4848 16.3612 17.5372 16.2151 17.6154 16.0208L18.4175 14.5235H19.6824L18.1697 17.0224L19.7246 19.5635Z"
      fill="white"
    />
  </SvgIcon>
);

export default PowerPointXIcon;
