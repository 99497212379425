import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const XCheckIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#FFEFEF" />
    <path
      d="M13.1739 11.9996L16.7537 8.42801C16.9105 8.27124 16.9986 8.05862 16.9986 7.83691C16.9986 7.61521 16.9105 7.40258 16.7537 7.24582C16.597 7.08905 16.3843 7.00098 16.1626 7.00098C15.9409 7.00098 15.7283 7.08905 15.5715 7.24582L12 10.8257L8.42845 7.24582C8.27168 7.08905 8.05906 7.00098 7.83736 7.00098C7.61565 7.00098 7.40303 7.08905 7.24626 7.24582C7.08949 7.40258 7.00142 7.61521 7.00142 7.83691C7.00142 8.05862 7.08949 8.27124 7.24626 8.42801L10.8261 11.9996L7.24626 15.5711C7.16823 15.6485 7.10629 15.7406 7.06403 15.842C7.02176 15.9435 7 16.0523 7 16.1622C7 16.2721 7.02176 16.3809 7.06403 16.4824C7.10629 16.5838 7.16823 16.6759 7.24626 16.7533C7.32365 16.8313 7.41573 16.8933 7.51718 16.9355C7.61864 16.9778 7.72745 16.9996 7.83736 16.9996C7.94726 16.9996 8.05608 16.9778 8.15753 16.9355C8.25898 16.8933 8.35106 16.8313 8.42845 16.7533L12 13.1734L15.5715 16.7533C15.6489 16.8313 15.741 16.8933 15.8425 16.9355C15.9439 16.9778 16.0527 16.9996 16.1626 16.9996C16.2725 16.9996 16.3814 16.9778 16.4828 16.9355C16.5843 16.8933 16.6763 16.8313 16.7537 16.7533C16.8318 16.6759 16.8937 16.5838 16.936 16.4824C16.9782 16.3809 17 16.2721 17 16.1622C17 16.0523 16.9782 15.9435 16.936 15.842C16.8937 15.7406 16.8318 15.6485 16.7537 15.5711L13.1739 11.9996Z"
      fill="#CC4A4A"
    />
  </SvgIcon>
);

export default XCheckIcon;
