import { TQuery } from 'api/api.types';

export interface IFilePayload {
  id: number;
  method: string;
  url: string;
  body: object;
}
export interface IBatchPayload {
  requests: IFilePayload[];
}

export interface IFilesDataOriginal {
  id: string;
  checkBox?: any;
  name: string;
  ellipsis: React.ReactNode;
  lastModified: string;
  modifiedBy: string;
  size: number;
  isFolder: boolean;
  childCount: number;
  downloadUrl: string;
  webUrl: string;
  parentReference: {
    driveId: string;
    driveType: string;
    id: string;
    path: string;
    siteId: string;
  };
  file: { mimeType: string | null };
}

export interface IFilesData {
  getToggleRowSelectedProps(): JSX.IntrinsicAttributes;
  original: IFilesDataOriginal;
  depth: number;
  id: string;
  index: number;
  isSelected: boolean;
  isSomeSelected: boolean;
}

export interface ISharePointFilesStructure {
  folder?: { childCount: number };
  lastModifiedBy: { user: { displayName: string } };
  lastModifiedDateTime: string;
  createdDateTime: string;
  id: string;
  name: string;
  size: number;
  parentReference: {
    driveId: string;
    driveType: string;
    id: string;
    path: string;
    siteId: string;
  };
  ['@microsoft.graph.downloadUrl']: string;
  webUrl: string;
  file: { mimeType: string };
}

export interface IFilesQuery {
  value: ISharePointFilesStructure;
}

export interface IPath extends TQuery {
  path: string | null;
}

export enum DEFAULTFOLDER {
  Requests = 'Requests',
  RequesterFiles = 'Requester Files',
  Programs = 'Programs'
}

export interface IUploadFiles {
  path: string;
  data: FormData;
  fileName: string;
  headers: { 'Content-Type': string };
  fileIsOpenErrorToast: () => void;
}

export enum ROLES {
  Read = 'read',
  Write = 'write'
}

export interface IRoles {
  requireSignIn: boolean;
  sendInvitation: boolean;
  recipients: { email: string }[];
  roles: ROLES[];
}

export interface ISharePointPermissionUser {
  grantedToV2: { user: { displayName: string; email: string; id: string } };
  roles: ROLES[];
  shareId: string;
}

export interface IUppyFile {
  source: string;
  id: string;
  name: string;
  extension: string;
  meta: {
    name: string;
    type: string;
  };
  type: string;
  data: any; // You can replace 'any' with a more specific type if needed
  progress: {
    percentage: number;
    bytesUploaded: number;
    bytesTotal: number;
    uploadComplete: boolean;
    uploadStarted: Date | null;
  };
  size: number;
  isRemote: boolean;
  remote: string;
}

export interface IBatchPayload {
  id: number;
  method: METHOD;
  url: string;
  headers?: any;
}

export enum METHOD {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT'
}

export interface SharePointBlobFiles {
  data: Blob;
  filename: string;
  id: string;
}

export const forbiddenChars = [
  '~',
  '"',
  '#',
  '%',
  '&',
  '*',
  ':',
  '<',
  '>',
  '?',
  '/',
  '\\',
  '{',
  '|',
  '}'
];
