import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Pencil: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 6 6" fill="none" {...props}>
    <path d="M3.40715 1.09266L4.90734 2.59286L1.64973 5.85047L0.312189 5.99812C0.133132 6.01792 -0.0181535 5.86652 0.00176785 5.68746L0.150592 4.34898L3.40715 1.09266ZM5.83521 0.869308L5.13081 0.164913C4.91109 -0.0548081 4.55474 -0.0548081 4.33501 0.164913L3.67234 0.827591L5.17253 2.32779L5.83521 1.66511C6.05493 1.44527 6.05493 1.08903 5.83521 0.869308Z" />
  </SvgIcon>
);

export default Pencil;
