import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const FolderIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" fill="#FFCB67" {...props}>
    <g clip-path="url(#clip0_3590_76133)">
      <path
        d="M2.25 3C1.00734 3 0 4.00734 0 5.25V18.75C0 19.9927 1.00734 21 2.25 21H21.75C22.9927 21 24 19.9927 24 18.75V8.25C24 7.00734 22.9927 6 21.75 6H12.75L10.1892 3.43922C9.90797 3.15797 9.52641 3 9.12844 3H2.25Z"
        fill="#FFCB67"
      />
    </g>
    <defs>
      <clipPath id="clip0_3590_76133">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default FolderIcon;
