import React, { useState } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';

import { useInterval } from 'hooks';
import DelayedRender from '../DelayedRender/DelayedRender';

type LoadingProps = {
  message?: string;
  delay?: number;
  containerProps?: any;
};

const Loading: React.FC<LoadingProps> = ({
  message = '',
  delay = 200,
  containerProps = {},
  ...restProps
}) => {
  const [timer, setTimer] = useState(0);
  const [displayMessage, setDisplayMessage] = useState(message);

  useInterval(
    () => {
      setTimer(prevState => prevState + 1);
      if (timer === 30) {
        setDisplayMessage("Hmmm, something isn't right...");
      } else if (timer === 60) {
        setDisplayMessage('Sorry for taking so long. Try refreshing the page!');
      }
    },
    timer < 61 ? 1000 : null
  );

  return (
    <DelayedRender delay={delay}>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          height: '100%',
          textAlign: 'center',
          padding: 8,
          ...containerProps
        }}
      >
        <Grid item>
          <CircularProgress />
          <Typography>{displayMessage}</Typography>
        </Grid>
      </Grid>
    </DelayedRender>
  );
};

export default Loading;
