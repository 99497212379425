import { format } from 'date-fns';
import React from 'react';

type renderProps = {
  Comp: any;
  props?: any;
};

export const render = (Comp: any, props: any = {}) => {
  return isReactComponent(Comp) ? <Comp {...props} /> : Comp;
};

const isReactComponent = (component: any) => {
  return (
    isClassComponent(component) || typeof component === 'function' || isExoticComponent(component)
  );
};

const isClassComponent = (component: any) => {
  return (
    typeof component === 'function' &&
    (() => {
      const proto = Object.getPrototypeOf(component);
      return proto.prototype && proto.prototype.isReactComponent;
    })()
  );
};

const isExoticComponent = (component: any) => {
  return (
    typeof component === 'object' &&
    typeof component.$$typeof === 'symbol' &&
    ['react.memo', 'react.forward_ref'].includes(component.$$typeof.description)
  );
};

export const convertToUTC = (value: any) => {
  return value
    ? value.getTimezoneOffset() > 0
      ? new Date(value.setUTCHours(0, 0, 0, 0))
      : new Date(value.setUTCHours(24, 0, 0, 0))
    : null;
};

export const addTimezoneOffset = (date: any) => {
  let newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());

  return newDate;
};

export const convertLocalToUTC = (date: any) => {
  // stepping on time to make sure FE submits midnight utc
  return format(date, "yyyy-MM-dd'T00:00:00Z'");
};

// test if str contains only numbers
export const validateNum = (str: string) => {
  return /^\d+$/.test(str);
};
