import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const EllipsisIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 4" {...props}>
    <path
      d="M9.66416 2.01337C9.66681 1.67971 9.57029 1.35278 9.38684 1.07406C9.20339 0.795343 8.94129 0.577395 8.63379 0.447869C8.32628 0.318343 7.98723 0.283077 7.65965 0.346545C7.33207 0.410013 7.03072 0.569355 6.79384 0.804355C6.55696 1.03936 6.39522 1.33942 6.32915 1.66649C6.26307 1.99355 6.29563 2.33287 6.4227 2.6414C6.54978 2.94993 6.76563 3.21376 7.04288 3.39942C7.32013 3.58508 7.64628 3.68421 7.97995 3.68422C8.20049 3.68598 8.4192 3.64407 8.62346 3.56089C8.82772 3.47771 9.0135 3.35492 9.17007 3.19959C9.32664 3.04426 9.45091 2.85947 9.53571 2.65588C9.62051 2.45229 9.66417 2.23392 9.66416 2.01337ZM14.3158 3.68422C13.9859 3.68158 13.6642 3.58135 13.3912 3.39615C13.1182 3.21096 12.9061 2.9491 12.7817 2.64357C12.6573 2.33804 12.6261 2.00252 12.6921 1.67929C12.758 1.35607 12.9182 1.0596 13.1524 0.827265C13.3866 0.594929 13.6843 0.437118 14.0081 0.373724C14.3318 0.31033 14.6671 0.34419 14.9716 0.471035C15.2761 0.597881 15.5363 0.812035 15.7193 1.08651C15.9023 1.36098 16 1.68348 16 2.01337C15.9965 2.45773 15.8175 2.88269 15.502 3.19566C15.1865 3.50862 14.7602 3.68423 14.3158 3.68422V3.68422ZM1.64411 3.68422C1.31484 3.67895 0.994466 3.57649 0.723237 3.38973C0.452007 3.20296 0.242017 2.9402 0.119644 2.63447C-0.00272875 2.32874 -0.0320266 1.99366 0.0354307 1.67133C0.102888 1.349 0.264092 1.05379 0.498793 0.822784C0.733494 0.591779 1.03123 0.43528 1.35459 0.372948C1.67795 0.310615 2.01252 0.345228 2.31627 0.472438C2.62002 0.599648 2.87941 0.813781 3.06185 1.08794C3.24429 1.3621 3.34165 1.68406 3.34169 2.01337C3.34172 2.23506 3.29763 2.45454 3.21199 2.65901C3.12636 2.86349 3.00089 3.04888 2.8429 3.20439C2.6849 3.3599 2.49754 3.48241 2.29173 3.56479C2.08592 3.64717 1.86577 3.68776 1.64411 3.68422Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default EllipsisIcon;
