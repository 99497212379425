import { get, post, put, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';

const BASE_URL = `${getEnv('IT_BASE_URL')}/admin-management`;

export const getDepartments = async () => {
  return (await get({ url: `${BASE_URL}/departments` })).data;
};

export const getDepartment = async ({ deptId }) => {
  return (await get({ url: `${BASE_URL}/departments/${deptId}` })).data;
};

export const getSubDepartments = async ({ deptId }) => {
  return (await get({ url: `${BASE_URL}/sub-departments/by-department/${deptId}` })).data;
};

export const getSubDepartment = async ({ subDeptId }) => {
  return (await get({ url: `${BASE_URL}/sub-departments/${subDeptId}` })).data;
};

export const getAreas = async ({ subDeptId }) => {
  return (await get({ url: `${BASE_URL}/areas/by-sub-department/${subDeptId}` })).data;
};

export const getArea = async ({ areaId }) => {
  return (await get({ url: `${BASE_URL}/areas/${areaId}` })).data;
};

export const getTeams = async ({ areaId }) => {
  return (await get({ url: `${BASE_URL}/teams/by-area/${areaId}` })).data;
};

export const getSubDepartmentMembers = async ({ subDeptId }: any) => {
  const response = await get({ url: `${BASE_URL}/sub-departments/${subDeptId}/members` });

  return {
    items: response.data,
    count: JSON.parse(response.headers['x-total-count'])
  };
};

export const addSubDepartment = async ({ data }: any) => {
  return (await post({ url: `${BASE_URL}/sub-departments`, data })).data;
};

export const addSubDepartmentMember = async ({ subDeptId, data }: any) => {
  return (await post({ url: `${BASE_URL}/sub-departments/${subDeptId}/members`, data })).data;
};

export const editSubDepartment = async ({ subDeptId, data }: any) => {
  return (await put({ url: `${BASE_URL}/sub-departments/${subDeptId}`, data })).data;
};

export const deleteSubDepartment = async ({ subDeptId, data }: any) => {
  return (await del({ url: `${BASE_URL}/sub-departments/${subDeptId}`, data })).data;
};

export const addArea = async ({ data }: any) => {
  return (await post({ url: `${BASE_URL}/areas`, data })).data;
};

export const editArea = async ({ areaId, data }: any) => {
  return (await put({ url: `${BASE_URL}/areas/${areaId}`, data })).data;
};

export const deleteArea = async ({ areaId, data }: any) => {
  return (await del({ url: `${BASE_URL}/areas/${areaId}`, data })).data;
};

export const deleteSubDepartmentMember = async ({ subDeptId, memberId, data }: any) => {
  return (await del({ url: `${BASE_URL}/sub-departments/${subDeptId}/members/${memberId}`, data }))
    .data;
};

export const updateSubDepartmentMember = async ({ subDeptId, memberId, data }: any) => {
  return (await put({ url: `${BASE_URL}/sub-departments/${subDeptId}/members/${memberId}`, data }))
    .data;
};

export const getAreaMembers = async ({ areaId }: any) => {
  const response = await get({ url: `${BASE_URL}/areas/${areaId}/members` });

  return {
    items: response.data,
    count: JSON.parse(response.headers['x-total-count'])
  };
};

export const addAreaMember = async ({ areaId, data }: any) => {
  return (await post({ url: `${BASE_URL}/areas/${areaId}/members`, data })).data;
};

export const deleteAreaMember = async ({ areaId, memberId, data }: any) => {
  return (await del({ url: `${BASE_URL}/areas/${areaId}/members/${memberId}`, data })).data;
};

export const updateAreaMember = async ({ areaId, memberId, data }: any) => {
  return (await put({ url: `${BASE_URL}/areas/${areaId}/members/${memberId}`, data })).data;
};

export const getSubDepartmentCandidateList = async ({ subDeptId }: any) => {
  return (await get({ url: `${BASE_URL}/sub-departments/${subDeptId}/candidates` })).data;
};

export const getAreaCandidateList = async ({ areaId }: any) => {
  return (await get({ url: `${BASE_URL}/areas/${areaId}/candidates` })).data;
};

export const addTeam = async ({ data }: any) => {
  return (await post({ url: `${BASE_URL}/teams`, data })).data;
};

export const editTeam = async ({ teamId, data }: any) => {
  return (await put({ url: `${BASE_URL}/teams/${teamId}`, data })).data;
};

export const deleteTeam = async ({ teamId, data }: any) => {
  return (await del({ url: `${BASE_URL}/teams/${teamId}`, data })).data;
};

export const getTeamsMembers = async ({ teamId }: any) => {
  const response = await get({ url: `${BASE_URL}/teams/${teamId}/members` });

  return {
    items: response.data,
    count: JSON.parse(response.headers['x-total-count'])
  };
};

export const addTeamsMember = async ({ teamId, data }: any) => {
  return (await post({ url: `${BASE_URL}/teams/${teamId}/members`, data })).data;
};

export const deleteTeamsMember = async ({ teamId, memberId, data }: any) => {
  return (await del({ url: `${BASE_URL}/teams/${teamId}/members/${memberId}`, data })).data;
};

export const updateTeamsMember = async ({ teamId, memberId, data }: any) => {
  return (await put({ url: `${BASE_URL}/teams/${teamId}/members/${memberId}`, data })).data;
};

export const getTeamsCandidateList = async ({ teamId }: any) => {
  return (await get({ url: `${BASE_URL}/teams/${teamId}/candidates` })).data;
};
