import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const HelpCenterIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M17.2227 10.8701C17.2227 11.2585 16.9079 11.5732 16.5195 11.5732C16.1313 11.5732 15.8164 11.2585 15.8164 10.8701C15.8164 10.4819 16.1313 10.167 16.5195 10.167C16.9079 10.167 17.2227 10.4819 17.2227 10.8701Z" />
    <path d="M16.5187 3.38916C15.0914 3.38916 13.9336 4.54419 13.9336 5.97424V5.97516C13.9336 6.36334 14.2484 6.67792 14.6367 6.67792C15.0251 6.67792 15.3398 6.36261 15.3398 5.97424C15.3398 5.32422 15.8655 4.79541 16.5196 4.79541C17.17 4.79541 17.6992 5.32459 17.6992 5.97516C17.6992 6.62555 17.17 7.15472 16.5196 7.15472C16.1312 7.15472 15.8165 7.46967 15.8165 7.85785V8.61096C15.8165 8.99933 16.1312 9.31409 16.5196 9.31409C16.9078 9.31409 17.2227 8.99933 17.2227 8.61096V8.46393C18.308 8.15686 19.1054 7.15729 19.1054 5.97516C19.1054 4.54913 17.9454 3.38916 16.5187 3.38916Z" />

    <path d="M6.74707 17.9443C7.62085 17.2639 8.18445 16.203 8.18445 15.0125C8.18445 12.9637 6.51746 11.2969 4.46869 11.2969C2.41992 11.2969 0.753113 12.9637 0.753113 15.0125C0.753113 16.203 1.31671 17.2639 2.19031 17.9443C0.957275 18.6779 0 20.0632 0 21.7906V23.2969C0 23.6852 0.314758 24 0.703125 24H8.23444C8.62262 24 8.93756 23.6852 8.93756 23.2969V21.7906C8.93756 20.0636 7.98047 18.678 6.74707 17.9443ZM2.15936 15.0125C2.15936 13.7391 3.19537 12.7031 4.46887 12.7031C5.74219 12.7031 6.7782 13.7391 6.7782 15.0125C6.7782 16.2859 5.74219 17.322 4.46887 17.322C3.19537 17.322 2.15936 16.2859 2.15936 15.0125ZM7.53131 22.5938H1.40625V21.7906C1.40625 20.0991 2.77478 18.7282 4.46869 18.7282C6.16022 18.7282 7.53113 20.0967 7.53113 21.7906V22.5938H7.53131Z" />

    <path d="M21.0371 0H11.9996C10.3633 0 9.03711 1.32385 9.03711 2.96246V16.5187C9.03711 17.0938 9.69666 17.4302 10.1621 17.0812L12.9871 14.9625H21.0371C22.6733 14.9625 23.9996 13.6386 23.9996 12V2.96246C23.9996 1.32623 22.6757 0 21.0371 0V0ZM22.5933 12C22.5933 12.8602 21.8977 13.5562 21.0371 13.5562H12.7527C12.6005 13.5562 12.4526 13.6057 12.3308 13.6968L10.4434 15.1124V2.96246C10.4434 2.10223 11.139 1.40625 11.9996 1.40625H21.0371C21.8973 1.40625 22.5933 2.10187 22.5933 2.96246V12Z" />
  </SvgIcon>
);

export default HelpCenterIcon;
