import { user } from './auth.service';

export function checkFeatureFlags(flag = '') {
  if (user.featureFlags.includes(flag)) {
    return true;
  } else {
    return false;
  }
}

/**
 * TBD:
 *  - undecided if AppCenter will handle all feature toggles atm
 */

import { useState, useEffect } from 'react';
// import { user, getHasuraJwtToken } from "./auth";
import { pathOr, includes } from 'ramda';
import { graphql } from './request.service';

export let accessableFeature = [];
export let isUnderMaintenance = [];
export let wizardIsUnderMaintenance = [];

export const getFeature = async (TeamID, CostcenterID, origin, id) => {
  try {
    if ((TeamID, CostcenterID)) {
      const response = await graphql(
        `
          query featureToggle($teamid: Int, $costcenterid: Int, $origin: String, $id: Int) {
            feature(
              where: {
                _or: [
                  {
                    costcenter_feature_toggles: {
                      costcenter_id: { _eq: $costcenterid }
                      _and: { is_deleted: { _eq: false } }
                    }
                  }
                  {
                    user_feature_toggles: {
                      team_id: { _eq: $teamid }
                      _and: { is_deleted: { _eq: false } }
                    }
                  }
                ]
                is_active: { _eq: true }
                is_deleted: { _eq: false }
              }
            ) {
              name
            }
            arc_maintenance_page(where: { origin: { _eq: $origin }, id: { _eq: $id } }) {
              is_under_maintenance
              page_name
              id
              origin
            }
            arc_submission_wizard(where: { origin: { _eq: $origin }, id: { _eq: $id } }) {
              page_name
              wizard_under_maintenance
              id
              origin
            }
          }
        `,
        {
          teamid: parseInt(TeamID),
          costcenterid: parseInt(CostcenterID),
          origin: origin,
          id: parseInt(id)
        }
      );

      // console.log('\n\nGETFEATURE -----------', response);

      accessableFeature = pathOr('N/A', ['data', 'data', 'feature'], response);
      isUnderMaintenance = pathOr(false, ['data', 'data', 'arc_maintenance_page'], response);
      wizardIsUnderMaintenance = pathOr(false, ['data', 'data', 'arc_submission_wizard'], response);
    }
  } catch (e) {
    console.log('failed fetching feature');
  }
};

export const useGetFeatureToggle = async (env: string = 'POC2', featureId: number) => {
  const getFeatureToggle = async () => {
    try {
      const response = await graphql(
        `
          query ARCFeatures($env: String!, $featureId: Int!) {
            arc_features(where: { feature_id: { _eq: $featureId } }) {
              feature_id
              feature_name
              arc_features_arc_feature_toggles(where: { environment: { _eq: $env } }) {
                environment
                feature_id
                is_enabled
              }
            }
          }
        `,
        { env, featureId }
      );
      return response.data;
    } catch (e) {
      console.log('error', e);
      return null;
    }
  };
  const data = await getFeatureToggle();
  return data;
};

export const getWizardFeature = async (origin, id) => {
  try {
    if ((origin, id)) {
      const response = await graphql(
        `
          query myQuery($id: Int, $origin: String) {
            arc_submission_wizard(where: { origin: { _eq: $origin }, id: { _eq: $id } }) {
              page_name
              wizard_under_maintenance
              id
              origin
            }
          }
        `,
        {
          origin: origin,
          id: parseInt(id)
        }
      );

      wizardIsUnderMaintenance = pathOr(false, ['data', 'data', 'arc_submission_wizard'], response);
      // console.log(accessableFeature);
    }
  } catch (e) {
    console.log('failed fetching feature');
  }
};

// NOTE: QUERY TO UDPATE MAINTENANCE TABLE IN HASURA
// export const updateMaintenancePage = async (isUnderMaintenance, pageName) => {
//   try {
//     const response = await graphql(
//       `
//         mutation MyMutation(
//           $is_under_maintenance: Boolean = true
//           $page_name: String = ""
//           $_eq: String = ""
//           $is_under_maintenance1: Boolean = false
//         ) {
//           update_arc_maintenance_page(
//             where: { page_name: { _eq: $_eq } }
//             _set: { is_under_maintenance: $is_under_maintenance1 }
//           ) {
//             returning {
//               is_under_maintenance
//               page_name
//             }
//           }
//         }
//       `,
//       { is_under_maintenance: isUnderMaintenance, page_name: isUnderMaintenance }
//     );
//     return pathOr(false, ['data', 'data']);
//   } catch (e) {
//     console.log('failed fetching');
//   }
// };

//NOTE: GraphQL query for get maintence
// export const getMaintenance = async () => {
//   try {
//     const response = await graphql(
//       `
//         query MyQuery {
//           arc_maintenance_page(where: { page_name: { _eq: "ALL" } }) {
//             is_under_maintenance
//             page_name
//           }
//         }
//       `
//     );

//     isUnderMaintenance = pathOr(false, ['data', 'data', 'arc_maintenance_page'], response);
//     // console.log(accessableFeature);
//   } catch (e) {
//     console.log('failed fetching feature');
//   }
// };

// NOTE: Returns true if feature should be accessible and False if feature is under maintenance.
export const useFeature = feature => {
  const [bool, setBool] = useState(includes({ name: feature }, accessableFeature));
  const [underMaintenance, setUnderMaintenance] = useState();
  const [wizardUnderMaintenance, setWizardUnderMaintenance] = useState();

  useEffect(() => {
    const id = setInterval(() => {
      setBool(includes({ name: feature }, accessableFeature));
      setUnderMaintenance(isUnderMaintenance?.[0]?.['is_under_maintenance']);
      setWizardUnderMaintenance(wizardIsUnderMaintenance?.[0]?.['wizard_under_maintenance']);
    }, 1000 * 1);
    return () => {
      clearInterval(id);
    };
  }, [accessableFeature, isUnderMaintenance, wizardIsUnderMaintenance]);
  return { feature: bool, underMaintenance, wizardUnderMaintenance };
};
// NOTE: Returns false if not under maintenance and true when under maintenance
export const useWizardFeature = () => {
  const [wizardUnderMaintenance, setWizardUnderMaintenance] = useState();

  useEffect(() => {
    const id = setInterval(() => {
      setWizardUnderMaintenance(wizardIsUnderMaintenance?.[0]?.['wizard_under_maintenance']);
    }, 1000 * 1);
    return () => {
      clearInterval(id);
    };
  }, [wizardIsUnderMaintenance]);
  return wizardUnderMaintenance;
};
