import { get, post, put, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/jama`;

export const searchJamaProjects = async ({ params }: TAPIArgs = {}, pageNumber = 1) => {
  const response = await get({ url: `${BASE_URL}/search`, params: { ...params, pageNumber } });

  return {
    data: response?.data,
    paginationMetadata: JSON.parse(response?.headers?.paginationmetadata)
  };
};

export const addRequestToJama = async ({ requestId, data }: any) => {
  return (
    await post({
      url: `${BASE_URL}/push/${requestId}`,
      data,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  ).data;
};
