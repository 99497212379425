import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';
import { ProjectNotesAPI } from 'api';
import { TQuery } from 'api/api.types';
import { reduceToOptionsDescription } from 'api/api.utils';

export const useGetNotesByRequestId = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await ProjectNotesAPI.getNotesByRequestId(args, pageParam);

    queryClient.setQueryData(['notes-headers', { id: args?.requestId }], headers);
    return items;
  };

  return useInfiniteQuery(['notes', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true
  });
};

export const useGetNoteByNoteId = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['note', args], () => ProjectNotesAPI.getNoteByNoteId(args), {
    staleTime: 1000 * 60 * 5,
    enabled: !!args.noteId,
    ...config,
    refetchOnWindowFocus: true
  });
};

export const useGetNoteEditRights = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['note-edit-rights', args], () => ProjectNotesAPI.getNoteEditRights(args), {
    staleTime: 1000 * 60 * 5,
    enabled: args?.noteId > 0,
    ...config
  });
};

export const useGetProgramNotes = ({
  programId,
  params,
  ...config
}: {
  programId: number;
  params: any;
}) => {
  const queryClient = useQueryClient();
  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await ProjectNotesAPI.getProgramNotes({
      programId,
      params
    });

    queryClient.setQueryData(['program-notes-headers', { id: programId }], headers);
    return items;
  };
  return useInfiniteQuery(['program-notes', programId], queryFn, {
    enabled: !!programId,
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true
  });
};

export const useGetProgramNotesById = ({
  programId,
  programNoteId,
  ...config
}: {
  programId: number;
  programNoteId: number;
}) => {
  return useQuery(
    ['program-note', { programId, programNoteId }],
    () => ProjectNotesAPI.getProgramNoteById({ programId, programNoteId }),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!programId && !!programNoteId,
      ...config
    }
  );
};
