import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const NotesIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M8.01794 1.87012C4.00584 1.87012 0.65603 4.38488 0.752013 7.49473C0.830289 8.43275 1.16206 9.33185 1.71185 10.0959C1.77786 10.1916 1.81939 10.302 1.83278 10.4175C1.84616 10.5329 1.83101 10.6499 1.78863 10.7582C1.78863 10.7582 1.46229 11.4684 1.26072 11.9292C1.05916 12.3899 0.780808 13.3017 0.780808 13.3017C0.71362 13.5033 0.780808 13.5897 1.00157 13.4841L1.87502 12.9946L2.5373 12.6202L3.33396 12.1691C3.55347 12.0858 3.79593 12.0858 4.01544 12.1691C5.29881 12.7039 6.66008 13.028 8.04674 13.1289C12.0684 13.1289 15.3223 10.6046 15.3223 7.49473C15.3223 4.38488 12.0492 1.87012 8.01794 1.87012Z"
      stroke="#7D858C"
      strokeWidth="0.959832"
    />
  </SvgIcon>
);

export default NotesIcon;
