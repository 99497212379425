import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CopyLinkIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.26317 10.1793L5.58367 11.8588C5.35995 12.0949 5.09121 12.2837 4.79331 12.4142C4.4954 12.5447 4.17438 12.6141 3.84919 12.6185C3.524 12.6229 3.20123 12.562 2.89994 12.4396C2.59864 12.3171 2.32494 12.1356 2.09497 11.9056C1.86501 11.6757 1.68344 11.4019 1.561 11.1007C1.43856 10.7994 1.37773 10.4766 1.38209 10.1514C1.38645 9.82621 1.45592 9.50518 1.58639 9.20728C1.71686 8.90938 1.9057 8.64064 2.14174 8.41692L3.82124 6.73742C3.91972 6.63895 3.97504 6.50539 3.97504 6.36613C3.97504 6.22686 3.91972 6.0933 3.82124 5.99483C3.72277 5.89635 3.58921 5.84103 3.44995 5.84103C3.31068 5.84103 3.17712 5.89635 3.07865 5.99483L1.39915 7.67433C1.06363 7.99514 0.795587 8.37975 0.610757 8.80558C0.425928 9.23141 0.328038 9.68987 0.322836 10.1541C0.317634 10.6182 0.405225 11.0788 0.580466 11.5086C0.755706 11.9385 1.01506 12.329 1.34331 12.6573C1.67156 12.9855 2.06208 13.2449 2.49194 13.4201C2.92181 13.5954 3.38235 13.683 3.84653 13.6778C4.31072 13.6725 4.76918 13.5747 5.19501 13.3898C5.62084 13.205 6.00545 12.937 6.32626 12.6014L8.00576 10.9219C8.10423 10.8235 8.15956 10.6899 8.15956 10.5506C8.15956 10.4114 8.10423 10.2778 8.00576 10.1793C7.90729 10.0809 7.77373 10.0255 7.63446 10.0255C7.4952 10.0255 7.36164 10.0809 7.26317 10.1793Z"
      fill="#373D43"
    />
    <path
      d="M7.67287 1.39819L5.99337 3.07769C5.8949 3.17617 5.83957 3.30973 5.83957 3.44899C5.83957 3.58825 5.8949 3.72181 5.99337 3.82029C6.09184 3.91876 6.2254 3.97408 6.36467 3.97408C6.50393 3.97408 6.63749 3.91876 6.73596 3.82029L8.41546 2.14079C8.63918 1.90474 8.90792 1.7159 9.20582 1.58543C9.50372 1.45496 9.82475 1.38549 10.1499 1.38113C10.4751 1.37677 10.7979 1.43761 11.0992 1.56005C11.4005 1.68248 11.6742 1.86405 11.9042 2.09401C12.1341 2.32398 12.3157 2.59769 12.4381 2.89898C12.5606 3.20027 12.6214 3.52304 12.617 3.84823C12.6127 4.17342 12.5432 4.49445 12.4127 4.79235C12.2823 5.09025 12.0934 5.35899 11.8574 5.58271L10.1779 7.26221C10.0794 7.36068 10.0241 7.49424 10.0241 7.6335C10.0241 7.77277 10.0794 7.90633 10.1779 8.0048C10.2764 8.10328 10.4099 8.1586 10.5492 8.1586C10.6884 8.1586 10.822 8.10328 10.9205 8.0048L12.6 6.3253C12.9355 6.00449 13.2035 5.61988 13.3884 5.19405C13.5732 4.76822 13.6711 4.30976 13.6763 3.84558C13.6815 3.38139 13.5939 2.92085 13.4187 2.49099C13.2434 2.06112 12.9841 1.6706 12.6558 1.34235C12.3276 1.01411 11.9371 0.754748 11.5072 0.579507C11.0773 0.404267 10.6168 0.316676 10.1526 0.321878C9.68841 0.32708 9.22995 0.42497 8.80412 0.609799C8.37829 0.794628 7.99368 1.06267 7.67287 1.39819Z"
      fill="#373D43"
    />
    <path
      d="M4.66926 9.33016C4.76773 9.42863 4.9175 9.46774 5.08563 9.43887C5.25376 9.41001 5.42646 9.31554 5.56576 9.17625L9.2912 5.4508C9.4305 5.31151 9.52496 5.1388 9.55383 4.97068C9.58269 4.80255 9.54358 4.65278 9.44511 4.5543C9.34664 4.45583 9.19686 4.41672 9.02874 4.44559C8.86061 4.47445 8.6879 4.56892 8.54861 4.70821L4.82316 8.43366C4.68387 8.57295 4.5894 8.74566 4.56054 8.91378C4.53168 9.08191 4.57078 9.23168 4.66926 9.33016Z"
      fill="#373D43"
    />
  </SvgIcon>
);

export default CopyLinkIcon;
