import { useMutation, UseMutationOptions } from 'react-query';
import { ProjectNotesAPI } from 'api';

export const useAddNote = (config?: UseMutationOptions<any>) => {
  return useMutation(ProjectNotesAPI.addNote, { ...config });
};

export const useUpdateNote = (config?: UseMutationOptions<any>) => {
  return useMutation(ProjectNotesAPI.updateNote, { ...config });
};

export const useDeleteNote = (config?: UseMutationOptions<any>) => {
  return useMutation(ProjectNotesAPI.deleteNote, { ...config });
};

export const useAddProgramNote = (config?: UseMutationOptions<any>) => {
  return useMutation(ProjectNotesAPI.addProjectNote, { ...config });
};

export const useUpdateProgramNote = (config?: UseMutationOptions<any>) => {
  return useMutation(ProjectNotesAPI.updateProgramNote, { ...config });
};

export const useDeleteProgramNote = (config?: UseMutationOptions<any>) => {
  return useMutation(ProjectNotesAPI.deleteProgramNote, { ...config });
};
