import { RequestFilesAPI, RequestTeamAPI } from 'api';
import { ISharePointPermissionUser, ROLES } from 'components/FileTypes/Files.types';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Employee } from 'components/CommonTypes/Common.types';
import { UseQueryResult } from 'react-query';

export interface IRights {
  teamRights: boolean;
  adminRights: boolean;
}

const useManagePermissions = (
  getPermissions,
  getMe,
  listOfPermissions,
  getUserHasEditRights,
  getDeleteRights,
  userQuery: UseQueryResult<Employee[], unknown>,
  requestFolderId
): IRights => {
  const [rights, setRights] = useState<IRights>({ adminRights: false, teamRights: false });
  const { addToast } = useToasts();

  const sendInvite = RequestFilesAPI.useSendInvite({
    onError: error => {
      addToast(error.response.data.error.message, { appearance: 'error' });
    }
  });

  const modifyPermissions = RequestFilesAPI.useModifyPermissions({
    onError: error => {
      addToast(error.response.data.error.message, { appearance: 'error' });
    }
  });

  const deletePermission = RequestFilesAPI.useDeletePermission({
    onError: error => {
      addToast(error.response.data.error.message, { appearance: 'error' });
    }
  });

  useEffect(() => {
    try {
      if (
        getPermissions.isSuccess &&
        listOfPermissions &&
        getUserHasEditRights &&
        userQuery.isSuccess &&
        requestFolderId
      ) {
        if (!getUserHasEditRights) {
          const isOnPermissionList = listOfPermissions.find(
            (permission: ISharePointPermissionUser) => {
              return (
                permission.grantedToV2?.user?.displayName ===
                `${getMe.data.lastName}, ${getMe.data.firstName}`
              );
            }
          );

          if (isOnPermissionList) {
            deletePermission.mutate({
              fileId: requestFolderId,
              permissionId: isOnPermissionList.shareId
            });
          }
          setRights({ teamRights: false, adminRights: false });
          return;
        }
        if (getDeleteRights) {
          setRights(prevState => ({ ...prevState, adminRights: true }));
        }
        if (getUserHasEditRights) {
          setRights(prevState => ({ ...prevState, teamRights: true }));
        }

        const userIsInPermissions = listOfPermissions.find(
          (permission: ISharePointPermissionUser) => {
            return (
              permission.grantedToV2?.user?.displayName ===
              `${getMe?.data?.lastName}, ${getMe?.data?.firstName}`
            );
          }
        );
        if (!userIsInPermissions) {
          if (!userQuery?.data[0]?.emailAddress) return; // needed to prevent errors from bad my applied data
          sendInvite.mutate({
            fileId: requestFolderId,
            emails: [{ email: userQuery?.data[0]?.emailAddress }],
            sendInvitation: false
          });
          return;
        }
        listOfPermissions.forEach((permission: ISharePointPermissionUser) => {
          if (
            permission.grantedToV2?.user &&
            permission.grantedToV2?.user?.displayName ===
              `${getMe.data.lastName}, ${getMe.data.firstName}` &&
            permission.roles[0] === ROLES.Read &&
            getUserHasEditRights
          ) {
            modifyPermissions.mutate({
              fileId: requestFolderId,
              role: ROLES.Write,
              permissionId: permission.shareId
            });
          }
        });
      }
    } catch (e) {
      console.log('e', e);
    }
  }, [
    getPermissions.data,
    getMe?.data,
    listOfPermissions,
    getUserHasEditRights,
    userQuery.data,
    getDeleteRights,
    requestFolderId
  ]);

  return rights;
};

export default useManagePermissions;
