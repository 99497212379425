import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { JamaAPI } from 'api';
import { TInfiniteQuery, TQuery } from 'api/api.types';

export const useSearchJamaProjects = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();
  const queryFn = async ({ pageParam = 1 }) => {
    const { data, ...headers } = await JamaAPI.searchJamaProjects(args, pageParam);

    queryClient.setQueryData('jama-project-headers', headers);

    return data;
  };

  return useInfiniteQuery(['jama-project-list', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true
  });
};
