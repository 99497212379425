import { useQuery, useInfiniteQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { DraftAPI } from 'api';

export const useGetDraft = ({ draftId, ...config }: { draftId: string }) => {
  return useQuery(['get-draft', draftId], () => DraftAPI.getDraft(draftId), {
    staleTime: 1000 * 60,
    ...config,
    refetchOnWindowFocus: true,
    enabled: !!draftId,
    cacheTime: 0
  });
};

export const useGetCurrentUserDrafts = ({ args, ...config }: { args: any }) => {
  const queryClient = useQueryClient();

  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await DraftAPI.getCurrentUserDrafts(args, pageParam);

    queryClient.setQueryData('drafts-headers', headers);

    return items;
  };

  return useInfiniteQuery(['drafts', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    cacheTime: 0,
    ...config
  });
};

export const useGetDraftFilterEnums = (config?: any) => {
  return useQuery('draft-filter-enums', () => DraftAPI.getDraftFilterEnums(), {
    staleTime: 1000 * 60 * 5,
    cacheTime: 0,
    ...config
  });
};
