import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const DownloadIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 7.5C0.632608 7.5 0.759785 7.55268 0.853553 7.64645C0.947322 7.74021 1 7.86739 1 8L1 11.5C1 11.7652 1.10536 12.0196 1.29289 12.2071C1.48043 12.3946 1.73478 12.5 2 12.5L14 12.5C14.2652 12.5 14.5196 12.3946 14.7071 12.2071C14.8946 12.0196 15 11.7652 15 11.5V8C15 7.86739 15.0527 7.74021 15.1464 7.64645C15.2402 7.55268 15.3674 7.5 15.5 7.5C15.6326 7.5 15.7598 7.55268 15.8536 7.64645C15.9473 7.74021 16 7.86739 16 8V11.5C16 12.0304 15.7893 12.5391 15.4142 12.9142C15.0391 13.2893 14.5304 13.5 14 13.5L2 13.5C1.46957 13.5 0.960859 13.2893 0.585786 12.9142C0.210714 12.5391 0 12.0304 0 11.5L0 8C0 7.86739 0.0526784 7.74021 0.146447 7.64645C0.240215 7.55268 0.367392 7.5 0.5 7.5Z"
      fill="#373D43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.99938 7.0003C5.09314 6.90656 5.22029 6.85391 5.35288 6.85391C5.48546 6.85391 5.61261 6.90656 5.70638 7.0003L7.99938 9.2933L10.2924 7.0003C10.3385 6.95255 10.3937 6.91445 10.4547 6.88825C10.5157 6.86205 10.5813 6.84825 10.6477 6.84768C10.7141 6.8471 10.7799 6.85975 10.8414 6.88489C10.9028 6.91003 10.9586 6.94716 11.0056 6.9941C11.0525 7.04105 11.0896 7.09688 11.1148 7.15832C11.1399 7.21977 11.1526 7.28561 11.152 7.352C11.1514 7.41839 11.1376 7.484 11.1114 7.545C11.0852 7.606 11.0471 7.66118 10.9994 7.7073L8.35338 10.3543C8.30693 10.4009 8.25175 10.4378 8.19101 10.463C8.13026 10.4882 8.06514 10.5012 7.99938 10.5012C7.93361 10.5012 7.86849 10.4882 7.80774 10.463C7.747 10.4378 7.69182 10.4009 7.64538 10.3543L4.99938 7.7073C4.90564 7.61354 4.85298 7.48638 4.85298 7.3538C4.85298 7.22122 4.90564 7.09406 4.99938 7.0003Z"
      fill="#373D43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 0.5C8.13261 0.5 8.25979 0.552678 8.35355 0.646447C8.44732 0.740215 8.5 0.867392 8.5 1L8.5 9C8.5 9.13261 8.44732 9.25979 8.35355 9.35355C8.25979 9.44732 8.13261 9.5 8 9.5C7.86739 9.5 7.74022 9.44732 7.64645 9.35355C7.55268 9.25979 7.5 9.13261 7.5 9L7.5 1C7.5 0.867392 7.55268 0.740215 7.64645 0.646447C7.74022 0.552678 7.86739 0.5 8 0.5Z"
      fill="#373D43"
    />
  </SvgIcon>
);

export default DownloadIcon;
