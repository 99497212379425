import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const UploadIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <g clip-path="url(#clip0_3429_69452)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5625 6.5625C13.4465 6.5625 13.3352 6.51641 13.2531 6.43436C13.1711 6.35231 13.125 6.24103 13.125 6.125L13.125 3.0625C13.125 2.83044 13.0328 2.60788 12.8687 2.44378C12.7046 2.27969 12.4821 2.1875 12.25 2.1875L1.75 2.1875C1.51794 2.1875 1.29538 2.27969 1.13128 2.44378C0.967189 2.60788 0.875001 2.83043 0.875001 3.0625L0.875001 6.125C0.875001 6.24103 0.828907 6.35231 0.746861 6.43436C0.664814 6.51641 0.553533 6.5625 0.437501 6.5625C0.321469 6.5625 0.210188 6.51641 0.128141 6.43436C0.0460947 6.35231 1.04041e-06 6.24103 1.05055e-06 6.125L1.31828e-06 3.0625C1.35886e-06 2.59837 0.184376 2.15325 0.512565 1.82506C0.840754 1.49687 1.28587 1.3125 1.75 1.3125L12.25 1.3125C12.7141 1.3125 13.1592 1.49688 13.4874 1.82506C13.8156 2.15325 14 2.59837 14 3.0625L14 6.125C14 6.24103 13.9539 6.35231 13.8719 6.43436C13.7898 6.51641 13.6785 6.5625 13.5625 6.5625Z"
        fill="#7D858C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.62555 6.99974C9.5435 7.08176 9.43224 7.12783 9.31623 7.12783C9.20022 7.12783 9.08897 7.08176 9.00692 6.99974L7.00055 4.99336L4.99417 6.99974C4.95381 7.04152 4.90554 7.07485 4.85216 7.09778C4.79878 7.12071 4.74138 7.13278 4.68329 7.13328C4.62519 7.13379 4.56758 7.12272 4.51382 7.10072C4.46005 7.07872 4.4112 7.04624 4.37012 7.00516C4.32905 6.96408 4.29656 6.91523 4.27456 6.86147C4.25256 6.8077 4.2415 6.75009 4.242 6.692C4.24251 6.63391 4.25457 6.5765 4.2775 6.52312C4.30043 6.46975 4.33376 6.42147 4.37555 6.38111L6.6908 4.06499C6.73144 4.02424 6.77972 3.99192 6.83287 3.96986C6.88602 3.94781 6.943 3.93645 7.00055 3.93645C7.05809 3.93645 7.11507 3.94781 7.16823 3.96986C7.22138 3.99192 7.26966 4.02424 7.3103 4.06499L9.62555 6.38111C9.70757 6.46316 9.75364 6.57442 9.75364 6.69042C9.75364 6.80643 9.70757 6.91769 9.62555 6.99974Z"
        fill="#7D858C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 12.6875C6.88397 12.6875 6.77269 12.6414 6.69064 12.5594C6.60859 12.4773 6.5625 12.366 6.5625 12.25L6.5625 5.25C6.5625 5.13397 6.60859 5.02269 6.69064 4.94064C6.77269 4.85859 6.88397 4.8125 7 4.8125C7.11603 4.8125 7.22731 4.85859 7.30936 4.94064C7.39141 5.02269 7.4375 5.13397 7.4375 5.25L7.4375 12.25C7.4375 12.366 7.39141 12.4773 7.30936 12.5594C7.22731 12.6414 7.11603 12.6875 7 12.6875Z"
        fill="#7D858C"
      />
    </g>
    <defs>
      <clipPath id="clip0_3429_69452">
        <rect width="14" height="14" fill="white" transform="translate(14 14) rotate(-180)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default UploadIcon;
