import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const FeatureIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path d="M2.5 12H11.5L7 2.5L2.5 12Z" fill="#7E6FD9" />
    <path
      d="M8.42857 7.375L9 6.75L7.28571 3L5 7.375L6.14286 8L7.28571 6.75L8.42857 7.375Z"
      fill="white"
    />
    <path d="M12 12L7 2.5" stroke="#7E6FD9" strokeWidth="0.920833" strokeLinecap="round" />
    <path d="M7 2.5L2 12" stroke="#7E6FD9" strokeWidth="0.920833" strokeLinecap="round" />
    <path d="M1.99902 12H11.999" stroke="#7E6FD9" strokeWidth="0.920833" strokeLinecap="round" />
  </SvgIcon>
);

export default FeatureIcon;
