import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const OutdentDisabledIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
    <rect y="0.666016" width="16" height="1.33333" rx="0.666667" fill="#DEE0E2" />
    <rect y="14.666" width="8" height="1.33333" rx="0.666667" fill="#DEE0E2" />
    <rect y="5.33398" width="16" height="1.33333" rx="0.666667" fill="#DEE0E2" />
    <rect y="10" width="8" height="1.33333" rx="0.666667" fill="#DEE0E2" />
    <path
      d="M9.66276 12.998L11.9961 10.6647"
      stroke="#DEE0E2"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M9.66276 12.998L11.9961 15.3314"
      stroke="#DEE0E2"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M15.3301 12.998L9.99675 12.998"
      stroke="#DEE0E2"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default OutdentDisabledIcon;
