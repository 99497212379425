import { useMutation, UseMutationOptions } from 'react-query';
import { RequestCostAPI } from 'api';

export const useAddCapitalAsset = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.addCapitalAsset, { ...config });
};

export const useAddRequestChangeOrder = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.addRequestChangeOrder, {
    ...config
  });
};

export const useUpdateCapitalAsset = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.updateCapitalAsset, {
    ...config
  });
};

export const useRemoveRequestCost = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.removeRequestCost, {
    ...config
  });
};

export const useUpdateRequestChangeOrder = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.updateRequestChangeOrder, {
    ...config
  });
};

export const useRemoveRequestChangeOrder = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.removeRequestChangeOrder, {
    ...config
  });
};

export const useUpdateRequestCostRecord = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.updateRequestCostRecord, {
    ...config
  });
};

export const useRemoveRequestCostRecord = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.removeRequestCostRecord, {
    ...config
  });
};

export const useAddCostRecord = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.addCostRecord, {
    ...config
  });
};

export const useDeleteCapitalAsset = (config?: UseMutationOptions) => {
  return useMutation(RequestCostAPI.deleteCapitalAsset, {
    ...config
  });
};
