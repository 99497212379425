import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ExcelXIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117776" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117776)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#21824D"
    />
    <path
      d="M8.51625 19.292H7.21462L6.37558 17.7051C6.3433 17.6459 6.31094 17.5372 6.27701 17.3788H6.26343C6.24708 17.4536 6.20967 17.5668 6.15129 17.719L5.30807 19.292H4L5.55315 16.8559L4.13224 14.419H5.46815L6.16486 15.8798C6.21924 15.9955 6.26743 16.133 6.31093 16.2913H6.3246C6.35139 16.1965 6.40238 16.0547 6.47755 15.8667L7.25238 14.419H8.47545L7.01417 16.8351L8.51625 19.292Z"
      fill="white"
    />
    <path d="M12.0763 19.292H9.17432V14.419H10.272V18.402H12.0763V19.292Z" fill="white" />
    <path
      d="M12.6587 19.1037V18.0162C12.8562 18.1815 13.0703 18.3059 13.301 18.3885C13.5321 18.472 13.766 18.5129 14.0015 18.5129C14.1398 18.5129 14.2604 18.5008 14.363 18.4755C14.4666 18.4503 14.5527 18.4155 14.6214 18.372C14.691 18.3276 14.7424 18.2754 14.7763 18.2154C14.8102 18.1554 14.8268 18.0901 14.8268 18.0196C14.8268 17.9248 14.7998 17.8395 14.7458 17.7647C14.691 17.6899 14.6171 17.6212 14.5231 17.5577C14.4292 17.4941 14.3169 17.4332 14.1882 17.3741C14.0592 17.3149 13.9196 17.2549 13.7704 17.194C13.3898 17.0356 13.106 16.8416 12.9191 16.6128C12.7318 16.384 12.6387 16.1082 12.6387 15.7837C12.6387 15.5305 12.6897 15.3113 12.7916 15.1294C12.8936 14.9476 13.0325 14.7971 13.2079 14.6796C13.3836 14.5613 13.5865 14.4752 13.818 14.4195C14.0491 14.3638 14.2934 14.336 14.5518 14.336C14.8059 14.336 15.0312 14.3516 15.2261 14.3821C15.4227 14.4125 15.6037 14.4595 15.7681 14.523V15.5392C15.6872 15.4827 15.5985 15.4322 15.5019 15.3896C15.4053 15.3469 15.3061 15.3113 15.2044 15.2825C15.1026 15.2538 15.0016 15.233 14.8998 15.2199C14.7998 15.206 14.7041 15.199 14.6127 15.199C14.4892 15.199 14.3752 15.2112 14.2734 15.2347C14.1716 15.2591 14.0854 15.2921 14.0147 15.3348C13.945 15.3783 13.8906 15.4296 13.8516 15.4896C13.8135 15.5497 13.7938 15.6175 13.7938 15.6923C13.7938 15.7733 13.8159 15.8463 13.8583 15.9116C13.9018 15.976 13.9631 16.0369 14.0423 16.0943C14.1221 16.1526 14.2177 16.2091 14.3309 16.2648C14.4448 16.3205 14.5718 16.377 14.7154 16.4362C14.9103 16.518 15.0852 16.6041 15.24 16.6963C15.3958 16.7877 15.5289 16.8912 15.6394 17.0069C15.7507 17.1226 15.836 17.2549 15.8943 17.4028C15.9534 17.5516 15.983 17.7247 15.983 17.9213C15.983 18.1936 15.9317 18.4216 15.8282 18.606C15.7255 18.7913 15.5854 18.941 15.4079 19.0549C15.2313 19.1689 15.026 19.2516 14.7911 19.3012C14.5571 19.3508 14.31 19.376 14.0491 19.376C13.7819 19.376 13.5274 19.3534 13.2857 19.3081C13.0448 19.262 12.8357 19.1942 12.6587 19.1037Z"
      fill="white"
    />
    <path
      d="M20.8277 19.292H19.5261L18.6866 17.7051C18.6552 17.6459 18.6222 17.5372 18.5882 17.3788H18.5743C18.5587 17.4536 18.5221 17.5668 18.4621 17.719L17.619 19.292H16.3105L17.8644 16.8559L16.4437 14.419H17.7791L18.476 15.8798C18.53 15.9955 18.5795 16.133 18.6222 16.2913H18.6352C18.6631 16.1965 18.7135 16.0547 18.7884 15.8667L19.5635 14.419H20.7868L19.3251 16.8351L20.8277 19.292Z"
      fill="white"
    />
  </SvgIcon>
);

export default ExcelXIcon;
