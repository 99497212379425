import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChevronNew: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 12 12" fill="none" {...props}>
    <path d="M10.4599 2.87062C10.6296 2.70256 10.8588 2.60829 11.0976 2.60829C11.3365 2.60829 11.5657 2.70256 11.7354 2.87062C11.8192 2.95314 11.8858 3.05151 11.9312 3.15999C11.9766 3.26848 12 3.38491 12 3.50252C12 3.62013 11.9766 3.73657 11.9312 3.84506C11.8858 3.95354 11.8192 4.05191 11.7354 4.13443L6.63843 9.12969C6.46831 9.29751 6.23896 9.3916 6 9.3916C5.76104 9.3916 5.53168 9.29751 5.36157 9.12969L0.264581 4.13443C0.180783 4.05191 0.114239 3.95354 0.0688155 3.84506C0.0233923 3.73657 -3.57628e-07 3.62013 -3.57628e-07 3.50252C-3.57628e-07 3.38491 0.0233923 3.26848 0.0688155 3.15999C0.114239 3.05151 0.180783 2.95314 0.264581 2.87062C0.434694 2.7028 0.664046 2.6087 0.903008 2.6087C1.14197 2.6087 1.37132 2.7028 1.54144 2.87062L6.00196 6.96725L10.4599 2.87062Z" />
  </SvgIcon>
);

export default ChevronNew;
