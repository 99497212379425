import { get, post, put, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/servicenow`;

export const addTransferIncident = async ({ requestId }: any) => {
  return (
    await post({
      url: `${BASE_URL}/transfer-incident/${requestId}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  ).data;
};

export const addTransferRequest = async ({ requestId }: any) => {
  return (
    await post({
      url: `${BASE_URL}/transfer-request/${requestId}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  ).data;
};
