import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#fafbfb',
    height: '100vh',
    fontSize: '14px'
  },
  appBarSpacer: { minHeight: 52 },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: 52
  },
  container: {
    // paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    marginTop: -52,
    height: '100%'
    // overflow: 'scroll'
    // overflow: 'hidden'
  }
}));
