import { useQuery } from 'react-query';
import { TQuery } from 'api/api.types';
import { reduceToOptions } from 'api/api.utils';
import { RequestStatusAPI } from 'api';

export const useRequestStatus = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['request-status', args], () => RequestStatusAPI.getRequestStatus(args), {
    staleTime: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true,
    enabled: !!args.id
  });
};

export const useRequestStatusEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestStatusAPI.getRequestStatusEnums());

  return useQuery(['request-status-enums'], queryFn, {
    staleTime: Infinity,
    ...config
  });
};

export const useGetSubStatusEnums = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['sub-status-enums', args],
    async () => await RequestStatusAPI.getSubStatusEnums(args),
    {
      staleTime: Infinity,
      enabled: !!args.statusId,
      cacheTime: 0,
      ...config,
      refetchOnWindowFocus: true
    }
  );
};

export const useGetHoldStatusHistory = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['hold-status-history', args],
    () => RequestStatusAPI.getHoldStatusHistory(args),
    {
      staleTime: Infinity,
      ...config
    }
  );
};

export const useUserTransferRights = ({ args, config }: TQuery = {}) => {
  return useQuery(['user-transfer-rights'], () => RequestStatusAPI.getUserTransferRights(args), {
    staleTime: Infinity,
    ...config
  });
};

export const useEscalationEditRights = ({ args, ...config }: TQuery = {}) => {
  return useQuery(
    ['escalation-edit-rights', args],
    () => RequestStatusAPI.getEscalationEditRights(args),
    {
      staleTime: Infinity,
      ...config
    }
  );
};
