import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { render } from 'utils/general.utils';
import APIErrorIcon from 'components/icons/APIError.icon';

interface IErrorBoundary {
  fallback?: any;
}

const OopsSomethingWentWrong = () => (
  <Grid container alignItems="center" style={{ height: '100%', padding: 8 }}>
    <Grid container justify="center" spacing={3} style={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          Looks like something went wrong!
        </Typography>
      </Grid>

      <Grid item xs={8} sm={6} md={4} lg={3}>
        <APIErrorIcon style={{ height: '100%', width: '100%' }} />
      </Grid>

      <Grid item xs={12}>
        <Typography paragraph variant="body1" style={{ fontWeight: 'bold' }}>
          We track these errors automatically, but if the problem persists feel free to contact us.
          In the meantime, try refreshing.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={() => {
            window.location.assign('/dashboard/explore/assigned');
          }}
        >
          Return To Dashboard
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

class ErrorBoundary extends Component<IErrorBoundary> {
  state = { error: null };

  static getDerivedStateFromError(error: Error) {
    // Update state so next render shows fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, info: any) {
    // Log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    const { fallback } = this.props;

    if (this.state.error) {
      if (fallback) return render(fallback, { error: this.state.error });

      return (
        <Grid container alignItems="center" style={{ height: '100vh', overflow: 'hidden' }}>
          <OopsSomethingWentWrong />
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
