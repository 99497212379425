import { useMutation, UseMutationOptions } from 'react-query';
import { RequestTeamAPI } from 'api';

export const useAddResourceSubDepartments = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestTeamAPI.addResourceSubDepartments, { ...config });
};

export const useDeleteResourceSubDepartments = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.deleteResourceSubDepartments, { ...config });
};

export const useAddResourceAreas = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestTeamAPI.addResourceAreas, { ...config });
};

export const useDeleteResourceAreas = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.deleteResourceAreas, { ...config });
};

export const useAddResourceTeams = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestTeamAPI.addResourceTeams, { ...config });
};

export const useDeleteResourceTeams = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.deleteResourceTeams, { ...config });
};

export const useDeleteResourceTeam = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.deleteResourceTeam, { ...config });
};

export const useUpdateProjectManager = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.updateProjectManager, { ...config });
};

export const useUpdateProjectLead = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.updateProjectLead, { ...config });
};

export const useAddResourceTeamMember = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.addResourceTeamMember, { ...config });
};

export const useUpdateRequestTeamDataset = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.updateRequestTeamDataset, { ...config });
};

export const useSendNotifications = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.sendNotifications, { ...config });
};

export const useMarkRequestComplete = (config?: UseMutationOptions) => {
  return useMutation(RequestTeamAPI.markRequestComplete, { ...config });
};
