import { ProgramsAPI } from 'api';
import { TInfiniteQuery, TQuery } from 'api/api.types';
import { reduceToOptions } from 'api/api.utils';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

export const usePrograms = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await ProgramsAPI.getPrograms(args, pageParam);

    queryClient.setQueryData(['programs-headers'], headers);

    return items;
  };

  return useInfiniteQuery(['programs', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    cacheTime: 0,
    ...config
  });
};

export const useProgramFilterEnums = (config = {}) => {
  const queryFn = async () => reduceToOptions(await ProgramsAPI.getProgramFilterEnums());

  return useQuery(['program-filter-enums'], queryFn, {
    staleTime: Infinity,
    ...config
  });
};

export const useProgramRequestFilterEnums = (config = {}) => {
  const queryFn = async () => reduceToOptions(await ProgramsAPI.getProgramRequestFilterEnums());

  return useQuery(['program-request-filter-enums'], queryFn, {
    staleTime: Infinity,
    ...config
  });
};

export const useProgramFilterDefaults = (config: TQuery = {}) => {
  const queryFn = async () => await ProgramsAPI.getProgramFilterDefaults();

  return useQuery(['program-filter-defaults'], queryFn, { staleTime: Infinity, ...config });
};

export const useGetProgram = ({ args, ...config }: { args: { id: string | number } }) => {
  const queryFn = async () => await ProgramsAPI.getProgram(args?.id);

  return useQuery(['program', args], queryFn, {
    staleTime: Infinity,
    enabled: !!args?.id,
    ...config
  });
};

export const useGetProgramEditRights = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['program-edit-rights', args], () => ProgramsAPI.getProgramEditRights(args), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetProgramRequests = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();
  const queryFn = async ({ pageParam = 1, ...restProps }: any) => {
    const { items, ...headers } = await ProgramsAPI.getProgramRequests(args, pageParam);

    queryClient.setQueryData(['program-requests-headers'], headers);

    return items;
  };

  return useInfiniteQuery(['program-requests', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    cacheTime: 0,
    ...config
  });
};

export const useGetProgramStatistics = ({ args, ...config }: { args: number }) => {
  return useQuery(['program-statistics', args], () => ProgramsAPI.getProgramStatistics(args), {
    staleTime: 1000 * 60 * 5,
    enabled: !!args && typeof args === 'number',
    refetchOnMount: 'always',
    ...config
  });
};
