import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ArrowDownIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 6 14" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.61492 10.4173L5.61182 10.4204L3.26141 12.8829C3.22545 12.9198 3.18249 12.9491 3.13506 12.9692C3.08762 12.9892 3.03666 12.9996 2.98516 12.9997C2.93756 13.0012 2.89022 12.9922 2.84645 12.9735C2.80268 12.9547 2.76355 12.9266 2.73179 12.8911L2.72288 12.8815L2.67872 12.8375L0.373921 10.4204C0.300457 10.3395 0.259835 10.2341 0.26001 10.1249C0.260186 10.0156 0.301147 9.91033 0.374871 9.82969C0.408533 9.7926 0.44958 9.76297 0.495375 9.74269C0.541169 9.72242 0.590698 9.71194 0.640781 9.71194C0.690864 9.71194 0.740394 9.72242 0.786188 9.74269C0.831982 9.76297 0.873029 9.7926 0.906691 9.82969L2.6196 11.609V1.37922C2.62003 1.27849 2.66035 1.18204 2.73172 1.11097C2.8031 1.0399 2.89972 1 3.00045 1C3.10118 1 3.1978 1.0399 3.26918 1.11097C3.34056 1.18204 3.38087 1.27849 3.38131 1.37922V11.609L5.09257 9.83141C5.12643 9.79402 5.16772 9.76408 5.21379 9.74352C5.25986 9.72295 5.3097 9.71221 5.36015 9.71196C5.40978 9.71184 5.45886 9.72223 5.50418 9.74245C5.55037 9.76298 5.59191 9.79266 5.62629 9.82971C5.69888 9.90766 5.739 10.0104 5.73848 10.1169C5.73872 10.1726 5.72792 10.2279 5.70672 10.2794C5.68551 10.331 5.65431 10.3778 5.61492 10.4173Z"
      fill="#7D858C"
      stroke="#7D858C"
      strokeWidth="0.5"
    />
  </SvgIcon>
);

export default ArrowDownIcon;
