import { get, post, put, patch, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';
import { identity } from 'ramda';

const BASE_URL = `${getEnv('IT_BASE_URL')}/team-planner`;

export const getTeamPlannerDropdown = async ({
  deptId,
  subDeptId,
  areaId,
  teamId,
  params
} = {}) => {
  let url = `${BASE_URL}/dropdowns?`;
  if (deptId.length > 0)
    deptId.map(id => {
      if (id) url += `deptId=${id}`;
    });
  if (subDeptId.length > 0)
    subDeptId.map(id => {
      if (id) url += `&subDeptId=${id}`;
    });
  if (areaId.length > 0)
    areaId.map(id => {
      if (id) url += `&areaId=${id}`;
    });
  if (teamId.length > 0)
    teamId.map(id => {
      if (id) url += `&teamId=${id}`;
    });
  const response = await get({
    url: url,
    params: { ...params }
  });

  return response.data;
};

export const getTeamPlannerData = async ({ deptId, subDeptId, areaId, teamId, params } = {}) => {
  let url = `${BASE_URL}/dataset?`;
  if (deptId.length > 0)
    deptId.map(id => {
      if (id) url += `deptId=${id}`;
    });
  if (subDeptId.length > 0)
    subDeptId.map(id => {
      if (id) url += `&subDeptId=${id}`;
    });
  if (areaId.length > 0)
    areaId.map(id => {
      if (id) url += `&areaId=${id}`;
    });
  if (teamId.length > 0)
    teamId.map(id => {
      if (id) url += `&teamId=${id}`;
    });
  const response = await get({
    url: url,
    params: { ...params }
  });

  return {
    items: response.data,
    backlogFilterCount: JSON.parse(response.headers.backlogfiltercount),
    inProcessFilterCount: JSON.parse(response.headers.inprocessfiltercount),
    backlogColumnData: JSON.parse(response.headers.backlogcolumndata),
    inProcessColumnData: JSON.parse(response.headers.inprocesscolumndata)
  };
};

export const getDefaultUserGroup = async () => {
  return (await get({ url: `${BASE_URL}/current-user-default-group` })).data;
};

export const getMembersForAdd = async ({ deptId, subDeptId, areaId, teamId }: any) => {
  return (
    await get({
      url: `${BASE_URL}/team-members-for-add?deptId=${deptId}&subDeptId=${subDeptId}&areaId=${areaId}&teamId=${teamId}`
    })
  ).data;
};
export const getMembersForEdit = async ({ deptId, subDeptId, areaId, teamId, requestId }: any) => {
  return (
    await get({
      url: `${BASE_URL}/team-members-for-edit/${requestId}?deptId=${deptId}&subDeptId=${subDeptId}&areaId=${areaId}&teamId=${teamId}`
    })
  ).data;
};

export const unAssignTeamMember = async ({
  requestId,
  teamMemberId,
  groupId,
  groupTypeId
}: any) => {
  return (
    await del({
      url: `${BASE_URL}/requests/${requestId}/team-members/${teamMemberId}?groupId=${groupId}&groupTypeId=${groupTypeId}`
    })
  ).data;
};

export const getTeamPlannerEditRights = async ({ groupId, groupTypeId }: TAPIArgs = {}) => {
  return (
    await get({
      url: `${BASE_URL}/edit-rights?groupId=${groupId}&groupTypeId=${groupTypeId}`
    })
  ).data;
};

export const assignTeamMembers = async ({ requestId, data }: any) => {
  return (
    await post({
      url: `${BASE_URL}/requests/${requestId}/team-members`,
      data
    })
  ).data;
};

export const editTeamMembers = async ({ requestId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/requests/${requestId}/team-members`,
      data
    })
  ).data;
};

export const getFilterEnums = async () => {
  return (await get({ url: `${BASE_URL}/filter-enums` })).data;
};

export const markWorkComplete = async ({ requestId, teamMemberId, groupId, groupTypeId }: any) => {
  return (
    await put({
      url: `${BASE_URL}/requests/${requestId}/team-members/${teamMemberId}/mark-complete?groupId=${groupId}&groupTypeId=${groupTypeId}`
    })
  ).data;
};
