import React from 'react';
import { get, set, del, clear, keys, Store } from 'idb-keyval';
import _ from 'lodash';
import { useQueries } from 'react-query';
//@ts-ignore
import { Async } from 'react-query-async';

const DEFAULT_STORE = new Store('projects.appliedmedical', 'databases');

const getItem = async (key: string, store: Store = DEFAULT_STORE) => {
  // const item = (await get(key, store)) as any;

  // if (item.version)
  // console.log('key:', key);
  const response = ((await get(key, store)) as any)?.data;
  // console.log('idb response:', response);
  // if ()

  return response;
};

const setItem = async (key: string, value: any, store: Store = DEFAULT_STORE) => {
  const prevItem = await getItem(key, store);

  if (_.isEqual(prevItem, value)) return;

  return set(key, { data: value, updatedAt: new Date().getTime() }, store);
};

export default {
  getItem,
  setItem,
  removeItem: (key: string, store: Store = DEFAULT_STORE) => del(key, store),
  clear: (store: Store = DEFAULT_STORE) => clear(store),
  keys: (store: Store = DEFAULT_STORE) => keys(store)
};

export const withStorage = (keys: string[], ChildComponent: React.FunctionComponent) => (
  props: any
) => {
  const queries = useQueries(
    keys.map((key: string) => ({ queryKey: key, queryFn: () => getItem(key) }))
  );

  return (
    <Async
      isLoading={queries.some(query => query.isLoading)}
      hasError={queries.some(query => query.isError)}
      hasData
    >
      {() => <ChildComponent storage={queries.map(query => query.data)} {...props} />}
    </Async>
  );
};
