import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChevronBoldIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 10 16" fill="none" {...props}>
    <path
      d="M13.9465 0.827572C14.1728 0.603499 14.4784 0.477801 14.7969 0.477801C15.1153 0.477801 15.4209 0.603499 15.6472 0.827572C15.759 0.937604 15.8477 1.06876 15.9082 1.21341C15.9688 1.35805 16 1.5133 16 1.67011C16 1.82693 15.9688 1.98217 15.9082 2.12682C15.8477 2.27147 15.759 2.40262 15.6472 2.51266L8.85124 9.173C8.62442 9.39676 8.31862 9.52222 8 9.52222C7.68138 9.52222 7.37558 9.39676 7.14876 9.173L0.352775 2.51266C0.241045 2.40262 0.152319 2.27147 0.0917544 2.12682C0.0311901 1.98217 -1.19209e-07 1.82693 -1.19209e-07 1.67011C-1.19209e-07 1.5133 0.0311901 1.35805 0.0917544 1.21341C0.152319 1.06876 0.241045 0.937604 0.352775 0.827572C0.579593 0.603809 0.885396 0.478354 1.20401 0.478354C1.52263 0.478354 1.82843 0.603809 2.05525 0.827572L8.00261 6.28975L13.9465 0.827572Z"
      fill={props.fill ? props.fill : '#7D858C'}
    />
  </SvgIcon>
);

export default ChevronBoldIcon;
