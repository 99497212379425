import { useEffect } from 'react';
import { QueryClient } from 'react-query';
import idb from 'services/idb.service';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 0,
      cacheTime: Infinity
    },
    mutations: {
      retry: 0
    }
  }
});

export const useIdbHydrate = () => {
  useEffect(() => {
    hydrateFromIDB();
  }, []);
};

export const hydrateFromIDB = async () => {
  const keys = await idb.keys();
  const values = await Promise.all(keys.map(key => idb.getItem(key)));

  keys.forEach((key, idx) => {
    queryClient.setQueryData(key, values[idx]);
  });
};

export const getIsLoading = (hook: QueryResultBase<[]>): boolean => {
  if (Array.isArray(hook)) {
    return hook.some(el => el.status === 'loading');
  }

  return hook.status === 'loading';
};

export const getIsFetching = (hook: QueryResultBase<[]>): boolean => {
  if (Array.isArray(hook)) {
    return hook.some(el => el.isFetching);
  }

  return hook.isFetching;
};

export const getHasError = (hook: QueryResultBase<[]>): boolean => {
  if (Array.isArray(hook)) {
    return hook.some(el => el.error);
  }

  return !!hook.error;
};

export const getHasData = (hook: QueryResultBase<[]>): boolean => {
  if (Array.isArray(hook)) {
    return hook.every(el => el.data);
  }

  return !!hook.data;
};

export const getErrors = (hook: QueryResultBase<[]>): unknown => {
  if (Array.isArray(hook)) {
    return hook.reduce((acc, el) => {
      if (el.error) acc.push(el.error);
      return acc;
    }, []);
  }

  return hook.error;
};
