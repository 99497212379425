import { get, post, put, patch, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/request-status`;

export const getRequestStatus = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/${id}` })).data;
};

export const getRequestStatusEnums = async () => {
  return (await get({ url: `${BASE_URL}/request-status-enums` })).data;
};

export const getSubStatusEnums = async ({ statusId, methodologyId }: any) => {
  let url = `${BASE_URL}/sub-status-by-status/${statusId}`;
  if (methodologyId) url += `?mId=${methodologyId}`;

  return (await get({ url: url })).data;
};

export const updateRequestStatus = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}`, data })).data;
};

export const getHoldStatusHistory = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/${id}/hold-status-history` })).data;
};

export const getUserTransferRights = async ({ requestId }: any) => {
  return (await get({ url: `${BASE_URL}/${requestId}/user-transfer-rights` })).data;
};

export const updateServiceDeskTransfer = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/servicedesk-transfer`, data })).data;
};
export const getEscalationEditRights = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/${id}/escalation-edit-rights` })).data;
};

export const updateRequestStatusApproval = async data => {
  return (
    await post({
      url: `${getEnv('IT_BASE_URL')}/request-status/${data.requestId}/approval-status-update`,
      data
    })
  ).data;
};
