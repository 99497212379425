import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const BoldCheckIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="10" fill={props.circleFill} />
    <path
      d="M15.7284 7.0991L15.7262 7.10131L8.59215 14.198C8.36521 14.4249 8.15155 14.5378 7.93014 14.5378C7.70874 14.5378 7.48733 14.4252 7.24295 14.2002C6.47993 13.4657 5.73129 12.6811 5.00729 11.9222C4.77177 11.6753 4.52794 11.4201 4.28772 11.1716C4.03863 10.9333 3.94564 10.63 4.02646 10.3178C4.07413 10.1453 4.16572 9.98817 4.29228 9.86166C4.41883 9.73515 4.57603 9.64363 4.74852 9.59602C5.0607 9.51493 5.36403 9.60792 5.6026 9.85755L7.9509 12.1699L14.3717 5.74906C14.8214 5.30154 15.3694 5.43245 15.6885 5.74408C16.0312 6.07841 16.1549 6.65019 15.7284 7.0991Z"
      fill={props.pathFill}
    />
  </SvgIcon>
);

export default BoldCheckIcon;
