import axios from 'axios';
import React from 'react';
import { pathOr, path } from 'ramda';
import gql from 'nanographql';
import qs from 'qs';
import { JwtToken, RefreshToken, updateSharePointJWT } from './auth.service';
import { getEnv } from './apiEnv.service';
// import jwt_decode from 'jwt-decode';

const instance = axios.create({
  baseURL: getEnv('BASE_URL')
});

export const sharePointInstance = axios.create({
  baseURL: 'https://graph.microsoft.com/v1.0/drives/'
});

let isRefreshing = false;
let refreshSubscribers = [];
export let tokenExpired: boolean = false;

const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

export const onRefreshed = token => {
  refreshSubscribers.map(cb => cb(token));
  refreshSubscribers = [];
};

// const isExp = (token: string) => {
//   const decodedToken = jwt_decode(token);
//   let currentDate = new Date();
//   // JWT exp is in seconds
//   if ((decodedToken?.exp + 300) * 1000 >= currentDate.getTime()) {
//     return false;
//   } else {
//     return true;
//   }
// };

// instance.interceptors.response.use(
//   response => {
//     // console.log('res -----\n ', response)
//     // const isExpired = isExp(JwtToken)
//     // console.log('RESPONSE -> ',isExpired)
//     return response;
//   },
//   error => {
//     // console.log('err  -----------\n',error, error?.response)
//     const isExpired = isExp(JwtToken);
//     // console.log('ERRROR -> ',isExpired)
//     if (isExpired) {
//       const currPath = window.location.pathname + window.location.search;
//       sessionStorage.setItem('auth-refresh-redirect', currPath);
//       // window.location.reload();
//     }
//     throw error;
//   }
// );

export const refreshSharePointToken = async () => {
  try {
    const { data } = await get({
      method: 'get',
      url: `${getEnv('IT_BASE_URL')}/user/get-files-auth`
    });
    updateSharePointJWT(data.access_token);
    return data.access_token;
  } catch (e) {
    console.log('e', e);
    console.log('failed to refresh sharepoint token');
  }
};

export const isGraphqlAuthError = ({ data }) => {
  return pathOr('', ['extensions', 'code'], pathOr('', ['errors'], data)[0]) === 'invalid-jwt';
};

const isGraphqlError = ({ data }) => {
  if (data.errors) return true;
  if (data.data && data.data.errors) return true;
  return false;
};

sharePointInstance.interceptors.response.use(
  async function (response) {
    if (isGraphqlAuthError(response) && RefreshToken) {
      const { config } = response;
      const originalRequest = config;
      const queueRetry = new global.Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;
        const jwt = await refreshSharePointToken();
        onRefreshed(jwt);
        isRefreshing = false;
        // isRefreshing = true;
        // await refreshJwtToken(response);
        // onRefreshed(getHasuraJwtToken());
        // isRefreshing = false;
      }
      return queueRetry;
    }
    if (isGraphqlError(response)) {
      const graphqlError = new Error(response.data.errors[0].message);
      response.original_status = response.status;
      response.status = response.data.errors[0].extensions.code;
      graphqlError.response = response;
      return global.Promise.reject(graphqlError);
    }
    return response;
  },
  async function (error) {
    const hasJwtToken = pathOr(false, ['config', 'headers', 'Authorization'], error);
    const { config, response } = error;
    const originalRequest = config;
    const is401 = path(['response', 'status'], error) === 401;
    const queueRetry = new global.Promise(resolve => {
      if (is401) {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      }
    });

    if (is401) {
      if (!isRefreshing) {
        isRefreshing = true;
        const jwt = await refreshSharePointToken();
        onRefreshed(jwt);
        isRefreshing = false;
      }
      return queueRetry;
    }

    return global.Promise.reject(error);
  }
);

export const get = props =>
  instance({
    method: 'get',
    params: {
      isPreview: getEnv('IS_PREVIEW'),
      ...props.params
    },
    paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    ...props
  });

export const post = props =>
  instance({
    method: 'post',
    paramsSerializer: params => qs.stringify(params),
    ...props
  });

export const put = props =>
  instance({
    method: 'put',
    paramsSerializer: params => qs.stringify(params),
    ...props
  });

export const patch = props =>
  instance({
    method: 'patch',
    paramsSerializer: params => qs.stringify(params),
    ...props
  });

export const del = props =>
  instance({
    method: 'delete',
    paramsSerializer: params => qs.stringify(params),
    ...props
  });

export const sharePointGet = props =>
  sharePointInstance({
    method: 'get',
    paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    ...props
  });

export const sharePointPost = props =>
  sharePointInstance({
    method: 'post',
    paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    ...props
  });

export const sharePointPut = props =>
  sharePointInstance({
    method: 'put',
    paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    ...props
  });

export const sharePointPatch = props =>
  sharePointInstance({
    method: 'patch',
    paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    ...props
  });

export const sharePointDelete = props =>
  sharePointInstance({
    method: 'delete',
    paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    ...props
  });

export default instance;

export const delay = ms => new global.Promise(resolve => setTimeout(resolve, ms));

export const graphql = async (ql, variables = {}) => {
  const query = JSON.parse(gql(ql)(variables));

  const response = await post({
    url: 'https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api/auth0api',
    data: {
      query,
      // jwtToken: JwtToken,
      //devMode: getEnv('DEV_MODE')
      devMode: false
    }
  });

  return response;
};
