import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddNewFolderIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M15.4253 8.55982L0.574669 8.55982C0.499926 8.56184 0.425535 8.54886 0.355893 8.52165C0.28625 8.49443 0.222768 8.45354 0.169197 8.40138C0.115626 8.34922 0.0730528 8.28685 0.043992 8.21795C0.0149312 8.14906 -2.72763e-05 8.07504 3.73378e-08 8.00027C3.73378e-08 7.84786 0.0605451 7.70169 0.168317 7.59392C0.276088 7.48615 0.422257 7.4256 0.574669 7.4256L15.4253 7.4256C15.5777 7.4256 15.7239 7.48615 15.8317 7.59392C15.9395 7.70169 16 7.84786 16 8.00027C16 8.07504 15.9851 8.14906 15.956 8.21795C15.9269 8.28685 15.8844 8.34922 15.8308 8.40138C15.7772 8.45354 15.7137 8.49443 15.6441 8.52165C15.5745 8.54886 15.5001 8.56184 15.4253 8.55982Z"
      fill="#373D43"
    />
    <path
      d="M8.0001 16C7.84768 16 7.70151 15.9395 7.59374 15.8317C7.48597 15.7239 7.42543 15.5777 7.42543 15.4253L7.42543 0.574669C7.42543 0.422257 7.48597 0.276088 7.59374 0.168317C7.70151 0.0605451 7.84768 3.73377e-08 8.0001 3.73377e-08C8.07487 -2.72763e-05 8.14888 0.0149312 8.21778 0.043992C8.28667 0.0730528 8.34904 0.115626 8.4012 0.169197C8.45336 0.222768 8.49426 0.28625 8.52147 0.355893C8.54868 0.425535 8.56166 0.499926 8.55964 0.574669L8.55964 15.4253C8.56166 15.5001 8.54868 15.5745 8.52147 15.6441C8.49426 15.7137 8.45336 15.7772 8.4012 15.8308C8.34904 15.8844 8.28667 15.9269 8.21778 15.956C8.14888 15.9851 8.07487 16 8.0001 16Z"
      fill="#373D43"
    />
  </SvgIcon>
);

export default AddNewFolderIcon;
