import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PopoutIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_4030_106975)">
      <path d="M11.2991 0.0625C11.2454 0.08875 11.1963 0.12375 11.1544 0.165625C11.101 0.219375 11.0591 0.284063 11.0322 0.356562C11.0588 0.28375 11.1007 0.21875 11.1544 0.165C11.1963 0.123125 11.2454 0.088125 11.2991 0.0625Z" />
      <path d="M14.9277 4.12875C14.9277 4.11969 14.928 4.11062 14.9284 4.10156L14.9277 4.29531V4.12875Z" />
      <path d="M15.9996 0.375L15.9893 4.47187C15.9893 4.75531 15.7665 4.98687 15.4865 5.00125H15.4306C15.1503 4.98687 14.9278 4.75531 14.9278 4.47187V4.29531L14.9284 4.10156V4.10031L14.9371 1.81375L14.915 1.79187L4.81746 11.8912C4.78277 11.9262 4.74121 11.9541 4.6959 11.9728C4.65027 11.9919 4.60121 12.0016 4.55184 12.0016C4.50246 12.0016 4.45371 11.9919 4.40809 11.9728C4.36246 11.9541 4.32121 11.9262 4.28621 11.8912L4.10934 11.7144C4.07434 11.6797 4.04684 11.6381 4.02777 11.5925C4.00902 11.5469 3.99902 11.4981 3.99902 11.4487C3.99902 11.3994 4.00902 11.3506 4.02777 11.305C4.04684 11.2594 4.07434 11.2178 4.10934 11.1831L14.209 1.08469L14.1871 1.0625L12.2806 1.06969L11.8709 1.07125H11.5284C11.2362 1.07125 10.9996 0.83375 10.9996 0.540937C10.9996 0.513437 11.0018 0.486562 11.0056 0.46C11.0112 0.424375 11.02 0.389687 11.0321 0.356875C11.059 0.284375 11.1009 0.219687 11.1543 0.165937C11.1962 0.124062 11.2453 0.0890625 11.299 0.0628125C11.3221 0.051875 11.3462 0.0421875 11.3712 0.034375C11.4043 0.0240625 11.439 0.016875 11.4743 0.0134375C11.4921 0.0115625 11.5103 0.010625 11.5284 0.010625V0.0103125L15.6246 0C15.7243 0 15.8196 0.039375 15.89 0.109687C15.9603 0.180312 15.9996 0.275625 15.9996 0.375Z" />
      <path d="M14 8.5V14.5C14 14.8978 13.8422 15.2794 13.5606 15.5606C13.2794 15.8419 12.8978 16 12.5 16H1.5C1.10219 16 0.720625 15.8419 0.439375 15.5606C0.158125 15.2794 0 14.8978 0 14.5V3.5C0 3.10219 0.158125 2.72062 0.439375 2.43937C0.720625 2.15812 1.10219 2 1.5 2H7.49844C7.77437 2 7.99844 2.22375 7.99844 2.5C7.99844 2.63813 7.9425 2.76313 7.85187 2.85375C7.76156 2.94406 7.63656 3 7.49844 3H1.5C1.3675 3 1.24031 3.05281 1.14656 3.14656C1.05281 3.24031 1 3.3675 1 3.5V14.5C1 14.6325 1.05281 14.7597 1.14656 14.8534C1.24031 14.9472 1.3675 15 1.5 15H12.5C12.6328 15 12.76 14.9472 12.8538 14.8534C12.9475 14.7597 13 14.6325 13 14.5V8.5C13 8.22375 13.2241 8 13.5 8C13.6381 8 13.7631 8.05594 13.8534 8.14625C13.9441 8.23687 14 8.36188 14 8.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_4030_106975">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default PopoutIcon;
