import { useQuery } from 'react-query';
import { ScoringAPI } from 'api';
import { TQuery } from 'api/api.types';
import { reduceToOptionsScore } from 'api/api.utils';

export const useGetScoreCardBySearch = ({ args, ...config }: any) => {
  return useQuery(
    ['score-card-by-search', args],
    async () => ScoringAPI.getScoreCardBySearch(args),
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
      enabled: !!args?.requestTypeId && !!args?.projectTypeId && !!args?.enabled,
      ...config
    }
  );
};

export const useGetScoreCardType = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['score-card-type', args], () => ScoringAPI.getScoreCardType(args), {
    staleTime: 1000 * 60 * 5,
    enabled: !!args?.id,
    cacheTime: 0,
    ...config
  });
};

export const useGetScoreTabCardType = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['score-tab-card-type', args], () => ScoringAPI.getScoreTabCardType(args), {
    staleTime: 1000 * 60 * 5,
    enabled: !!args?.requestId,
    cacheTime: 0,
    ...config
  });
};

export const useGetRequestScoreCard = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['request-score-card', args], () => ScoringAPI.getRequestScoreCard(args), {
    staleTime: 1000 * 60 * 5,
    ...config,
    enabled: !!args?.requestId
  });
};

export const useScoringEnums = ({ ...config }: TQuery = {}) => {
  const queryFn = async () => reduceToOptionsScore(await ScoringAPI.getScoringEnums());

  return useQuery(['request-scoring-enums'], queryFn, { staleTime: Infinity, ...config });
};
