import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ErrorIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <circle cx="24" cy="24" r="23" stroke="#CC4A4A" fill="#FFFFFF" strokeWidth="2" />
    <path
      d="M25.7217 23.9973L30.9722 18.759C31.2022 18.5291 31.3313 18.2172 31.3313 17.8921C31.3313 17.5669 31.2022 17.255 30.9722 17.0251C30.7423 16.7952 30.4305 16.666 30.1053 16.666C29.7801 16.666 29.4683 16.7952 29.2384 17.0251L24.0001 22.2756L18.7618 17.0251C18.5319 16.7952 18.22 16.666 17.8949 16.666C17.5697 16.666 17.2579 16.7952 17.0279 17.0251C16.798 17.255 16.6688 17.5669 16.6688 17.8921C16.6688 18.2172 16.798 18.5291 17.0279 18.759L22.2784 23.9973L17.0279 29.2355C16.9135 29.349 16.8226 29.4841 16.7607 29.6329C16.6987 29.7817 16.6667 29.9413 16.6667 30.1025C16.6667 30.2637 16.6987 30.4233 16.7607 30.5721C16.8226 30.7209 16.9135 30.8559 17.0279 30.9694C17.1414 31.0839 17.2765 31.1747 17.4253 31.2367C17.5741 31.2987 17.7337 31.3306 17.8949 31.3306C18.0561 31.3306 18.2157 31.2987 18.3645 31.2367C18.5132 31.1747 18.6483 31.0839 18.7618 30.9694L24.0001 25.7189L29.2384 30.9694C29.3519 31.0839 29.4869 31.1747 29.6357 31.2367C29.7845 31.2987 29.9441 31.3306 30.1053 31.3306C30.2665 31.3306 30.4261 31.2987 30.5749 31.2367C30.7237 31.1747 30.8587 31.0839 30.9722 30.9694C31.0867 30.8559 31.1775 30.7209 31.2395 30.5721C31.3015 30.4233 31.3334 30.2637 31.3334 30.1025C31.3334 29.9413 31.3015 29.7817 31.2395 29.6329C31.1775 29.4841 31.0867 29.349 30.9722 29.2355L25.7217 23.9973Z"
      fill="#CC4A4A"
    />
  </SvgIcon>
);

export default ErrorIcon;
