import defaultConstants from '../../config/config';
import production from '../../config/config.prod';
import development from '../../config/config.dev';
import qa from '../../config/config.test';
import prodTest from '../../config/config.prodtest';
import poc1 from '../../config/config.poc1';
import poc2 from '../../config/config.poc2';

const today = new Date().toLocaleDateString();
const LS_KEY_PREFIX = 'appliedarc';
const PROD_SITES = ['requests.appliedmedical.com', 'projects.appliedmedical.com'];
const { hostname } = window.location;
export const TODAY_LS_KEY = `${LS_KEY_PREFIX}-constants-${COMMITID}-${today}`;
const DEV_SITES = [
  'appliedarcqa2.z22.web.core.windows.net',
  'appliedarcqa5.z22.web.core.windows.net',
  'appliedarcqa6.z22.web.core.windows.net',
  'appliedarcqa7.z22.web.core.windows.net',
  'appliedarcqa8.z22.web.core.windows.net',
  'appliedarcqa9.z22.web.core.windows.net',
  'appliedarcqa10.z22.web.core.windows.net',

  'appliedarc2qa.z1.web.core.windows.net',
  'appliedarc5qa.z1.web.core.windows.net',
  'appliedarc6qa.z1.web.core.windows.net',
  'appliedarc7qa.z1.web.core.windows.net',
  'appliedarc8qa.z1.web.core.windows.net',
  'appliedarc9qa.z1.web.core.windows.net',
  'appliedarc10qa.z1.web.core.windows.net'
];
const QA_SITES = [
  'appliedarcqa1.z22.web.core.windows.net',
  'appliedarc1qa.z1.web.core.windows.net'
];
const DEV_URLS = [
  'appliedarcqa2.z22.web.core.windows.net',
  'appliedarc2qa.z1.web.core.windows.net'
];
const PLAYGROUND_SITES = ['appliedarcprodtest.z22.web.core.windows.net'];
const POC1_SITES = [
  'appliedarcqa3.z22.web.core.windows.net',
  'appliedarc3qa.z1.web.core.windows.net'
];
const POC2_SITES = [
  'appliedarcqa4.z22.web.core.windows.net',
  'appliedarc4qa.z1.web.core.windows.net'
];

export const HOSTNAME_WHITE_LIST = ['localhost', ...DEV_SITES, ...QA_SITES];

export const isProdEnv = PROD_SITES.includes(hostname);
export const isDevEnv = DEV_SITES.includes(hostname) || hostname === 'localhost';
export const isDevSite = DEV_URLS.includes(hostname);
export const isStgEnv = QA_SITES.includes(hostname);
export const isProdTestEnv = PLAYGROUND_SITES.includes(hostname);
export const isPoc1ENV = POC1_SITES.includes(hostname);
export const isPoc2ENV = POC2_SITES.includes(hostname);

// ****** HELPERS ****** //

export const serveConstants = () => {
  // Prevent function from continuing and serve production endpoints on production
  const savedConstants = JSON.parse(localStorage.getItem(TODAY_LS_KEY));

  // Cleanup
  Object.keys(localStorage).forEach(key => {
    if (key !== TODAY_LS_KEY && key.includes(LS_KEY_PREFIX)) localStorage.removeItem(key);
  });

  // On Production
  if (isProdEnv) return production;

  // Has Local Storage
  if (savedConstants) return savedConstants;

  // On Prod Test / Playground
  if (isProdTestEnv) {
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(prodTest));
    return prodTest;
  }

  // On QA

  if (isStgEnv) {
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(qa));
    return qa;
  }

  // On localhost/Dev

  if (isDevEnv) {
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(development));
    return development;
  }

  // On POC 1 (ARC TEAM 1)
  if (isPoc1ENV) {
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(poc1));
    return poc1;
  }

  // On POC 2 (ARC TEAM 2)
  if (isPoc2ENV) {
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(poc2));
    return poc2;
  }

  localStorage.setItem(TODAY_LS_KEY, JSON.stringify(defaultConstants));
  return defaultConstants;
};

export const getEnv = api => {
  return serveConstants()[api];
};

export const updateConstantEnv = (target: string, env: string, setEndpoints: any) => {
  const savedConstants = JSON.parse(localStorage.getItem(TODAY_LS_KEY));
  const endpoints = require(`../../config/config.${env}`);
  savedConstants[target] = endpoints[target];
  setEndpoints(savedConstants);
};

export const updateConstantVal = (target: string, value: string, setEndpoints: any) => {
  const savedConstants = JSON.parse(localStorage.getItem(TODAY_LS_KEY));
  savedConstants[target] = value;
  setEndpoints(savedConstants);
};

export const addCustomValues = (list: object[]) => {
  const savedConstants = JSON.parse(localStorage.getItem(TODAY_LS_KEY));
  const newSet = { ...savedConstants, ...list };

  localStorage.setItem(TODAY_LS_KEY, JSON.stringify(newSet));
};

export const restoreDefault = () => {
  if (isProdEnv) localStorage.setItem(TODAY_LS_KEY, JSON.stringify(production));
  if (isProdTestEnv) localStorage.setItem(TODAY_LS_KEY, JSON.stringify(prodTest));
  if (isStgEnv) localStorage.setItem(TODAY_LS_KEY, JSON.stringify(qa));
  if (isDevEnv) localStorage.setItem(TODAY_LS_KEY, JSON.stringify(development));
  if (isPoc1ENV) localStorage.setItem(TODAY_LS_KEY, JSON.stringify(poc1));
  if (isPoc2ENV) localStorage.setItem(TODAY_LS_KEY, JSON.stringify(poc2));
};

export const changeEnvironments = (env: string, setEndpoints: any) => {
  const retrievedEndpoints = require(`../../config/config.${env}`);
  setEndpoints(retrievedEndpoints);
};
