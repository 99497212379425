import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ResetIconGreen: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: '8px', height: '16px', width: '16px' }}
  >
    <circle cx="8" cy="8" r="7.5" fill="#EBFBF2" stroke="#007983" />
    <g clip-path="url(#clip0_23417_197617)">
      <path
        d="M7.99966 4.00024C7.28553 4.00274 6.58495 4.19533 5.96989 4.55822C5.35483 4.9211 4.84748 5.4412 4.49996 6.06507V4.80018C4.49996 4.73388 4.47362 4.67029 4.42674 4.62341C4.37986 4.57653 4.31628 4.5502 4.24998 4.5502C4.18368 4.5502 4.1201 4.57653 4.07322 4.62341C4.02634 4.67029 4 4.73388 4 4.80018V6.99999H6.19981C6.26611 6.99999 6.3297 6.97365 6.37658 6.92677C6.42346 6.87989 6.44979 6.81631 6.44979 6.75001C6.44979 6.68371 6.42346 6.62013 6.37658 6.57325C6.3297 6.52637 6.26611 6.50003 6.19981 6.50003H4.83743C5.15271 5.83464 5.66983 5.28563 6.31519 4.93115C6.96055 4.57666 7.70128 4.43476 8.43196 4.52562C9.16265 4.61649 9.84606 4.9355 10.3849 5.43726C10.9238 5.93902 11.2907 6.59795 11.4334 7.3203C11.5761 8.04265 11.4874 8.79161 11.1798 9.46059C10.8722 10.1296 10.3614 10.6845 9.72017 11.0464C9.07893 11.4083 8.33988 11.5587 7.6082 11.4763C6.87652 11.3938 6.18949 11.0827 5.64486 10.5872C5.62057 10.565 5.59215 10.5479 5.56123 10.5367C5.5303 10.5255 5.49748 10.5205 5.46464 10.5221C5.43179 10.5236 5.39956 10.5315 5.36979 10.5455C5.34003 10.5595 5.3133 10.5791 5.29114 10.6034C5.26898 10.6277 5.25183 10.6561 5.24065 10.6871C5.22947 10.718 5.2245 10.7508 5.22601 10.7837C5.22752 10.8165 5.23548 10.8487 5.24944 10.8785C5.26341 10.9083 5.2831 10.935 5.30739 10.9572C5.78789 11.3947 6.36777 11.7086 6.99685 11.8716C7.62593 12.0347 8.28527 12.042 8.91781 11.8929C9.55035 11.7438 10.137 11.4429 10.6271 11.0161C11.1172 10.5893 11.4959 10.0495 11.7304 9.44342C11.965 8.83735 12.0483 8.18326 11.9732 7.53774C11.8981 6.89223 11.6669 6.27473 11.2995 5.73869C10.932 5.20264 10.4396 4.7642 9.8646 4.46129C9.28964 4.15838 8.64953 4.00013 7.99966 4.00024Z"
        fill="#007983"
        stroke="#007983"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_23417_197617">
        <rect width="10" height="10" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ResetIconGreen;
