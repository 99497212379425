import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

interface TTheme {
  palette?: any;
  typography?: any;
  props?: any;
  overrides?: any;
}

const config: TTheme = {
  typography: {
    h1: {
      fontSize: 36,
      lineHeight: 1.222,
      color: 'rgba(55, 61, 67, 100)'
    },
    h2: {
      fontSize: 26,
      lineHeight: 34,
      color: 'rgba(55, 61, 67, 100)'
    },
    h3: {
      fontSize: 22,
      lineHeight: 1.364,
      color: 'rgba(55, 61, 67, 100)'
    },
    h4: {
      fontSize: 20,
      lineHeight: 1.4,
      color: 'rgba(55, 61, 67, 100)'
    },
    h5: {
      fontSize: 18,
      lineHeight: 1.444,
      color: 'rgba(55, 61, 67, 100)'
    },
    h6: {
      fontSize: 15,
      lineHeight: 1.533,
      color: 'rgba(55, 61, 67, 100)'
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.571
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.5
    },
    fontFamily: 'Open Sans',
    button: {
      fontWeight: 600,
      fontSize: 14
    }
  },
  palette: {
    primary: {
      main: '#007983'
    },
    success: {
      main: '#37D881',
      10: '#EBFBF2'
    },
    error: {
      main: '#CC4A4A',
      70: '#FF8D8D'
    },
    teal: {
      primary: '#007983',
      shade: '#004B50',
      digital70: '#4CB5BD',
      digital10: '#E5F4F6',
      digital5: '#F2FAFA'
    },
    grey: {
      dark60: '#373D43',
      primaryDark: '#6C757D',
      medium70: '#727678',
      disabledBtnText: '#A4A4A4',
      primary: '#BFC3C5',
      primaryDark20: '#DEE0E2',
      disabledButton: '#F2F2F2',
      primaryLight10: '#FAFBFB'
    },
    link: {
      bright: '#008BC7',
      dark: '#004D6F'
    },
    toolbar: {
      main: '#001E20'
    }
  },
  props: {
    MuiButton: {
      variant: 'outlined'
    },
    MuiButtonBase: {
      disableRipple: true
    },
    MuiTypography: {
      display: 'block'
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        boxShadow: 'none',
        '&:hover:not($disabled):not($focused):not($error)': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #007983'
          }
        },
        '&$focused $notchedOutline': {
          borderColor: '#007983',
          borderWidth: 1
        }
      }
    },
    MuiTypography: {
      h1: {
        fontSize: '36px !important',
        lineHeight: '44px !important',
        color: 'rgba(55, 61, 67, 100)'
      },
      h2: {
        fontSize: '26px !important',
        lineHeight: '34px !important',
        color: 'rgba(55, 61, 67, 100)'
      },
      h3: {
        fontSize: '22px !important',
        lineHeight: '30px !important',
        color: 'rgba(55, 61, 67, 100)'
      },
      h4: {
        fontSize: '20px !important',
        lineHeight: '28px !important',
        color: 'rgba(55, 61, 67, 100)'
      },
      h5: {
        fontSize: '18px !important',
        lineHeight: '26px !important',
        color: 'rgba(55, 61, 67, 100)'
      },
      h6: {
        fontSize: '15px !important',
        lineHeight: '23px !important',
        color: 'rgba(55, 61, 67, 100)'
      },
      body1: {
        fontSize: '14px !important',
        lineHeight: '22px !important'
      },
      caption: {
        fontSize: '12px !important',
        lineHeight: '18px !important'
      },
      fontFamily: 'Open Sans',
      button: {
        fontWeight: 600,
        fontSize: 14
      }
    }
  }
};

export const theme = responsiveFontSizes(createMuiTheme(config));
