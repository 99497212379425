import { get, post, put, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { AddProgramNotesPayload, ProgramNote } from './project-notes.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/note-management`;

export const getNotesByRequestId = async ({ params, requestId }: TAPIArgs = {}, pageNumber = 1) => {
  const response = await get({
    url: `${BASE_URL}/requests/${requestId}/notes`,
    params: { ...params, pageNumber }
  });

  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata)
  };
};

export const getNoteByNoteId = async ({ requestId, noteId }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/requests/${requestId}/notes/${noteId}` })).data;
};

export const addNote = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/notes`, data })).data;
};

export const updateNote = async ({ requestId, noteId, data }: any) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/notes/${noteId}`, data })).data;
};

export const deleteNote = async ({ requestId, noteId, data }: any) => {
  return (await del({ url: `${BASE_URL}/requests/${requestId}/notes/${noteId}`, data })).data;
};

export const getNoteEditRights = async ({ requestId, noteId }: any) => {
  return (await get({ url: `${BASE_URL}/requests/${requestId}/notes/${noteId}/note-edit-rights` }))
    .data;
};

export const getProgramNotes = async (
  { programId, params }: { programId: number; params: any },
  pageNumber = 1
): Promise<{ items: ProgramNote[]; paginationMetadata: any }> => {
  const response = await get({
    url: `${BASE_URL}/programs/${programId}/notes`,
    params: { ...params, pageNumber }
  });

  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata)
  };
};

export const addProjectNote = async ({
  programId,
  data
}: {
  programId: number;
  data: AddProgramNotesPayload;
}) => {
  return (await post({ url: `${BASE_URL}/programs/${programId}/notes`, data })).data;
};

export const getProgramNoteById = async ({
  programId,
  programNoteId
}: {
  programId: number;
  programNoteId: number;
}) => {
  return (await get({ url: `${BASE_URL}/programs/${programId}/notes/${programNoteId}` })).data;
};

export const updateProgramNote = async ({
  programId,
  programNoteId,
  data
}: {
  programId: number;
  programNoteId: number;
  data: {
    programId: number;
    programNoteId: number;
    noteBody: string;
    rowVersion: string;
  };
}) => {
  return (await put({ url: `${BASE_URL}/programs/${programId}/notes/${programNoteId}`, data }))
    .data;
};

export const deleteProgramNote = async ({
  programId,
  programNoteId,
  data
}: {
  programId: number;
  programNoteId: number;
  data: {
    programId: number;
    programNoteId: number;
    rowVersion: string;
  };
}) => {
  return await del({ url: `${BASE_URL}/programs/${programId}/notes/${programNoteId}`, data });
};
