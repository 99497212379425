import { get, post, put, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import {
  CreateDraftPayload,
  UpdateDraftPayload,
  CreateDraftResponse,
  GetCurrentUserDrafts
} from './draft.types';
import draft from '.';

const BASE_URL = `${getEnv('IT_BASE_URL')}/draft-management`;

export const getDraft = async (draftId: string): Promise<CreateDraftResponse> => {
  return (
    await get({
      url: `${BASE_URL}/drafts/${draftId}`
    })
  ).data;
};

export const createDraft = async (data: CreateDraftPayload): Promise<CreateDraftResponse> => {
  return (
    await post({
      url: `${BASE_URL}/drafts`,
      data
    })
  ).data;
};

export const updateDraft = async (data: UpdateDraftPayload) => {
  return (
    await put({
      url: `${BASE_URL}/drafts/${data.draftId}`,
      data
    })
  ).data;
};

export const getCurrentUserDrafts = async ({ params }: any, pageNumber = 1) => {
  const response = await get({
    url: `${BASE_URL}/current-user-drafts`,
    params: { ...params, pageNumber }
  });

  return {
    items: response?.data,
    paginationMetadata: JSON.parse(response?.headers?.paginationmetadata)
  };
};

export const getDraftFilterEnums = async () => {
  return (await get({ url: `${BASE_URL}/draft-filter-enums` })).data;
};

export const deleteDraft = async ({
  draftId,
  data
}: {
  draftId: string;
  data: { draftId: string; rowVersion: string };
}) => {
  return (
    await del({
      url: `${BASE_URL}/drafts/${draftId}`,
      data
    })
  ).data;
};
