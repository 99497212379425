import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ExclamationMark: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12.5" cy="12" r="11.5" fill="#EBFBF2" stroke="#007983" />
    <path
      d="M13.5294 16.9709C13.5294 17.5393 13.0683 17.9999 12.4999 17.9999C11.9314 17.9999 11.4707 17.5393 11.4707 16.9709C11.4707 16.4019 11.9314 15.9414 12.4999 15.9414C13.0683 15.9415 13.5294 16.4021 13.5294 16.9709Z"
      fill="#007983"
    />
    <path
      d="M13.9132 7.36867C13.8877 7.07613 13.7751 6.80067 13.6033 6.57467C13.3408 6.22933 12.9397 5.99933 12.4992 6C12.0717 5.99947 11.6816 6.21587 11.4189 6.544C11.2336 6.77547 11.1117 7.06267 11.0851 7.36867C11.078 7.44947 11.0788 7.53707 11.0851 7.6156L11.6705 14.3215C11.6883 14.5239 11.7771 14.7027 11.91 14.8357C12.062 14.9879 12.2716 15.0803 12.4992 15.08C12.704 15.0803 12.8943 15.0053 13.0409 14.8796C13.2 14.7433 13.308 14.5468 13.3277 14.3213L13.9132 7.61547C13.9196 7.53693 13.9203 7.44947 13.9132 7.36867Z"
      fill="#007983"
    />
  </SvgIcon>
);

export default ExclamationMark;
