import React, { useState, useContext, useEffect, createContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IUser, mockUser, setUserCypress, setUserWithAuth0, user } from 'services/auth.service';

export type PermissionsObjType = {
  groups: string[];
  permissions: string[];
  runtimePermissions: string[];
  teamMemberId: number;
};

const defaultPermissionState = {
  groups: [],
  permissions: [],
  runtimePermissions: [],
  teamMemberId: 0
};

export const UserContext = createContext<{ myUser: IUser }>({ myUser: {} });

export const UserProvider = ({ children }) => {
  const { user: auth0User } = useAuth0();
  const [myUser, setMyUser] = useState<IUser>(user);
  const [settingUserData, setSettingUserData] = useState(true);
  const [generalPermissions, setGeneralPermissions] = useState<PermissionsObjType>(
    defaultPermissionState
  );
  const [calculatedPermissions, setCalculatedPermissions] = useState<PermissionsObjType>(
    defaultPermissionState
  );

  //@ts-ignore
  const isTest = window?.Cypress ? true : false;

  useEffect(() => {
    const setUserData = async () => {
      if (isTest) {
        setUserCypress(mockUser);
        return;
      }
      if (auth0User?.['https://auth.appliedmedical.com/identity/userData']) {
        const userData = auth0User['https://auth.appliedmedical.com/identity/userData'];
        if (userData) {
          await setUserWithAuth0(userData);
          setMyUser(user);
          setSettingUserData(false);
        }
      }
    };

    setUserData();
  }, [auth0User]);

  return (
    <UserContext.Provider
      value={{
        myUser,
        generalPermissions,
        setGeneralPermissions,
        calculatedPermissions,
        setCalculatedPermissions,
        settingUserData
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
