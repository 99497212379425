import React from 'react';
import { getEnv } from 'services/apiEnv.service';

export const DATE_FORMAT = 'dd MMM yyy';

export const HELP_CENTER_DOCUMENTS = [
  {
    displayName: 'How to submit a request',
    url: `${getEnv('CONTENT_URL')}/Resources/IT/How%20to%20submit%20a%20request.pdf`,
    'data-cy': 'how-to-submit-a-request-document'
  },
  {
    displayName: 'How to review/filter requests',
    url: `${getEnv(
      'CONTENT_URL'
    )}/Resources/IT/how%20to%20use%20filters%20on%20request%20views.pdf`,
    'data-cy': 'how-to-review-requests-document'
  },
  {
    displayName: 'How to edit requests',
    url: `${getEnv('CONTENT_URL')}/Resources/IT/how%20to%20edit%20requests.pdf`,
    'data-cy': 'how-to-edit-requests-document'
  },
  {
    displayName: 'Power BI Report',
    url:
      'https://app.powerbi.com/links/wOXF4BDkds?ctid=6517ef53-434f-418d-a53c-e6c0fb9c3c8e&pbi_source=linkShare',
    'data-cy': 'power-bi-report'
  },
  {
    displayName: 'Applied Request Center Power BI Instructions',
    url: `${getEnv(
      'CONTENT_URL'
    )}/Resources/IT/applied%20request%20center%20powerbi%20instructions.pdf`,
    'data-cy': 'applied-request-center-power-bi-instructions'
  },
  {
    displayName: 'How to use Tasks Tab',
    url: `${getEnv('CONTENT_URL')}/Resources/IT/How-To_Use Task Tab in ARC.pdf`,
    'data-cy': 'how-to-use-tasks-tab'
  },
  {
    displayName: 'How to - Corporate Applications Approval Workflows',
    url: `${getEnv(
      'CONTENT_URL'
    )}/Resources/IT/How%20To_ARC%20Approval%20Workflows_Help%20Guide%20version%202.pdf`,
    'data-cy': 'how-to-coporate-applications-approval-workflows'
  },
  {
    displayName: 'ARC Status and Sub status - Corporate Applications',
    url: `${getEnv(
      'CONTENT_URL'
    )}/Resources/IT/ARC%20Status%20and%20Sub-status_Corporate%20Applications_%202024GAW082152-2_FinalDocument.pdf`,
    'data-cy': 'arc-status-and-sub-status'
  },
  {
    displayName: 'How to use Files Tab',
    url: `${getEnv('CONTENT_URL')}/Resources/IT/ARC Files Tab User Guide Version 2.pdf`,
    'data-cy': 'how-to-use-files-tab'
  },
  {
    displayName: 'How to - Mark Team Assignment Completions',
    url: `${getEnv('CONTENT_URL')}/Resources/IT/ARC Mark Team Assignment Completions.pdf`,
    'data-cy': 'how-to-mark-team-assignment-completions'
  },
  {
    displayName: 'ARC How to use Programs',
    url: `${getEnv('CONTENT_URL')}/Resources/IT/ARC How to use Programs.pdf`,
    'data-cy': 'how-to-use-programs'
  },
  {
    displayName: 'How to Subscribe to an ARC Request',
    url: `${getEnv('CONTENT_URL')}/Resources/IT/How to Subscribe to an ARC Request.pdf`,
    'data-cy': 'arc-subscribe-how-to'
  }
];

export const HELP_CENTER_VIDEOS = [
  {
    displayName: 'Request Tab',
    url: `${getEnv('CONTENT_URL')}/Videos/ARC%20-%20Request%20Tab.mp4`,
    'data-cy': 'request-tab-video'
  },
  {
    displayName: 'Scope Tab',
    url: `${getEnv('CONTENT_URL')}/Videos/ARC%20-%20Scope%20Tab.mp4`,
    'data-cy': 'scope-tab-video'
  },
  {
    displayName: 'Status Tab',
    url: `${getEnv('CONTENT_URL')}/Videos/ARC%20-%20Status%20Tab.mp4`,
    'data-cy': 'status-tab-video'
  },
  {
    displayName: 'Team Tab',
    url: `${getEnv('CONTENT_URL')}/Videos/ARC%20-%20Team%20Tab.mp4`,
    'data-cy': 'team-tab-video'
  },
  {
    displayName: 'Tasks Tab',
    url: `${getEnv('CONTENT_URL')}/Videos/ARC%20-%20Task%20Tab.mp4`,
    'data-cy': 'task-tab-video'
  },
  {
    displayName: 'Notes Tab',
    url: `${getEnv('CONTENT_URL')}/Videos/ARC%20-%20Notes%20Tab.mp4`,
    'data-cy': 'notes-tab-video'
  },
  {
    displayName: 'Log Tab',
    url: `${getEnv('CONTENT_URL')}/Videos/ARC%20-%20Log%20Tab.mp4`,
    'data-cy': 'log-tab-video'
  }
];

export const HELP_CENTER_FAQ = [
  {
    question: "Why can't I edit my requests?",
    answer:
      'By default, all users at Applied across different departments have access to VIEW any requests that are submitted and can add notes to a request. Users will not be able to edit the request fields unless they are assigned as the project manager or a project lead or have special assigned permissions. Team coordinators will have access to edit the team tab when they are assigned as a team coordinator for a request.',
    'data-cy': 'faq-why-cant-i-edit-my-requests'
  },
  {
    question: 'How can I rearrange columns on my dashboard?',
    answer:
      'Go to the filters icon on the far right hand side of the screen and click on "Columns." You will be able to drop and drop data columns to an active view and re-order them however you like.',
    'data-cy': 'faq-how-can-i-rearrange-columns-on-my-dashboard'
  },
  {
    question: 'Who is a Team Coordinator?',
    answer:
      'A team coordinator on the Applied Request Center is a designated role on the system. The assigned team coordinator is responsible for assigning the correct team members to work on a request.',
    'data-cy': 'faq-who-is-a-team-coordinator'
  },
  {
    question: 'Who can update Scope, Status and Team tabs?',
    answer:
      'The assigned request Project Manager will be able to edit all tabs on a request. The Project Lead will be able to edit Request Details, Scope and Status tabs on a request. Administrative users, and Project Management leads will be able to edit tabs of any request.',
    'data-cy': 'faq-who-can-update'
  },
  {
    question: 'How can I edit my notes?',
    answer:
      'Click on the Notes tab of the request, and click anywhere on the row of your authored note. The side panel will pull out and you will be able to type within the text field, and click on save on the bottom. Your note will then be updated.',
    'data-cy': 'faq-how-can-i-edit-my-notes'
  },
  {
    question: 'Where can I go to submit a request?',
    answer:
      'Select "My Dashboard" or "Requests" options from the left-hand slide-out menu and then click on the "New Request" button to start the submission process. Follow the questions and prompts to complete your request submission.',
    'data-cy': 'faq-where-can-i-go-to-submit-a-request'
  },
  {
    question: 'How do I pull an excel export of all requests in the Applied Request Center?',
    answer: React.createElement(
      'span',
      null,
      'Please visit the ',
      React.createElement(
        'a',
        {
          href:
            'https://app.powerbi.com/links/wOXF4BDkds?ctid=6517ef53-434f-418d-a53c-e6c0fb9c3c8e&pbi_source=linkShare',
          target: '_blank',
          rel: 'noopener'
        },
        'Power BI report'
      ),
      `. You will be able to grab an excel or csv export of the fields by hovering over the main
        table, clicking on the action item indicated by the "..." and select export data.`
    ),
    'data-cy': 'faq-how-do-i-pull-an-excell-export-of-all-requests-in-the-ARC'
  }
];
