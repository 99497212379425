import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CustomFilesUncheckIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
        stroke="#AEB3B7"
      />
    </SvgIcon>
  );
};

export default CustomFilesUncheckIcon;
