export const REQUEST_HEALTH_COLORS = {
  1: { color: '#21824D', label: 'On Track' }, // Green
  2: { color: '#FFB526', label: 'At Risk' }, // Yellow
  3: { color: '#CC4A4A', label: 'Off Track' }, // Red
  4: { color: '#008BC7', label: 'Complete' }, // Blue
  5: { color: '#008BC7', label: 'Completed' } // Blue
} as any;

export const DEFAULT_STATUS_DATA = {
  status: null,
  subStatus: null,
  projectHealth: null,
  percentageComplete: '',
  plannedStartDate: null,
  actualStartDate: null,
  onHoldStartDate: null,
  onHoldEndDate: null,
  reasonForHold: '',
  PIDateFrom: null,
  AIDateFrom: null,
  requestedImplementationDate: null,
  dueDateReason: '',
  plannedClosureDate: null,
  actualClosureDate: null,
  newReasonForHold: '',
  classification: null,
  'Financial Requirement/ Risk': false,
  'Legal Requirement/ Risk': false,
  'Regulatory Requirement/ Risk': false,
  'Escalated Project': false,
  'Security Requirement/ Risk': false,
  'Schedule Risk': false,
  'Senior Staff Priority': false,
  'No PM': false,
  'Requires Validation': false
};
