import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const FilesPencil: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_4527_94024)">
      <path
        d="M13.4873 1.53836L12.4617 0.512695C12.1201 0.170898 11.6723 0 11.2244 0C10.7765 0 10.3286 0.170898 9.98679 0.512422L0.351395 10.1478L0.00412886 13.271C-0.0396211 13.6642 0.270184 14 0.65491 14C0.679246 14 0.703582 13.9986 0.728465 13.9959L3.84948 13.6511L13.4876 4.01297C14.1709 3.32965 14.1709 2.22168 13.4873 1.53836ZM3.4478 12.8155L0.904012 13.0974L1.18729 10.5498L8.40304 3.33402L10.6663 5.59727L3.4478 12.8155ZM12.8688 3.39445L11.2848 4.97848L9.02155 2.71523L10.6056 1.13121C10.7707 0.966055 10.9906 0.875 11.2244 0.875C11.4582 0.875 11.6777 0.966055 11.8432 1.13121L12.8688 2.15687C13.2098 2.49812 13.2098 3.0532 12.8688 3.39445Z"
        fill="#7D858C"
      />
    </g>
    <defs>
      <clipPath id="clip0_4527_94024">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default FilesPencil;
