import React from 'react';
// import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NoDataIcon from 'components/icons/NoData.icon';
// import { Button } from 'components/common';

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%'
  }
}));

interface INoData {
  isFullPageCenter: boolean;
}

const NoDataError: React.FC<INoData> = ({ isFullPageCenter = false }) => {
  //   const history = useHistory();
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{
        height: '100%',
        marginTop: isFullPageCenter ? -64 : 'auto'
      }}
    >
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={8} sm={7} md={7} lg={6}>
            <NoDataIcon classes={{ root: classes.root }} />
          </Grid>

          <Grid item xs={11} sm={9} md={7} lg={8}>
            <Typography paragraph variant="h6" align="center">
              No results to display yet
            </Typography>
            {/* <Typography variant="body2" align="center">
              Please use the "New Request" Button at the top of the page.
            </Typography> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NoDataError;
