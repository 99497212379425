import { get } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';

const BASE_URL = `${getEnv('IT_BASE_URL')}/help-center`;

export const getCurrentProductVersion = async () => {
  return (await get({ url: `${BASE_URL}/current-product-version` })).data;
};

export const getCurrentReleaseNotes = async () => {
  return (await get({ url: `${BASE_URL}/current-release-notes` })).data;
};
