import { get, post, put, patch, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';
import {
  AddChangeOrderPayload,
  AddCostPayload,
  CapitalAsset,
  DeleteCostPayload,
  DeleteCapitalAssetPayload
} from './request-cost.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/cost-management/requests`;

export const getCapitalAsset = async ({ requestId }: TAPIArgs = {}): Promise<CapitalAsset> => {
  return (await get({ url: `${BASE_URL}/${requestId}/capital-asset` })).data;
};

export const addCapitalAsset = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/capital-asset`, data })).data;
};

export const addRequestChangeOrder = async ({
  requestId,
  capitalAssetId,
  data
}: {
  requestId: number;
  capitalAssetId: number;
  data: AddChangeOrderPayload;
}) => {
  return (
    await post({
      url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}/change-orders`,
      data
    })
  ).data;
};

export const addCostRecord = async ({
  requestId,
  capitalAssetId,
  data
}: {
  requestId: number;
  capitalAssetId: number;
  data: AddCostPayload;
}) => {
  return (
    await post({
      url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}/costs`,
      data
    })
  ).data;
};

export const updateCapitalAsset = async ({ requestId, capitalAssetId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}`,
      data
    })
  ).data;
};

export const removeRequestCost = async ({ requestId, capitalAssetId, data }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}`, data }))
    .data;
};

export const updateRequestChangeOrder = async ({
  requestId,
  capitalAssetId,
  changeOrderId,
  data
}: {
  requestId: number;
  capitalAssetId: number;
  changeOrderId: number;
  data: AddChangeOrderPayload;
}) => {
  return (
    await put({
      url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}/change-orders/${changeOrderId}`,
      data
    })
  ).data;
};

export const removeRequestChangeOrder = async ({
  requestId,
  capitalAssetId,
  changeOrderId,
  data
}: {
  requestId: number;
  capitalAssetId: number;
  changeOrderId: number;
  data: AddChangeOrderPayload;
}) => {
  return (
    await del({
      url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}/change-orders/${changeOrderId}`,
      data
    })
  ).data;
};

export const updateRequestCostRecord = async ({
  requestId,
  capitalAssetId,
  costId,
  data
}: {
  requestId: number;
  capitalAssetId: number;
  costId: number;
  data: AddCostPayload;
}) => {
  return (
    await put({
      url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}/costs/${costId}`,
      data
    })
  ).data;
};

export const removeRequestCostRecord = async ({
  requestId,
  capitalAssetId,
  costId,
  data
}: DeleteCostPayload) => {
  return (
    await del({
      url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}/costs/${costId}`,
      data
    })
  ).data;
};

export const getCostEnum = async ({}: TAPIArgs = {}) => {
  return (await get({ url: `${getEnv('IT_BASE_URL')}/cost-management/cost-enums` })).data;
};

export const deleteCapitalAsset = async ({
  requestId,
  capitalAssetId,
  data
}: {
  requestId: number;
  capitalAssetId: number;
  data: DeleteCapitalAssetPayload;
}) => {
  return (
    await del({
      url: `${BASE_URL}/${requestId}/capital-asset/${capitalAssetId}`,
      data
    })
  ).data;
};
