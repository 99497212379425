import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ODTIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117780" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117780)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#6C757D"
    />
    <path
      d="M7.41343 15.2808C7.02802 15.2808 6.72263 15.4252 6.49556 15.7141C6.26848 16.003 6.15539 16.3849 6.15539 16.8607C6.15539 17.3437 6.26848 17.7257 6.49556 18.0067C6.72263 18.2868 7.01844 18.4278 7.38559 18.4278C7.76405 18.4278 8.06508 18.2918 8.28607 18.0179C8.50792 17.7447 8.61927 17.3654 8.61927 16.8818C8.61927 16.3771 8.51139 15.9829 8.2965 15.7018C8.0816 15.4218 7.78667 15.2808 7.41343 15.2808ZM7.36558 19.376C6.66782 19.376 6.09882 19.1479 5.65946 18.6949C5.2201 18.2405 5 17.6482 5 16.9192C5 16.1491 5.22359 15.5251 5.66991 15.0493C6.11536 14.5735 6.70697 14.336 7.44387 14.336C8.13989 14.336 8.70193 14.564 9.13085 15.0191C9.55977 15.4748 9.77466 16.0743 9.77466 16.82C9.77466 17.5863 9.55281 18.2031 9.10736 18.6721C8.66191 19.1411 8.08161 19.376 7.36558 19.376Z"
      fill="white"
    />
    <path
      d="M11.7734 15.3127V18.4019H12.3171C12.793 18.4019 13.1671 18.2592 13.4368 17.9741C13.7074 17.6886 13.8431 17.2988 13.8431 16.808C13.8431 16.3445 13.7092 15.9791 13.4403 15.712C13.1724 15.4449 12.7948 15.3127 12.3102 15.3127H11.7734ZM10.6763 19.292V14.4192H12.4024C14.1337 14.4192 14.9985 15.2107 14.9985 16.7941C14.9985 17.5536 14.7628 18.1593 14.2903 18.6126C13.8179 19.0667 13.1889 19.292 12.4024 19.292H10.6763Z"
      fill="white"
    />
    <path
      d="M19.257 15.3127H17.8667V19.292H16.7662V15.3127H15.3828V14.4192H19.257V15.3127Z"
      fill="white"
    />
  </SvgIcon>
);

export default ODTIcon;
