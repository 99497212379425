import React, { useState } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';

import { useInterval } from 'hooks';
import DelayedRender from '../DelayedRender/DelayedRender';
import Button from '../Buttons/Button';

type AuthLoadingProps = {
  auth0Logout: any;
  message?: string;
  delay?: number;
  containerProps?: any;
};

const AuthLoading: React.FC<AuthLoadingProps> = ({
  auth0Logout,
  message = '',
  delay = 200,
  containerProps = {},
  ...restProps
}) => {
  const [timer, setTimer] = useState(0);
  const [displayMessage, setDisplayMessage] = useState(message);
  const [showLogout, setShowLogout] = useState(false);

  useInterval(
    () => {
      setTimer(prevState => prevState + 1);
      if (timer === 10) {
        setDisplayMessage("Hmmm, something isn't right...");
      } else if (timer === 15) {
        setDisplayMessage("Sorry, but we're having trouble signing you in. Try logging out.");
        setShowLogout(true);
      }
    },
    timer < 61 ? 1000 : null
  );

  return (
    <DelayedRender delay={delay}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          height: '100%',
          textAlign: 'center',
          //   backgroundColor:'red',
          padding: '36px',
          ...containerProps
        }}
      >
        <Grid item>
          <CircularProgress />
          <Typography style={{ marginTop: '24px' }}>{displayMessage}</Typography>
          {showLogout && (
            <Button
              onClick={() => {
                auth0Logout({
                  logoutParams: { returnTo: window.location.origin, federated: true }
                });
              }}
              style={{
                textDecoration: 'none',
                backgroundColor: '#007983',
                borderRadius: '4px',
                padding: '4px 12px',
                marginTop: '12px'
              }}
            >
              <Typography
                style={{
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '22px'
                }}
              >
                LOGOUT
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </DelayedRender>
  );
};

export default AuthLoading;
