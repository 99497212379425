import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const EMLIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_13639_153776" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13639_153776)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#3170C0"
    />
    <path
      d="M7.99031 19.4331H5.01953V14.4799H7.87631V15.3881H6.13531V16.49H7.75542V17.3956H6.13531V18.5284H7.99031V19.4331Z"
      fill="white"
    />
    <path
      d="M14.8342 19.52H13.7323V16.5557C13.7323 16.2365 13.7465 15.8827 13.7739 15.4954H13.7465C13.689 15.7996 13.6377 16.0189 13.5908 16.1516L12.4306 19.52H11.5179L10.3364 16.1861C10.3046 16.0967 10.2533 15.8668 10.1817 15.4954H10.1498C10.1808 15.9844 10.195 16.4124 10.195 16.7803V19.52H9.18945V14.5659H10.8237L11.8363 17.5028C11.9168 17.7372 11.976 17.9742 12.0123 18.2103H12.0326C12.0954 17.9362 12.1608 17.6983 12.2298 17.4958L13.2424 14.5659H14.8342V19.52Z"
      fill="white"
    />
    <path d="M18.98 19.52H16.0298V14.5659H17.1458V18.6153H18.98V19.52Z" fill="white" />
  </SvgIcon>
);

export default EMLIcon;
