import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const JPGIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117594" maskUnits="userSpaceOnUse" x="1" y="0" width="20" height="24">
      <path
        d="M1.95409 23.6221C1.74726 23.6221 1.5791 23.4541 1.5791 23.2471V0.747768C1.5791 0.540775 1.74726 0.37278 1.95409 0.37278H14.5485L20.3285 6.15286V23.2471C20.3285 23.4541 20.1604 23.6221 19.9535 23.6221H1.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117594)">
      <path
        d="M1.95409 23.6221C1.74726 23.6221 1.5791 23.4541 1.5791 23.2471V0.747768C1.5791 0.540775 1.74726 0.37278 1.95409 0.37278H14.5485L20.3285 6.15286V23.2471C20.3285 23.4541 20.1604 23.6221 19.9535 23.6221H1.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M14.7037 0.000736237H1.95408C1.53984 0.000736237 1.2041 0.336725 1.2041 0.750713V23.25C1.2041 23.664 1.53984 24 1.95408 24H19.9535C20.3677 24 20.7035 23.664 20.7035 23.25V6.00055L14.7037 0.000736237ZM14.3931 0.750713L19.9535 6.31104V23.25H1.95408V0.750713H14.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M20.7039 6.75H14.7041C14.2899 6.75 13.9541 6.41401 13.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M21.2143 21H0.785714C0.35173 21 0 20.552 0 20V14C0 13.448 0.35173 13 0.785714 13H21.2143C21.6482 13 22 13.448 22 14V20C22 20.552 21.6482 21 21.2143 21Z"
      fill="#6C757D"
    />
    <path
      d="M7.47783 17.3575C7.47783 18.0101 7.32643 18.5094 7.02453 18.8557C6.72176 19.2028 6.28416 19.376 5.71082 19.376C5.45503 19.376 5.2175 19.3316 5 19.2437V18.2136C5.18879 18.3563 5.39934 18.4277 5.6325 18.4277C6.13363 18.4277 6.38334 18.0562 6.38334 17.3132V14.4178H7.47783V17.3575Z"
      fill="white"
    />
    <path
      d="M9.721 15.2612V16.7811H10.1525C10.7372 16.7811 11.0295 16.5245 11.0295 16.0129C11.0295 15.5118 10.7372 15.2612 10.1525 15.2612H9.721ZM9.721 17.6137V19.292H8.62305V14.419H10.3431C11.5707 14.419 12.1849 14.9367 12.1849 15.972C12.1849 16.461 12.0083 16.8568 11.6568 17.1596C11.3036 17.4623 10.8329 17.6137 10.2447 17.6137H9.721Z"
      fill="white"
    />
    <path
      d="M17.0273 18.9645C16.5514 19.2385 15.9598 19.376 15.2533 19.376C14.4686 19.376 13.8508 19.1585 13.3993 18.7252C12.9469 18.2911 12.7207 17.6934 12.7207 16.9321C12.7207 16.1665 12.9686 15.5427 13.4619 15.0598C13.9552 14.577 14.613 14.336 15.4334 14.336C15.9493 14.336 16.4052 14.4073 16.7993 14.55V15.5801C16.4226 15.3626 15.9632 15.2538 15.4195 15.2538C14.9645 15.2538 14.593 15.4017 14.3067 15.6967C14.0205 15.9925 13.8769 16.3875 13.8769 16.8816C13.8769 17.3828 14.0048 17.7699 14.2624 18.044C14.5199 18.3181 14.8662 18.4546 15.3038 18.4546C15.5665 18.4546 15.7753 18.4172 15.9293 18.3424V17.3915H14.954V16.5145H17.0273V18.9645Z"
      fill="white"
    />
  </SvgIcon>
);

export default JPGIcon;
