import 'react';
import { getEnv } from 'services/apiEnv.service';
import { serveEnvCode } from 'services/auth.service';

const envCodes = serveEnvCode();
export const appCode = 'ARC';
export const envCode = envCodes.currentEnv ?? 'DEV';
export const auth0ApiAudience = 'https://api.appliedmedical.com/ARC';
export const opts = {
  audience: auth0ApiAudience,
  appCode: appCode,
  envCode: envCode
};
export const AppCenterOpt = {
  audience: 'https://api.appliedmedical.com/application-center',
  appCode: 'APPCNTR',
  envCode: 'PROD'
};
