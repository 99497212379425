import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const XportIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.92929 3.37232L14.9293 1.65803C15.2305 1.615 15.5 1.84873 15.5 2.15301V21.847C15.5 22.1513 15.2305 22.385 14.9293 22.342L2.92929 20.6277C2.68296 20.5925 2.5 20.3815 2.5 20.1327V3.8673C2.5 3.61847 2.68297 3.40751 2.92929 3.37232Z"
      fill={props?.fill ?? '#7D858C'}
      stroke={props?.fill ?? '#7D858C'}
    />
    <path
      d="M12.2233 8.41172L9.85434 11.7776L9.69235 12.0077L9.85428 12.2379L12.2274 15.6117H10.8218L9.33053 13.5075L9.00452 13.0474L8.67806 13.5071L7.18516 15.6092L5.79021 15.5926L8.14486 12.2376L8.30582 12.0083L8.14528 11.7786L5.78332 8.4H7.20011L8.66104 10.5127L8.98659 10.9835L9.31678 10.5159L10.8027 8.41172H12.2233Z"
      fill="white"
      stroke="white"
      stroke-width="0.8"
    />
    <path
      d="M17 3.00391H21C21.5523 3.00391 22 3.45162 22 4.00391V20C22 20.5523 21.5523 21 21 21H17V3.00391Z"
      fill={props?.fill ?? '#7D858C'}
    />
  </SvgIcon>
);

export default XportIcon;
