import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IndentIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
    <rect y="0.666504" width="16" height="1.33333" rx="0.666667" fill="#7D858C" />
    <rect x="8" y="14.6665" width="8" height="1.33333" rx="0.666667" fill="#7D858C" />
    <rect y="5.3335" width="16" height="1.33333" rx="0.666667" fill="#7D858C" />
    <rect x="8" y="10" width="8" height="1.33333" rx="0.666667" fill="#7D858C" />
    <path
      d="M6.33724 13.0015L4.00391 15.3348"
      stroke="#008BC7"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M6.33724 13.0015L4.00391 10.6681"
      stroke="#008BC7"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M0.669434 13.0015H6.00277"
      stroke="#008BC7"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default IndentIcon;

// <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <g clip-path="url(#clip0_20971_179349)">
//     <rect y="0.666504" width="16" height="1.33333" rx="0.666667" fill="#7D858C" />
//     <rect x="8" y="14.6665" width="8" height="1.33333" rx="0.666667" fill="#7D858C" />
//     <rect y="5.3335" width="16" height="1.33333" rx="0.666667" fill="#7D858C" />
//     <rect x="8" y="10" width="8" height="1.33333" rx="0.666667" fill="#7D858C" />
//     <path
//       d="M6.33724 13.0015L4.00391 15.3348"
//       stroke="#008BC7"
//       strokeWidth="1.33333"
//       strokeLinecap="round"
//     />
//     <path
//       d="M6.33724 13.0015L4.00391 10.6681"
//       stroke="#008BC7"
//       strokeWidth="1.33333"
//       strokeLinecap="round"
//     />
//     <path
//       d="M0.669434 13.0015H6.00277"
//       stroke="#008BC7"
//       strokeWidth="1.33333"
//       strokeLinecap="round"
//     />
//   </g>
//   <defs>
//     <clipPath id="clip0_20971_179349">
//       <rect width="16" height="16" fill="white" />
//     </clipPath>
//   </defs>
// </svg>;
