import { useQuery } from 'react-query';
import { TQuery } from 'api/api.types';
import { RequestOverviewAPI } from 'api';

export const useRequestOverview = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['request-overview', args], () => RequestOverviewAPI.getRequestOverview(args), {
    staleTime: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true
  });
};

export const useRequestOverviewEnums = ({ ...config }: TQuery = {}) => {
  return useQuery(['request-overview-enums'], () => RequestOverviewAPI.getRequestOverviewEnums(), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};
