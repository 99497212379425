import { RequestFilesAPI } from 'api';
import { TQuery } from 'api/api.types';
import { useQuery } from 'react-query';
import { IPath, IFilesData } from 'components/FileTypes/Files.types';

export const useGetFiles = ({ path, ...config }: IPath) => {
  return useQuery(['get-files'], () => RequestFilesAPI.getFiles({ path }), {
    staleTime: 1000 * 60,
    ...config,
    refetchOnWindowFocus: true,
    enabled: path !== null && path !== 'Requests/undefined' && path !== 'Programs/undefined',
    cacheTime: 0
  });
};

export const useGetParentFolder = ({ path, ...config }: IPath) => {
  return useQuery(['get-parent-folder'], () => RequestFilesAPI.getItem({ path }), {
    staleTime: 1000 * 60,
    ...config,
    refetchOnWindowFocus: true,
    enabled: path !== null,
    cacheTime: 0
  });
};

export const useGetFilesModal = ({ path, ...config }: IPath) => {
  return useQuery(['get-files-modal'], () => RequestFilesAPI.getFilesModal({ path }), {
    staleTime: 1000 * 60,
    ...config,
    refetchOnWindowFocus: true,
    enabled: path !== null
  });
};

export const useGetAllFiles = ({ path, ...config }: IPath) => {
  return useQuery(['get-all-files'], () => RequestFilesAPI.getFiles({ path }), {
    staleTime: 1000 * 60,
    ...config,
    refetchOnWindowFocus: true,
    enabled: path !== null,
    cacheTime: 0
  });
};

export const useGetFilesRequestLevel = ({ path, ...config }: IPath) => {
  return useQuery(['get-files-request-level'], () => RequestFilesAPI.getFiles({ path }), {
    staleTime: 1000 * 60,
    ...config,
    refetchOnWindowFocus: true,
    enabled: path !== null
  });
};

export const useDownloadFile = (args: string[], config: TQuery) => {
  const queryFn = async () => {
    return args.map((file: IFilesData) => {
      return RequestFilesAPI.downloadFile({ link: file.original.downloadUrl });
    });
  };
  const data = useQuery(['get-link'], queryFn, {
    ...config,
    staleTime: 1000 * 60,
    enabled: false
  });
  return data;
};

export const useGetPermissions = ({ fileId, ...config }: { fileId: string }) => {
  return useQuery(['get-permissions'], () => RequestFilesAPI.getPermissions({ fileId }), {
    ...config,
    staleTime: 1000 * 60,
    enabled: !!fileId
  });
};
