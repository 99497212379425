import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChildIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M11.2277 11.8433C10.5308 10.8353 9.5612 10.0608 8.43965 9.61614C9.53305 8.86165 10.2063 7.58395 10.2063 6.24485C10.2063 3.99527 8.39576 2.16541 6.16995 2.16541C3.94413 2.16541 2.13392 3.99566 2.13392 6.24524C2.13392 7.58395 2.80909 8.86165 3.90062 9.61614C2.77923 10.0607 1.80972 10.8351 1.11291 11.8429C0.38858 12.8844 -0.000613048 14.1334 7.24841e-07 15.4142C7.2709e-07 15.5696 0.0599509 15.7186 0.166663 15.8284C0.273375 15.9383 0.418107 16 0.569021 16C0.719934 16 0.864666 15.9383 0.971378 15.8284C1.07809 15.7186 1.13804 15.5696 1.13804 15.4142C1.14329 12.6191 3.40062 10.3405 6.17032 10.3351C8.94003 10.3405 11.1966 12.6191 11.2022 15.4142C11.206 15.567 11.2677 15.7123 11.374 15.819C11.4803 15.9256 11.623 15.9853 11.7714 15.9853C11.9199 15.9853 12.0625 15.9256 12.1689 15.819C12.2752 15.7123 12.3368 15.567 12.3406 15.4142C12.3411 14.1335 11.9519 12.8848 11.2277 11.8433ZM6.17032 9.169C4.57467 9.16592 3.27458 7.85424 3.27196 6.24524C3.26933 4.63624 4.57617 3.32418 6.17032 3.31916C7.7656 3.32263 9.06606 4.6347 9.06906 6.2437C9.06568 7.85424 7.76448 9.16592 6.17032 9.169ZM11.344 5.17953C11.344 5.03616 11.3993 4.89866 11.4978 4.79728C11.5963 4.6959 11.7299 4.63895 11.8691 4.63895C12.0082 4.63883 12.1416 4.69549 12.2401 4.79649L13.1471 5.73169V0.548302C13.1471 0.270676 13.3703 0.0193064 13.6347 0C13.6475 0 13.6599 0 13.6722 0C13.8115 0 13.9451 0.0569538 14.0436 0.158332C14.142 0.25971 14.1974 0.397209 14.1974 0.54058V5.7313L15.0976 4.80459C15.2885 4.6069 15.6182 4.59107 15.8178 4.77023C15.8725 4.81877 15.9169 4.87841 15.9481 4.94541C15.9794 5.0124 15.997 5.08533 15.9996 5.15961C16.0023 5.2339 15.9901 5.30796 15.9637 5.37716C15.9373 5.44636 15.8973 5.50921 15.8463 5.56179L14.0425 7.4183C13.9942 7.468 13.9368 7.50737 13.8737 7.53414C13.785 7.57158 13.6883 7.58384 13.5935 7.56966C13.4799 7.55177 13.3751 7.49602 13.2953 7.41096L11.4978 5.56257C11.449 5.51227 11.4102 5.45255 11.3838 5.38682C11.3574 5.32109 11.3439 5.25065 11.344 5.17953Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default ChildIcon;
