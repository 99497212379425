import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WaitingOnIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8" cy="8" r="7.5" fill="#FFB526" stroke="#FFB526" />
    <path d="M8 8.75L8 4.75" stroke="white" stroke-width="1.5" stroke-linecap="round" />
    <path d="M8 8.75L11.156 11.2075" stroke="white" stroke-width="1.5" stroke-linecap="round" />
  </SvgIcon>
);

export default WaitingOnIcon;
