import React from 'react';
import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { AsyncProvider } from 'react-query-async/dist';
import CssBaseline from '@material-ui/core/CssBaseline';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import { ThemeProvider } from 'emotion-theming';
import { lightTheme } from '@applied-med/ui/dist/styles';
import { theme } from './theme';
import { queryClient } from 'utils/react-query.utils';
import App from './App';
import { makeServer } from 'mockServer';
import '@fontsource/open-sans';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import { Font } from '@react-pdf/renderer';
import { robotoRegular, robotoBold, robotoBoldItalic, robotoItalic } from 'fonts';
import { Auth0ProviderWithHistory } from 'components/Auth0/Auth0ProviderWithHistory';
import AuthWrapper from 'components/Auth0/AuthWrapper';
import { UserProvider } from 'context/User.context';
import { BrowserRouter } from 'react-router-dom';
import Loading from 'components/common/Loading/Loading';
import LoadingOverlay from 'components/common/Loading/LoadingOverlay';
import ServerError from 'components/common/ServerError/ServerError';
import NoData from 'components/common/NoData/NoData';
import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import '../src/index.css';

// import setupLocatorUI from "@locator/runtime";

// if (process.env.NODE_ENV === "development") {
//   setupLocatorUI();
// }

Font.register({
  family: 'Roboto',
  fonts: [
    { src: robotoRegular, fontStyle: 'normal' },
    { src: robotoBold, fontWeight: 700 },
    { src: robotoItalic, fontStyle: 'italic' },
    { src: robotoBoldItalic, fontStyle: 'italic', fontWeight: 700 }
  ]
});

const ToastContainer = props => (
  <DefaultToastContainer className="toast-container" style={{ zIndex: 9999 }} {...props} />
);

const asyncConfig = {
  components: {
    Loading,
    Error: ServerError,
    Fetching: LoadingOverlay,
    NoData
  },
  ErrorBoundary,
  errorBoundaryProps: { fallback: ServerError }
};

// MOCK SERVER Useful for when API doesn't exist yet
// if (process.env.NODE_ENV !== 'production') {
//   makeServer();
// }

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <AuthWrapper>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <AsyncProvider config={asyncConfig}>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                  <ToastProvider
                    autoDismiss
                    autoDismissTimeout={10000}
                    placement="bottom-right"
                    components={{ ToastContainer }}
                  >
                    <App />
                  </ToastProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </AsyncProvider>
          </QueryClientProvider>
        </UserProvider>
      </AuthWrapper>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);
// render(
//   <QueryClientProvider client={queryClient}>
//     <AsyncProvider config={asyncConfig}>
//       <MuiThemeProvider theme={theme}>
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           <ReactQueryDevtools initialIsOpen={false} />
//           <ToastProvider
//             autoDismiss
//             autoDismissTimeout={10000}
//             placement="bottom-right"
//             components={{ ToastContainer }}
//           >
//             <App />
//           </ToastProvider>
//         </ThemeProvider>
//       </MuiThemeProvider>
//     </AsyncProvider>
//   </QueryClientProvider>,
//   document.getElementById('root')
// );
