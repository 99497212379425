import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MSWordIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117779" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117779)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#185ABD"
    />
    <path
      d="M6.0977 15.3125V18.402H6.64146C7.11719 18.402 7.49068 18.2593 7.76126 17.9739C8.03175 17.6886 8.16739 17.2997 8.16739 16.8081C8.16739 16.3435 8.03314 15.9781 7.76465 15.7119C7.49617 15.4457 7.11927 15.3125 6.63467 15.3125H6.0977ZM5 19.292V14.419H6.72638C8.45762 14.419 9.32285 15.2107 9.32285 16.7942C9.32285 17.5537 9.08664 18.1592 8.61431 18.6125C8.14189 19.0658 7.51278 19.292 6.72638 19.292H5Z"
      fill="white"
    />
    <path
      d="M12.3544 15.2808C11.969 15.2808 11.6631 15.4252 11.4368 15.7141C11.2104 16.0029 11.097 16.3849 11.097 16.8608C11.097 17.3436 11.2104 17.7256 11.4368 18.0066C11.6631 18.2867 11.9602 18.4277 12.3272 18.4277C12.7058 18.4277 13.0055 18.2911 13.2278 18.0179C13.4501 17.7447 13.5609 17.3662 13.5609 16.8816C13.5609 16.3762 13.4535 15.9829 13.238 15.7019C13.0225 15.4218 12.7282 15.2808 12.3544 15.2808ZM12.3068 19.376C11.6087 19.376 11.0405 19.1489 10.6008 18.6948C10.161 18.2406 9.94141 17.6481 9.94141 16.9191C9.94141 16.1491 10.1644 15.5253 10.611 15.0494C11.0576 14.5735 11.6488 14.336 12.3849 14.336C13.0803 14.336 13.6427 14.5639 14.0723 15.0189C14.5019 15.4748 14.7163 16.0743 14.7163 16.8199C14.7163 17.5864 14.4938 18.2032 14.0485 18.6721C13.6033 19.1411 13.0225 19.376 12.3068 19.376Z"
      fill="white"
    />
    <path
      d="M19.1344 19.1176C18.779 19.2898 18.3141 19.376 17.7411 19.376C16.9934 19.376 16.4054 19.1567 15.9772 18.7165C15.5491 18.2772 15.335 17.6916 15.335 16.9599C15.335 16.1804 15.5759 15.5479 16.0571 15.0633C16.5384 14.5787 17.1633 14.336 17.9313 14.336C18.4072 14.336 18.8082 14.396 19.1344 14.5161V15.5731C18.8082 15.3783 18.4364 15.2808 18.0197 15.2808C17.5623 15.2808 17.1925 15.4252 16.9119 15.7123C16.6311 15.9995 16.4904 16.3901 16.4904 16.8816C16.4904 17.3523 16.6229 17.7282 16.888 18.0083C17.1531 18.2885 17.51 18.4277 17.9586 18.4277C18.3868 18.4277 18.779 18.3241 19.1344 18.1153V19.1176Z"
      fill="white"
    />
  </SvgIcon>
);

export default MSWordIcon;
