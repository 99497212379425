import React from 'react';
import { Backdrop as MuiBackdrop, CircularProgress, useMediaQuery } from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import DelayedRender from '../DelayedRender/DelayedRender';

const StyledMuiBackdrop = withStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))(MuiBackdrop);

interface ILoadingOverlay {
  delay?: number;
}

const LoadingOverlay: React.FC<ILoadingOverlay> = ({ delay = 200, children, ...restProps }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <DelayedRender delay={delay}>
        <StyledMuiBackdrop open>
          <CircularProgress style={{ marginLeft: isDesktop ? theme.spacing(9) : 0 }} />
        </StyledMuiBackdrop>
      </DelayedRender>
      {children(restProps)}
    </>
  );
};

export default LoadingOverlay;
