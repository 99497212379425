import { useQuery, useQueryClient } from 'react-query';
import { RequestTeamAPI } from 'api';
import { TQuery } from 'api/api.types';

export const useGetResourceGroups = ({ args, config }: TQuery = {}) => {
  return useQuery(['resource-groups', args], () => RequestTeamAPI.getResourceGroups(args), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

/*
export const useGetResourceSubDepartments = ({ args, config }: TQuery = {}) => {
  return useQuery(
    ['resource-sub-departments', args],
    () => RequestTeamAPI.getResourceSubDepartments(args),
    {
      staleTime: 1000 * 60 * 5,
      ...config
    }
  );
};*/

export const useGetDepartmentGroupDataset = ({ args, config }: TQuery = {}) => {
  return useQuery(
    ['department-group-dataset', args],
    () => RequestTeamAPI.getDepartmentGroupDataset(args),
    {
      staleTime: 1000 * 60 * 5,
      // enabled: !!args?.deptId,
      ...config
    }
  );
};

export const useGetRequestTeamDataset = ({ args, config }: TQuery = {}) => {
  return useQuery(
    ['request-team-dataset', args],
    () => RequestTeamAPI.getRequestTeamDataset(args),
    {
      staleTime: 1000 * 60 * 5,
      // enabled: !!args?.deptId,
      ...config
    }
  );
};

export const useGetResourceTeam = ({ args, config }: TQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async () => {
    const { items, count } = await RequestTeamAPI.getResourceTeam(args);
    queryClient.setQueryData(['resource-team-headers', { requestId: args.requestId }], count);
    return items;
  };

  return useQuery(['resource-team', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetProjectManager = ({ args, config }: TQuery = {}) => {
  return useQuery(
    ['resource-project-manager', args],
    () => RequestTeamAPI.getProjectManager(args),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!args?.requestId,
      ...config
    }
  );
};

export const useGetProjectLead = ({ args, config }: TQuery = {}) => {
  return useQuery(['resource-project-lead', args], () => RequestTeamAPI.getProjectLead(args), {
    staleTime: 1000 * 60 * 5,
    enabled: !!args?.requestId,
    ...config
  });
};

export const useGetIndividualContrubutors = ({
  args,
  config
}: {
  args: { requestId: number; teamMemberId: number };
}) => {
  return useQuery(
    ['individual-contributors', args],
    () => RequestTeamAPI.getIndividualContributors(args),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!args?.requestId,
      ...config
    }
  );
};
