import React from 'react';
import { Button as MuiButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  default: {
    height: 38,
    boxShadow: 'none',
    border: 'none',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.teal.digital5,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#007983',
      color: theme.palette.background.paper,
      border: 'none'
    },
    '&:active': {
      backgroundColor: theme.palette.teal.digital70,
      color: theme.palette.background.paper
    },
    padding: theme.spacing(1, 2),
    // '&:focus': {
    //   backgroundColor: theme.palette.teal.digital70,
    //   color: theme.palette.background.paper
    // },
    // '&:focus:after': {
    //   borderRadius: 4,
    //   padding: 0,
    //   border: '0.5px solid #727678',
    //   position: 'absolute',
    //   content: '""',
    //   top: 2,
    //   left: 2,
    //   bottom: 2,
    //   right: 2
    // },
    '&:disabled': {
      color: theme.palette.grey.disabledBtnText,
      backgroundColor: '#EFF0F1',
      border: 'none'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const Button = ({ children, isLoading = false, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <MuiButton {...props} disableElevation classes={{ root: !props.variant && classes.default }}>
        {children}
      </MuiButton>

      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

export default Button;
