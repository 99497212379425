import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CustomFilesCheckIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
      stroke="#007983"
    />
    <path
      d="M9 14C11.7614 14 14 11.7614 14 9C14 6.23858 11.7614 4 9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14Z"
      fill="#007983"
    />
  </SvgIcon>
);

export default CustomFilesCheckIcon;
