import { useMutation, UseMutationOptions } from 'react-query';
import { AdminAPI } from 'api';

export const useAddSubDepartmentMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.addSubDepartmentMember, { ...config });
};

export const useAddSubDepartment = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.addSubDepartment, { ...config });
};

export const useEditSubDepartment = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.editSubDepartment, { ...config });
};

export const useDeleteSubDepartment = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.deleteSubDepartment, { ...config });
};

export const useAddArea = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.addArea, { ...config });
};

export const useEditArea = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.editArea, { ...config });
};

export const useDeleteArea = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.deleteArea, { ...config });
};

export const useDeleteSubDepartmentMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.deleteSubDepartmentMember, { ...config });
};

export const useUpdateSubDepartmentMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.updateSubDepartmentMember, { ...config });
};

export const useAddAreaMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.addAreaMember, { ...config });
};

export const useDeleteAreaMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.deleteAreaMember, { ...config });
};

export const useUpdateAreaMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.updateAreaMember, { ...config });
};

export const useAddTeam = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.addTeam, { ...config });
};

export const useEditTeam = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.editTeam, { ...config });
};

export const useDeleteTeam = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.deleteTeam, { ...config });
};

export const useAddTeamsMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.addTeamsMember, { ...config });
};

export const useDeleteTeamsMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.deleteTeamsMember, { ...config });
};

export const useUpdateTeamsMember = (config?: UseMutationOptions<any>) => {
  return useMutation(AdminAPI.updateTeamsMember, { ...config });
};
