import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChangeIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M13.0963 7.58343C13.077 7.78595 13.0475 7.98804 13.0079 8.18895C12.7581 9.45327 12.1161 10.6066 11.1731 11.4848C10.2301 12.363 9.0342 12.9214 7.75557 13.0804C6.47693 13.2395 5.18072 12.9911 4.05139 12.3707C2.92206 11.7502 2.01715 10.7893 1.46543 9.62466H3.84949C3.96551 9.62466 4.07677 9.57856 4.15881 9.49651C4.24085 9.41445 4.28693 9.30317 4.28693 9.18712C4.28693 9.07108 4.24085 8.95979 4.15881 8.87774C4.07677 8.79569 3.96551 8.74959 3.84949 8.74959H0V12.5999C0 12.7159 0.0460875 12.8272 0.128124 12.9093C0.21016 12.9913 0.321425 13.0374 0.437442 13.0374C0.553459 13.0374 0.664724 12.9913 0.74676 12.9093C0.828797 12.8272 0.874884 12.7159 0.874884 12.5999V10.386C1.48301 11.4779 2.37084 12.3882 3.44714 13.0234C4.52345 13.6585 5.74941 13.9956 6.99907 14C8.13629 14.0002 9.25643 13.7232 10.2626 13.193C11.2687 12.6629 12.1305 11.8955 12.7734 10.9572C13.4164 10.019 13.821 8.93821 13.9524 7.80837C13.9612 7.73345 13.9687 7.65845 13.9749 7.58343H13.0963Z"
      fill="#7D858C"
    />
    <path
      d="M10.5529 0.976611C9.47655 0.341459 8.25059 0.00437725 7.00093 9.94877e-08C5.86371 -0.000191645 4.74357 0.276783 3.73744 0.806958C2.73131 1.33713 1.86949 2.10454 1.22656 3.04277C0.583634 3.981 0.178958 5.06179 0.0475517 6.19163C0.0388377 6.26656 0.0313428 6.34155 0.0250633 6.41657H0.903685C0.923 6.21406 0.952454 6.01197 0.992137 5.81105C1.24186 4.54673 1.8839 3.3934 2.82689 2.51518C3.76989 1.63697 4.9658 1.07861 6.24443 0.919562C7.52307 0.760519 8.81928 1.0089 9.94861 1.62935C11.0779 2.2498 11.9829 3.21071 12.5346 4.37534H10.1505C10.0345 4.37534 9.92323 4.42144 9.84119 4.50349C9.75915 4.58555 9.71307 4.69684 9.71307 4.81288C9.71307 4.92892 9.75915 5.04021 9.84119 5.12226C9.92323 5.20431 10.0345 5.25041 10.1505 5.25041H14V1.40011C14 1.28407 13.9539 1.17278 13.8719 1.09073C13.7898 1.00867 13.6786 0.962576 13.5626 0.962576C13.4465 0.962576 13.3353 1.00867 13.2532 1.09073C13.1712 1.17278 13.1251 1.28407 13.1251 1.40011V3.61403C12.517 2.52208 11.6292 1.61176 10.5529 0.976611Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default ChangeIcon;
