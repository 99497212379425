import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const OutdentIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
    <rect y="0.666504" width="16" height="1.33333" rx="0.666667" fill="#7D858C" />
    <rect y="14.6665" width="8" height="1.33333" rx="0.666667" fill="#7D858C" />
    <rect y="5.3335" width="16" height="1.33333" rx="0.666667" fill="#7D858C" />
    <rect y="10" width="8" height="1.33333" rx="0.666667" fill="#7D858C" />
    <path
      d="M9.66276 12.9985L11.9961 10.6652"
      stroke="#008BC7"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M9.66276 12.9985L11.9961 15.3319"
      stroke="#008BC7"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M15.3306 12.9985L9.99723 12.9985"
      stroke="#008BC7"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default OutdentIcon;
