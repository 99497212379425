import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const TXTIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117785" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117785)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#6C757D"
    />
    <path
      d="M9.00627 15.5148H7.56859V19.6299H6.4305V15.5148H5V14.5899H9.00627V15.5148Z"
      fill="white"
    />
    <path
      d="M14.0412 19.6299H12.6953L11.8271 17.9889C11.7939 17.9277 11.7606 17.8152 11.7255 17.6515H11.7111C11.694 17.7289 11.6553 17.8458 11.595 18.0024L10.7232 19.6299H9.37012L10.9769 17.1099L9.50777 14.5899H10.8888L11.6094 16.1013C11.6652 16.221 11.7156 16.3632 11.7606 16.5269H11.7741C11.8028 16.4279 11.855 16.2822 11.9324 16.087L12.734 14.5899H13.999L12.4875 17.0892L14.0412 19.6299Z"
      fill="white"
    />
    <path
      d="M18.4594 15.5148H17.0217V19.6299H15.8836V15.5148H14.4531V14.5899H18.4594V15.5148Z"
      fill="white"
    />
  </SvgIcon>
);

export default TXTIcon;
