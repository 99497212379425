import React, { useState, useEffect, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { NAV_LINKS } from './AppLayout.constants';

interface IAppLayoutContext {
  title: string;
  navTabIdx: number;
  isDesktopDrawerOpen: boolean;
  isMobileDrawerOpen: boolean;
  handleDesktopDrawerOpen: () => void;
  handleDesktopDrawerClose: () => void;
  handleMobileDrawerToggle: () => void;
  handleNavChange: () => void;
  handleLogoClick: () => void;
}

export const AppLayoutContext = createContext({} as IAppLayoutContext);

const getNavIdx = history => {
  const currentPath = history.location.pathname;

  if (currentPath === NAV_LINKS[0].url) return 0;

  const foundNavIdx = NAV_LINKS.slice(1).findIndex(el =>
    currentPath.includes(el?.url?.split('/')?.[1])
  );

  // TODO: uncomment once dashboard is complete
  // return foundNavIdx < 0 ? null : foundNavIdx + 1;
  return foundNavIdx < 0 ? 0 : foundNavIdx + 1;
};

interface IAppLayoutProvider {
  children: React.ReactNode;
}

export const AppLayoutProvider: React.FC<IAppLayoutProvider> = ({ children, ...restProps }) => {
  const history = useHistory();
  const [navTabIdx, setNavTabIdx] = useState(getNavIdx(history));
  const [isDesktopDrawerOpen, setIsDesktopDrawerOpen] = useState(false);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const handleDesktopDrawerOpen = () => {
    setIsDesktopDrawerOpen(true);
  };

  const handleDesktopDrawerClose = () => {
    setIsDesktopDrawerOpen(false);
  };

  const handleMobileDrawerToggle = () => {
    setIsMobileDrawerOpen(prevState => !prevState);
  };

  const handleNavChange = (e: React.ChangeEvent<{}>, idx: number) => {
    const link = NAV_LINKS[idx].url;
    if (link.includes('requests')) {
      const savedTab = localStorage.getItem('requests-list');
      if (savedTab) history.push(savedTab);
      else link && history.push(link);
    } else if (link.includes('dashboard')) {
      const savedTab = localStorage.getItem('dashboard-list');
      if (savedTab) history.push(savedTab);
      else link && history.push(link);
    } else if (link.includes('intake')) {
      const savedTab = localStorage.getItem('intake-list');
      if (savedTab) history.push(savedTab);
      else link && history.push(link);
    } else if (link.includes('team-planner')) {
      localStorage.setItem('origin-list-url', link);
      link && history.push(link);
    } else {
      link && history.push(link);
    }
  };

  const handleLogoClick = () => {
    const navIdx = NAV_LINKS.findIndex(el =>
      history.location.pathname.includes(el?.url?.split('/')?.[1])
    );
    setNavTabIdx(navIdx);
    history.push('/dashboard/explore/assigned');
  };

  useEffect(() => {
    setNavTabIdx(getNavIdx(history));
  }, [history.location.pathname]);

  const value = {
    navTabIdx,
    isDesktopDrawerOpen,
    isMobileDrawerOpen,
    handleDesktopDrawerOpen,
    handleDesktopDrawerClose,
    handleMobileDrawerToggle,
    handleNavChange,
    handleLogoClick,
    ...restProps
  };

  return <AppLayoutContext.Provider value={value}>{children}</AppLayoutContext.Provider>;
};

export default AppLayoutContext;
