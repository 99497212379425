import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SearchIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M11.9174 11.2869L8.8941 8.26366C8.84019 8.20975 8.76988 8.18163 8.69489 8.18163H8.45349C9.25736 7.31214 9.74952 6.15204 9.74952 4.87476C9.74952 2.18192 7.5676 0 4.87476 0C2.18192 0 0 2.18192 0 4.87476C0 7.5676 2.18192 9.74952 4.87476 9.74952C6.15204 9.74952 7.31214 9.25736 8.18163 8.45584V8.69489C8.18163 8.76988 8.2121 8.84019 8.26366 8.8941L11.2869 11.9174C11.3971 12.0275 11.5752 12.0275 11.6854 11.9174L11.9174 11.6854C12.0275 11.5752 12.0275 11.3971 11.9174 11.2869ZM4.87476 8.99956C2.5944 8.99956 0.749963 7.15512 0.749963 4.87476C0.749963 2.5944 2.5944 0.749963 4.87476 0.749963C7.15512 0.749963 8.99956 2.5944 8.99956 4.87476C8.99956 7.15512 7.15512 8.99956 4.87476 8.99956Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default SearchIcon;
