import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ArrowRightIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      d="M12.9451 7.13052C13.0183 7.0573 13.0183 6.93865 12.9451 6.86549L9.38326 3.30366C9.34577 3.26593 9.2989 3.22656 9.24969 3.22656C9.20048 3.22656 9.15376 3.24487 9.11715 3.28149C9.04393 3.35472 9.04393 3.47336 9.11715 3.54652L12.358 6.78732H1.18869C1.08544 6.78732 1.00122 6.87117 1.00122 6.97479C1.00122 7.10006 1.08544 7.16333 1.18869 7.16333H12.3593L9.11847 10.4041C9.04524 10.4774 9.04524 10.596 9.11847 10.6692C9.1917 10.7423 9.31034 10.7424 9.3835 10.6692L12.9451 7.13052Z"
      fill="#7D858C"
      stroke={props.stroke || '#7D858C'}
      strokeWidth="0.5"
    />
  </SvgIcon>
);

export default ArrowRightIcon;
