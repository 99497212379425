import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ClipboardCheckedIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M13.2267 1.4H11.58V2.04C11.58 2.54667 11.1867 2.96 10.7067 2.96H5.29333C4.80667 2.96 4.42 2.54667 4.42 2.04V1.4H2.79333C2.36 1.4 2 1.77333 2 2.22V15.18C2 15.6267 2.36 16 2.79333 16H13.2133C13.64 16 14.0067 15.6267 14.0067 15.18V2.22C14 1.76667 13.6533 1.4 13.2267 1.4ZM10.56 7.66L7.32667 10.8733C7.14 11.06 6.98667 11.06 6.78667 10.8733C6.33333 10.4333 5.89333 9.96 5.45333 9.5C5.1 9.16 5.64 8.62 5.98 8.97333L7.08 10.0533L10.02 7.11333C10.3733 6.76 10.8933 7.3 10.56 7.66ZM10.9 1.08667V2.07333C10.9 2.2 10.8 2.30667 10.6733 2.30667H5.28C5.16 2.30667 5.06 2.21333 5.04 2.08V1.08667C5.04 0.486667 5.50667 0 6.08667 0H9.85333C10.4267 0 10.9 0.486667 10.9 1.08667Z"
      fill="#006C9B"
    />
    <path
      d="M10.56 7.66L7.32667 10.8733C7.14 11.06 6.98667 11.06 6.78667 10.8733C6.33333 10.4333 5.89333 9.96 5.45333 9.5C5.1 9.16 5.64 8.62 5.98 8.97333L7.08 10.0533L10.02 7.11333C10.3733 6.76 10.8933 7.3 10.56 7.66Z"
      fill="#006C9B"
    />
    <path
      d="M10.9141 6.79708C10.8198 6.70494 10.6898 6.63744 10.5463 6.62776C10.3999 6.61787 10.2473 6.66887 10.1153 6.80023L10.1151 6.8004L6.97491 9.94064L5.8725 8.85512C5.73049 8.70723 5.54279 8.6475 5.34912 8.6978L5.34912 8.69779L5.34765 8.69819C5.24474 8.7266 5.15095 8.78121 5.07544 8.85669C4.99993 8.93217 4.94529 9.02594 4.91684 9.12884L4.91683 9.12884L4.91642 9.13042C4.86634 9.32387 4.92589 9.51139 5.07326 9.65323C5.19271 9.77682 5.31397 9.90373 5.43129 10.0267C5.79304 10.4059 6.16882 10.7997 6.55212 11.1687L6.55211 11.1687L6.55374 11.1702C6.68409 11.2903 6.81811 11.3655 6.96507 11.3655C7.1132 11.3655 7.24399 11.289 7.36669 11.1664C7.36672 11.1663 7.36675 11.1663 7.36679 11.1663L10.9336 7.61813L10.9338 7.61794L10.9349 7.61683L10.9349 7.61686L10.9367 7.61501C11.1958 7.34228 11.1155 6.99357 10.9141 6.79708ZM10.9141 6.79708L10.8443 6.86862M10.9141 6.79708C10.9141 6.79707 10.9141 6.79706 10.9141 6.79705L10.8443 6.86862M10.8443 6.86862C10.6847 6.71281 10.4107 6.64735 10.1859 6.87111L10.8642 7.54613C11.0774 7.32168 11.0156 7.03579 10.8443 6.86862Z"
      fill="white"
      stroke="white"
      stroke-width="0.2"
    />
  </SvgIcon>
);

export default ClipboardCheckedIcon;
