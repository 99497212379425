import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const GIFIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117784" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117784)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#6C757D"
    />
    <path
      d="M11.3057 18.9645C10.8298 19.2385 10.2391 19.376 9.53174 19.376C8.74785 19.376 8.13013 19.1585 7.67773 18.7252C7.22619 18.2911 7 17.6934 7 16.9321C7 16.1665 7.24622 15.5427 7.74038 15.0598C8.23455 14.577 8.89141 14.336 9.71183 14.336C10.2286 14.336 10.6836 14.4073 11.0778 14.55V15.5801C10.7019 15.3626 10.2417 15.2538 9.69792 15.2538C9.2429 15.2538 8.8714 15.4017 8.58517 15.6967C8.29806 15.9925 8.15538 16.3875 8.15538 16.8816C8.15538 17.3828 8.28327 17.7699 8.5408 18.044C8.79745 18.3181 9.14546 18.4546 9.58307 18.4546C9.84495 18.4546 10.0537 18.4172 10.2077 18.3424V17.3915H9.23246V16.5145H11.3057V18.9645Z"
      fill="white"
    />
    <path
      d="M13.9639 14.419V15.2682H13.5559V18.4429H13.9639V19.292H12.0508V18.4429H12.458V15.2682H12.0508V14.419H13.9639Z"
      fill="white"
    />
    <path
      d="M17.6853 15.3125H15.9661V16.5088H17.5461V17.3997H15.9661V19.292H14.8682V14.419H17.6853V15.3125Z"
      fill="white"
    />
  </SvgIcon>
);

export default GIFIcon;
