import { get, post, put, patch, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';
import {
  AddProgramRequestsPayload,
  CreateProgramPayload,
  DeleteProgramPayload,
  GetPrograms,
  Program,
  ProgramEnums,
  ProgramStatistics,
  UpdateProgramPayload
} from './programs.types';
import { AxiosPromise, AxiosResponse } from 'axios';

const BASE_URL = `${getEnv('IT_BASE_URL')}/program-management`;

export const createProgram = async (data: CreateProgramPayload): Promise<Program> => {
  return (await post({ url: `${BASE_URL}/programs`, data })).data;
};

export const getPrograms = async ({ params }: TAPIArgs = {}, pageNumber = 1) => {
  async function getProgramsFunction(): Promise<AxiosResponse<GetPrograms[]>> {
    return await get({
      url: `${BASE_URL}/programs`,
      params: { ...params, pageNumber }
    });
  }

  const response = await getProgramsFunction();

  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata),
    filterCount: JSON.parse(response.headers.filtercount)
  };
};

export const getProgramFilterEnums = async (): Promise<ProgramEnums> => {
  return (await get({ url: `${BASE_URL}/program-filter-enums` })).data;
};

export const getProgramRequestFilterEnums = async (): Promise<ProgramEnums> => {
  return (await get({ url: `${BASE_URL}/program-request-filter-enums` })).data;
};

export const getProgramFilterDefaults = async () => {
  return (await get({ url: `${BASE_URL}/program-filter-defaults` })).data;
};

export const getProgram = async (id: string | number): Promise<Program> => {
  return (await get({ url: `${BASE_URL}/programs/${id}` })).data;
};

export const deleteProgram = async (payload: DeleteProgramPayload): Promise<Program> => {
  const { id, data } = payload;
  return (await del({ url: `${BASE_URL}/programs/${id}`, data })).data;
};

export const getProgramEditRights = async ({ programId }: any) => {
  return (await get({ url: `${BASE_URL}/programs/${programId}/program-edit-rights` })).data;
};

export const getProgramRequests = async ({ params, programId }: TAPIArgs = {}, pageNumber = 1) => {
  async function getRequests(): Promise<AxiosResponse<any>> {
    return await get({
      url: `${BASE_URL}/programs/${programId}/requests`,
      params: { pageNumber, ...params }
    });
  }

  const response = await getRequests();

  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata),
    filterCount: JSON.parse(response.headers.filtercount)
  };
};

export const getProgramRequestOptions = async (search: string) => {
  return get({
    url: `${BASE_URL}/requests?rfilt.search=${search.trim()}`
  });
};

export const updateProgram = async (payload: UpdateProgramPayload): Promise<Program> => {
  const { id, data } = payload;
  return (await put({ url: `${BASE_URL}/programs/${id}`, data })).data;
};

export const addProgramRequests = async (data: AddProgramRequestsPayload): Promise<any> => {
  return (await post({ url: `${BASE_URL}/programs/${data.programId}/requests`, data })).data;
};

export const deleteProgramRequests = async (data: any): Promise<any> => {
  return (await del({ url: `${BASE_URL}/programs/${data.programId}/requests`, data })).data;
};

export const searchPrograms = async (input: string): Promise<any> => {
  return (
    await get({
      url: `${BASE_URL}/program-search`,
      params: { searchTerm: input }
    })
  ).data;
};

export const getProgramStatistics = async (programId: number): Promise<ProgramStatistics> => {
  return (await get({ url: `${BASE_URL}/programs/${programId}/statistics` })).data;
};
