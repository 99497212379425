import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import AppLayout from '../../components/AppLayout/AppLayout';
import { MaintenanceErrorIcon } from 'components/icons';
import { isAuthenticated } from 'services/auth.service';

const ApplicationUnavailable = () => (
  <Grid container alignItems="center" style={{ height: '100%', padding: 8 }}>
    <Grid container justify="center" spacing={3} style={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          Oops!
        </Typography>
      </Grid>

      <Grid item xs={8} sm={6} md={4} lg={3}>
        <MaintenanceErrorIcon style={{ height: '100%', width: '100%' }} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          This application is currently unavailable.
        </Typography>
        <Typography variant="body1">We are making updates, it will be back up soon.</Typography>
      </Grid>
    </Grid>
  </Grid>
);

const MaintenancePage: React.FC = () => {
  return (
    <Grid container alignItems="center" style={{ height: '100vh', overflow: 'hidden' }}>
      <ApplicationUnavailable />
    </Grid>
  );
};

export default MaintenancePage;
