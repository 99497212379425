import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SmallTriangle: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 10 10" fill="none" {...props}>
    <path
      d="M2.78094 9.93549L7.4806 5.24425C7.51243 5.2129 7.53771 5.17553 7.55496 5.13433C7.57222 5.09312 7.5811 5.04889 7.5811 5.00421C7.5811 4.95954 7.57222 4.91531 7.55496 4.8741C7.53771 4.83289 7.51243 4.79552 7.4806 4.76417L2.78094 0.0645126C2.75149 0.0339286 2.71348 0.0129618 2.6719 0.00436329C2.63032 -0.00423516 2.58712 -6.35007e-05 2.54795 0.0163308C2.50879 0.0327251 2.4755 0.0605762 2.45244 0.0962294C2.42939 0.131883 2.41765 0.173672 2.41877 0.216114V9.78389C2.41765 9.82633 2.42939 9.86812 2.45244 9.90377C2.4755 9.93942 2.50879 9.96728 2.54795 9.98367C2.58712 10.0001 2.63032 10.0042 2.6719 9.99564C2.71348 9.98704 2.75149 9.96607 2.78094 9.93549Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default SmallTriangle;
