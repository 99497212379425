import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CloseIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path d="M15.1783 14.4346C15.2274 14.4832 15.2663 14.5412 15.2929 14.605C15.3195 14.6688 15.3332 14.7372 15.3332 14.8063C15.3332 14.8754 15.3195 14.9439 15.2929 15.0077C15.2663 15.0715 15.2274 15.1294 15.1783 15.1781C15.1296 15.2271 15.0717 15.2661 15.0079 15.2927C14.9441 15.3193 14.8757 15.3329 14.8065 15.3329C14.7374 15.3329 14.669 15.3193 14.6052 15.2927C14.5414 15.2661 14.4835 15.2271 14.4348 15.1781L7.99984 8.73788L1.56488 15.1781C1.51621 15.2271 1.4583 15.2661 1.39449 15.2927C1.33069 15.3193 1.26225 15.3329 1.19313 15.3329C1.12401 15.3329 1.05558 15.3193 0.991771 15.2927C0.927966 15.2661 0.870056 15.2271 0.821382 15.1781C0.772306 15.1294 0.733354 15.0715 0.706772 15.0077C0.68019 14.9439 0.666504 14.8754 0.666504 14.8063C0.666504 14.7372 0.68019 14.6688 0.706772 14.605C0.733354 14.5412 0.772306 14.4832 0.821382 14.4346L7.26157 7.99961L0.821382 1.56466C0.722787 1.46607 0.667398 1.33234 0.667398 1.19291C0.667398 1.12387 0.680996 1.05551 0.707417 0.99172C0.733837 0.927935 0.772563 0.869978 0.821382 0.821159C0.870201 0.77234 0.928157 0.733615 0.991942 0.707194C1.05573 0.680774 1.12409 0.667175 1.19313 0.667175C1.33257 0.667175 1.46629 0.722565 1.56488 0.821159L7.99984 7.26135L14.4348 0.821159C14.5334 0.722565 14.6671 0.667175 14.8065 0.667175C14.946 0.667175 15.0797 0.722565 15.1783 0.821159C15.2769 0.919754 15.3323 1.05348 15.3323 1.19291C15.3323 1.33234 15.2769 1.46607 15.1783 1.56466L8.7381 7.99961L15.1783 14.4346Z" />
  </SvgIcon>
);

export default CloseIcon;
