import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PowerPointXIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_10661_117790" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_10661_117790)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#CF5230"
    />
    <path
      d="M7.13539 15.4617V17.0325H7.58163C8.18621 17.0325 8.48851 16.768 8.48851 16.2381C8.48851 15.7208 8.18621 15.4617 7.58163 15.4617H7.13539ZM7.13539 17.8935V19.6299H6V14.5899H7.77867C9.04811 14.5899 9.68328 15.1261 9.68328 16.1967C9.68328 16.7023 9.50065 17.1117 9.13628 17.4248C8.77191 17.737 8.28518 17.8935 7.6761 17.8935H7.13539Z"
      fill="white"
    />
    <path
      d="M11.572 15.4617V17.0325H12.0182C12.6228 17.0325 12.9251 16.768 12.9251 16.2381C12.9251 15.7208 12.6228 15.4617 12.0182 15.4617H11.572ZM11.572 17.8935V19.6299H10.4375V14.5899H12.2153C13.4847 14.5899 14.1199 15.1261 14.1199 16.1967C14.1199 16.7023 13.9372 17.1117 13.5738 17.4248C13.2094 17.737 12.7227 17.8935 12.1136 17.8935H11.572Z"
      fill="white"
    />
    <path
      d="M18.5937 15.5148H17.156V19.6299H16.0179V15.5148H14.5874V14.5899H18.5937V15.5148Z"
      fill="white"
    />
  </SvgIcon>
);

export default PowerPointXIcon;
