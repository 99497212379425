import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MSGIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_13589_142916" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13589_142916)">
      <path
        d="M2.95409 23.6221C2.74726 23.6221 2.5791 23.4541 2.5791 23.2471V0.747768C2.5791 0.540775 2.74726 0.37278 2.95409 0.37278H15.5485L21.3285 6.15286V23.2471C21.3285 23.4541 21.1604 23.6221 20.9535 23.6221H2.95409Z"
        fill="white"
      />
    </g>
    <path
      d="M15.7037 0.000736237H2.95408C2.53984 0.000736237 2.2041 0.336725 2.2041 0.750713V23.25C2.2041 23.664 2.53984 24 2.95408 24H20.9535C21.3677 24 21.7035 23.664 21.7035 23.25V6.00055L15.7037 0.000736237ZM15.3931 0.750713L20.9535 6.31104V23.25H2.95408V0.750713H15.3931Z"
      fill="#AEB3B7"
    />
    <path
      d="M21.7039 6.75H15.7041C15.2899 6.75 14.9541 6.41401 14.9541 6.00002V0.00020647"
      fill="#AEB3B7"
    />
    <path
      d="M22.2143 21H1.78571C1.35173 21 1 20.552 1 20V14C1 13.448 1.35173 13 1.78571 13H22.2143C22.6482 13 23 13.448 23 14V20C23 20.552 22.6482 21 22.2143 21Z"
      fill="#3170C0"
    />
    <path
      d="M19.4702 19.1085C18.9943 19.3826 18.4026 19.52 17.6962 19.52C16.9114 19.52 16.2937 19.3025 15.8422 18.8692C15.3898 18.4351 15.1636 17.8374 15.1636 17.0762C15.1636 16.3105 15.4115 15.6867 15.9048 15.2039C16.3981 14.721 17.0559 14.48 17.8763 14.48C18.3922 14.48 18.8481 14.5514 19.2422 14.694V15.7241C18.8655 15.5066 18.4061 15.3979 17.8623 15.3979C17.4073 15.3979 17.0358 15.5458 16.7496 15.8407C16.4634 16.1365 16.3198 16.5315 16.3198 17.0257C16.3198 17.5268 16.4477 17.914 16.7052 18.188C16.9628 18.4621 17.309 18.5987 17.7466 18.5987C18.0094 18.5987 18.2182 18.5613 18.3722 18.4865V17.5355H17.3969V16.6585H19.4702V19.1085Z"
      fill="white"
    />
    <path
      d="M11.1387 19.2477V18.1602C11.3362 18.3255 11.5502 18.4499 11.7807 18.5326C12.0122 18.6161 12.2453 18.657 12.4811 18.657C12.6186 18.657 12.7395 18.6448 12.843 18.6196C12.9466 18.5943 13.0318 18.5595 13.1014 18.516C13.171 18.4716 13.2224 18.4195 13.2563 18.3594C13.2902 18.2994 13.3067 18.2341 13.3067 18.1637C13.3067 18.0688 13.2798 17.9836 13.2258 17.9087C13.171 17.8339 13.0962 17.7652 13.0031 17.7017C12.9091 17.6382 12.7969 17.5773 12.6682 17.5181C12.5394 17.4589 12.3993 17.3989 12.2497 17.338C11.8695 17.1797 11.5858 16.9857 11.3988 16.7568C11.2117 16.528 11.1187 16.2522 11.1187 15.9277C11.1187 15.6745 11.1691 15.4553 11.2709 15.2735C11.3736 15.0917 11.5128 14.9411 11.6876 14.8237C11.8625 14.7053 12.067 14.6192 12.2975 14.5635C12.529 14.5079 12.7734 14.48 13.0318 14.48C13.285 14.48 13.5112 14.4957 13.7061 14.5261C13.9018 14.5566 14.0837 14.6036 14.2481 14.6671V15.6833C14.1672 15.6267 14.0784 15.5762 13.9819 15.5336C13.8844 15.491 13.7861 15.4553 13.6843 15.4266C13.5825 15.3979 13.4807 15.377 13.3798 15.3639C13.2798 15.35 13.1832 15.3431 13.0927 15.3431C12.9692 15.3431 12.8552 15.3553 12.7534 15.3788C12.6508 15.4031 12.5646 15.4362 12.495 15.4788C12.4254 15.5223 12.3706 15.5736 12.3315 15.6337C12.2923 15.6937 12.274 15.7616 12.274 15.8364C12.274 15.9173 12.2958 15.9904 12.3384 16.0556C12.381 16.12 12.4419 16.1809 12.522 16.2383C12.602 16.2966 12.6969 16.3532 12.8108 16.4088C12.9248 16.4645 13.0518 16.5211 13.1945 16.5802C13.3903 16.662 13.5651 16.7481 13.72 16.8404C13.8749 16.9317 14.0088 17.0353 14.1193 17.151C14.2298 17.2667 14.3151 17.3989 14.3742 17.5468C14.4334 17.6956 14.4621 17.8687 14.4621 18.0654C14.4621 18.3377 14.4117 18.5656 14.3081 18.7501C14.2046 18.9354 14.0645 19.085 13.8879 19.199C13.7113 19.313 13.506 19.3956 13.2711 19.4452C13.037 19.4948 12.7891 19.52 12.5289 19.52C12.2619 19.52 12.0069 19.4974 11.7659 19.4522C11.5249 19.406 11.3153 19.3382 11.1387 19.2477Z"
      fill="white"
    />
    <path
      d="M10.0836 19.5195H8.99869V16.6032C8.99869 16.2892 9.01261 15.9412 9.03958 15.5601H9.0126C8.95692 15.8594 8.90559 16.0751 8.85948 16.2057L7.71803 19.5195H6.82103L5.65868 16.2396C5.62649 16.1517 5.57603 15.9255 5.50556 15.5601H5.47512C5.5047 16.0412 5.51862 16.4623 5.51862 16.8242V19.5195H4.53027V14.6457H6.13719L7.13338 17.535C7.21342 17.7656 7.27084 17.9988 7.30651 18.2311H7.32739C7.38829 17.9613 7.45267 17.7273 7.52053 17.5281L8.5167 14.6457H10.0836V19.5195Z"
      fill="white"
    />
  </SvgIcon>
);

export default MSGIcon;
