import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const FileIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M10.6646 3.175L7.74961 0.115C7.68178 0.0493495 7.5936 0.00878494 7.49961 0L2.86461 0C2.43052 -2.01743e-06 2.01413 0.172097 1.70672 0.478576C1.3993 0.785055 1.22593 1.20091 1.22461 1.635V10.36C1.22461 10.795 1.39739 11.2121 1.70495 11.5197C2.01251 11.8272 2.42965 12 2.86461 12H9.12961C9.5637 12 9.98008 11.8279 10.2875 11.5214C10.5949 11.2149 10.7683 10.7991 10.7696 10.365V3.435C10.7658 3.33878 10.7287 3.24686 10.6646 3.175V3.175ZM9.49961 3.055H8.44961C8.29181 3.055 8.14047 2.99231 8.02888 2.88073C7.9173 2.76914 7.85461 2.6178 7.85461 2.46V2.46V1.31L9.49961 3.055ZM9.99961 10.365C9.99961 10.6024 9.90531 10.83 9.73747 10.9979C9.56962 11.1657 9.34198 11.26 9.10461 11.26H2.86461C2.62805 11.2574 2.40191 11.1623 2.23462 10.995C2.06734 10.8277 1.97221 10.6016 1.96961 10.365V1.635C1.97221 1.39844 2.06734 1.1723 2.23462 1.00501C2.40191 0.837729 2.62805 0.7426 2.86461 0.74H7.10961V2.46C7.11093 2.81452 7.25269 3.15407 7.50385 3.40429C7.75501 3.65451 8.09508 3.795 8.44961 3.795H10.0246L9.99961 10.365ZM6.90961 7.185C6.90961 7.28313 6.87063 7.37724 6.80124 7.44663C6.73185 7.51602 6.63774 7.555 6.53961 7.555H3.64461C3.54648 7.555 3.45237 7.51602 3.38298 7.44663C3.31359 7.37724 3.27461 7.28313 3.27461 7.185C3.27461 7.08687 3.31359 6.99276 3.38298 6.92337C3.45237 6.85398 3.54648 6.815 3.64461 6.815H6.53961C6.63774 6.815 6.73185 6.85398 6.80124 6.92337C6.87063 6.99276 6.90961 7.08687 6.90961 7.185V7.185ZM8.72461 5.385C8.72461 5.48313 8.68563 5.57724 8.61624 5.64663C8.54685 5.71602 8.45274 5.755 8.35461 5.755H3.64461C3.54648 5.755 3.45237 5.71602 3.38298 5.64663C3.31359 5.57724 3.27461 5.48313 3.27461 5.385C3.27461 5.28687 3.31359 5.19276 3.38298 5.12337C3.45237 5.05398 3.54648 5.015 3.64461 5.015H8.34961C8.39862 5.01434 8.44727 5.02342 8.49274 5.04172C8.53821 5.06001 8.57959 5.08716 8.61448 5.12159C8.64937 5.15601 8.67707 5.19702 8.69597 5.24224C8.71488 5.28746 8.72461 5.33599 8.72461 5.385V5.385Z" />
  </SvgIcon>
);

export default FileIcon;
