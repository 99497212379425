import * as api from './scoring.api';
import * as queries from './scoring.queries';
import * as mutations from './scoring.mutations';
import * as constants from './scoring.constants';

export * from './scoring.api';
export * from './scoring.queries';
export * from './scoring.mutations';
export * from './scoring.constants';

export default {
  ...api,
  ...queries,
  ...mutations,
  ...constants
};
