import { useQuery, useQueryClient } from 'react-query';
import { TeamPlannerAPI } from 'api';
import { TQuery } from 'api/api.types';
import { reduceToOptions } from 'api/api.utils';

export const useGetTeamPlannerDropdownData = ({ args, config }: TQuery = {}) => {
  return useQuery(
    ['team-planner-dropdown', args],
    () => TeamPlannerAPI.getTeamPlannerDropdown(args),
    {
      staleTime: Infinity,
      // enabled: !!args?.deptId || !!args?.subDeptId || !!args?.areaId || !!args?.teamId,
      keepPreviousData: true,
      cacheTime: 0,
      ...config
    }
  );
};

export const useGetTeamPlannerData = ({ args, config }: TQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async () => {
    const { items, ...headers } = await TeamPlannerAPI.getTeamPlannerData(args);

    queryClient.setQueryData(['team-backlog-headers'], headers);

    return items;
  };

  return useQuery(['team-planner-dataset', args], queryFn, {
    staleTime: Infinity,
    // enabled: config.enabled,
    keepPreviousData: true,
    cacheTime: 0,
    ...config
  });
};

export const useGetDefaultGroup = () => {
  const queryFn = async () => await TeamPlannerAPI.getDefaultUserGroup();

  return useQuery(['default-user-group'], queryFn, {
    staleTime: Infinity
  });
};

export const useGetMembersForAdd = ({ args, config }: any) => {
  return useQuery(['members-for-add', args], () => TeamPlannerAPI.getMembersForAdd(args), {
    staleTime: 1000 * 60 * 5,
    enabled: !!args.deptId || !!args.subDeptId || !!args.areaId || !!args.teamId,
    ...config
  });
};
export const useGetMembersForEdit = ({ args, config }: any) => {
  return useQuery(['members-for-edit', args], () => TeamPlannerAPI.getMembersForEdit(args), {
    staleTime: 1000 * 60 * 5,
    enabled:
      (!!args.deptId || !!args.subDeptId || !!args.areaId || !!args.teamId) && !!args.requestId,
    cacheTime: 0,
    ...config
  });
};

export const useGetTeamPlannerEditRights = ({ args, config }: TQuery = {}) => {
  const queryFn = async () => await TeamPlannerAPI.getTeamPlannerEditRights(args);

  return useQuery(['team-planner-edit-rights', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    enabled: !!args?.groupId || !!args?.groupTypeId,
    cacheTime: 0,
    ...config
  });
};

export const useGetFilterEnums = () => {
  const queryFn = async () => reduceToOptions(await TeamPlannerAPI.getFilterEnums());

  return useQuery(['team-planner-filter-enums'], queryFn, {
    staleTime: Infinity
  });
};
