import { useQuery, useQueryClient } from 'react-query';
import { UserAPI } from 'api';
import { TModifiedTokenArgs, TQuery } from 'api/api.types';
import { user } from 'services/auth.service';
import { isInteger } from 'lodash';

export const useMe = (config?: any) => {
  return useQuery('me', UserAPI.getMe, {
    staleTime: 1000 * 60 * 60,
    enabled: !!user?.TeamId,
    ...config
  });
};

export const useUser = ({ args, ...config }: TQuery = {}) => {
  const queryFn = async () => {
    return await UserAPI.getTeamMember(args);
  };
  return useQuery(['users', args], queryFn, {
    staleTime: 1000 * 60 * 60,
    ...config,
    enabled: !!args?.id
  });
};

export const useSearchCostCenters = (input: string, config: any) => {
  const queryFn = async () => {
    return (await UserAPI.searchCostCenters(input)).data;
  };

  return useQuery(['cost-centers', input], queryFn, { ...config });
};

export const useApprovalsList = ({
  teamMemberNumber,
  approvalLevel
}: {
  teamMemberNumber: string | number | null;
  approvalLevel: '' | 'regular' | 'director' | 'escalated';
}) => {
  const queryFn = async () => {
    return await UserAPI.generateApprovalsList(teamMemberNumber, approvalLevel);
  };
  return useQuery(['approvals-list', teamMemberNumber, approvalLevel], queryFn, {
    staleTime: 1000 * 60,
    enabled:
      typeof teamMemberNumber === 'number' &&
      !Number.isNaN(teamMemberNumber) &&
      typeof approvalLevel === 'string',
    cacheTime: 0
  });
};

export const useCheckListOfUsers = ({ args, ...config }: TQuery = {}) => {
  const queryFn = async () => {
    return await UserAPI.getListOfUsers(args);
  };
  const listOfUsers = useQuery(['list-of-users', args], queryFn, {
    staleTime: Infinity,
    enabled: args?.length > 0 && !!args[0]?.value,
    ...config
  });

  return listOfUsers;
};

export const useGetFilesAuth = ({ ...config }: TQuery = {}) => {
  const queryFn = async () => {
    return await UserAPI.getFilesAuth();
  };
  return useQuery('get-files-auth', queryFn, {
    staleTime: 1000 * 60 * 60,
    ...config
  });
};

export const useSearchUsers = ({ args, ...config }: { args: string }) => {
  // please use searchTeamMembers from the BE in user api unless if that data is missing something
  const queryFn = async () => {
    return await UserAPI.searchUsers(args);
  };
  return useQuery(['use-search-users', args], queryFn, {
    staleTime: 1000 * 60,
    enabled: !!args,
    cacheTime: 0,
    ...config
  });
};

export const useSearchTeamMembers = ({ args, ...config }: { args: string }) => {
  return useQuery(['search-team-members', args], () => UserAPI.searchTeamMembers(args), {
    staleTime: 1000 * 60,
    ...config,
    refetchOnWindowFocus: true,
    enabled: args.length > 2 && typeof args === 'string'
  });
};

export const useModifiedUserToken = ({ args, ...config }: { args: TModifiedTokenArgs }) => {
  const queryClient = useQueryClient();

  const queryFn = async () => {
    const data = await UserAPI.getModifiedUserToken(args);

    queryClient.setQueryData(['modified-user-token-data'], data);

    return data;
  };

  return useQuery(['modified-user-token', args], queryFn, {
    staleTime: Infinity,
    ...config
  });
};
