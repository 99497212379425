export function getUserType(groups = []) {
  if (groups.includes('TeamMembers')) {
    return 'TeamMembers';
  }
}

export function createDefaultFlags() {
  // TBD
  const defaultFlagsArray = [
    'Admin',
    'PmAdmin',
    'Pm',
    'US',
    'USCompanyUpdate',
    'RSM',
    'LakeForest',
    'Irvine',
    'Australia',
    'Europe'
  ];
  const defaultFlags = {};

  for (let flag of defaultFlagsArray) {
    //@ts-ignore
    defaultFlags[`is${flag}`] = false;
  }

  return defaultFlags;
}

export function setUserDetails(userInfo) {
  // Note: Lists will need to be maintained
  let alCostCenter = ['101104', '202000', '401030', '491030'];
  let caCostCenter = ['11040', '101045', '401065'];

  const {
    userName: username,
    city: City,
    costCenter: CostCenter,
    companyName: CompanyName,
    country: Country,
    firstName,
    lastName,
    email,
    location,
    subArea,
    department,
    permissions,
    featureFlags,
    groups
  } = userInfo;

  const derivedDeptId = alCostCenter.includes(CostCenter)
    ? 5
    : caCostCenter.includes(CostCenter)
    ? 3
    : 4;

  return {
    username: username.toUpperCase(),
    firstName,
    lastName,
    FullName: `${firstName} ${lastName}`,
    email: email.toUpperCase(),
    CostCenter,
    location,
    subArea,
    CompanyName,
    CountryCode: Country,
    City,
    featureFlags,
    department,
    departmentId: derivedDeptId,
    permissions,
    groups
  };
}

export function setUserflags(outputUser, userInfo) {
  for (let flag of userInfo.flags) {
    if (flag.includes('Is')) {
      if (flag.includes('Manager')) {
        outputUser[flag] = true;
      } else {
        if (flag.includes('US')) {
          outputUser[`isUS`] = true;
          outputUser['isUSCompanyUpdate'] = true;
        } else {
          outputUser[`is${flag.slice(2)}`] = true;
        }
      }
    }
  }

  if (userInfo.groups.includes('ADMIN')) outputUser['isAdmin'] = true;

  if (userInfo.groups.includes('Admin')) outputUser['isAdmin'] = true;

  if (userInfo.groups.includes('PM')) outputUser['isPm'] = true;

  if (userInfo.groups.includes('PM Admin')) outputUser['isPmAdmin'] = true;
}

export const useRuntimePermissionCheck = (
  rightsData: any,
  permission: string
): { hasPermission: boolean; hasRuntimePermission: boolean } => {
  const hasPermission = rightsData?.permissions.includes(permission);
  const hasRuntimePermission = Object.keys(rightsData?.runtimePermissions).includes(permission);

  return { hasPermission, hasRuntimePermission };
};
