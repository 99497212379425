import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const LogoutIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.28788 0.746859C6.36992 0.664812 6.41602 0.553532 6.41602 0.4375C6.41602 0.321468 6.36992 0.210188 6.28788 0.128141C6.20583 0.0460936 6.09455 0 5.97852 0H2.91602C2.45189 0 2.00677 0.184374 1.67858 0.512563C1.35039 0.840752 1.16602 1.28587 1.16602 1.75V12.25C1.16602 12.7141 1.35039 13.1592 1.67858 13.4874C2.00677 13.8156 2.45189 14 2.91602 14H5.97852C6.09455 14 6.20583 13.9539 6.28788 13.8719C6.36992 13.7898 6.41602 13.6785 6.41602 13.5625C6.41602 13.4465 6.36992 13.3352 6.28788 13.2531C6.20583 13.1711 6.09455 13.125 5.97852 13.125L2.91602 13.125C2.68395 13.125 2.46139 13.0328 2.2973 12.8687C2.1332 12.7046 2.04102 12.4821 2.04102 12.25L2.04102 1.75C2.04102 1.51794 2.1332 1.29538 2.2973 1.13128C2.46139 0.967187 2.68395 0.875 2.91602 0.875L5.97852 0.875C6.09455 0.875 6.20583 0.828906 6.28788 0.746859ZM9.49652 9.32089C9.49652 9.4369 9.54259 9.54816 9.62461 9.63021C9.70666 9.71223 9.81792 9.7583 9.93392 9.7583C10.0499 9.7583 10.1612 9.71223 10.2432 9.63021L12.5594 7.31496C12.6001 7.27432 12.6324 7.22604 12.6545 7.17289C12.6765 7.11973 12.6879 7.06275 12.6879 7.00521C12.6879 6.94766 12.6765 6.89068 12.6545 6.83753C12.6324 6.78438 12.6001 6.7361 12.5594 6.69546L10.2432 4.38021C10.2029 4.33842 10.1546 4.30509 10.1012 4.28216C10.0478 4.25923 9.99044 4.24716 9.93235 4.24666C9.87426 4.24615 9.81665 4.25722 9.76288 4.27922C9.70912 4.30122 9.66027 4.33371 9.61919 4.37478C9.57811 4.41586 9.54563 4.46471 9.52363 4.51848C9.50163 4.57224 9.49056 4.62985 9.49107 4.68794C9.49157 4.74604 9.50364 4.80344 9.52657 4.85682C9.5495 4.9102 9.58283 4.95847 9.62461 4.99883L11.1929 6.56716H4.37435C4.25832 6.56716 4.14704 6.61325 4.06499 6.6953C3.98294 6.77735 3.93685 6.88863 3.93685 7.00466C3.93685 7.12069 3.98294 7.23197 4.06499 7.31402C4.14704 7.39607 4.25832 7.44216 4.37435 7.44216H11.194L9.62461 9.01158C9.54259 9.09363 9.49652 9.20488 9.49652 9.32089Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default LogoutIcon;
