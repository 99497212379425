import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IntakeGovernanceIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19.191 1.55H16.5924C16.3026 0.65 15.4931 0 14.5436 0H9.35647C8.397 0 7.59745 0.65 7.29761 1.55H4.82898C3.81954 1.55 3 2.42 3 3.49V22.06C3 23.13 3.81954 24 4.82898 24H19.181C20.1705 24 21.01 23.11 21.01 22.06V3.49C20.99 2.42 20.1805 1.55 19.191 1.55ZM8.63687 2.32C8.63687 1.89 8.95669 1.53 9.33648 1.53H14.5236C14.9134 1.53 15.2332 1.88 15.2332 2.32V3.3H8.63687V2.32ZM19.5208 22.06C19.5208 22.27 19.3509 22.47 19.161 22.47H4.82898C4.63909 22.47 4.46918 22.28 4.46918 22.06V3.49C4.46918 3.27 4.62909 3.08 4.82898 3.08H7.17768V3.79L7.18767 3.85C7.26763 4.41 7.71738 4.82 8.24708 4.82H15.683C16.2626 4.82 16.7324 4.33 16.7324 3.72V3.08H19.201C19.3709 3.08 19.5308 3.27 19.5308 3.49V22.06H19.5208ZM8.74681 9.5H15.2432C15.653 9.5 15.9928 9.84 15.9928 10.25C15.9928 10.66 15.653 11 15.2432 11H8.74681C8.33703 11 7.99722 10.66 7.99722 10.25C7.99722 9.84 8.33703 9.5 8.74681 9.5ZM8.74681 12.5H15.2432C15.653 12.5 15.9928 12.84 15.9928 13.25C15.9928 13.66 15.653 14 15.2432 14H8.74681C8.33703 14 7.99722 13.66 7.99722 13.25C7.99722 12.84 8.33703 12.5 8.74681 12.5ZM8.74681 15.5H15.2432C15.653 15.5 15.9928 15.84 15.9928 16.25C15.9928 16.66 15.653 17 15.2432 17H8.74681C8.33703 17 7.99722 16.66 7.99722 16.25C7.99722 15.84 8.33703 15.5 8.74681 15.5Z"
      //   fill="#007983"
    />
  </SvgIcon>
);

export default IntakeGovernanceIcon;
