module.exports = {
  DEV_MODE: false,
  IS_PREVIEW: false,
  AUTH_BASE_URL: 'https://api.appliedmed.com/AuthQA',
  // BASE_URL: 'https://api.appliedmed.com/MyApplied',
  BASE_URL: 'https://my-api-stg.appliedmed.com/MyApplied',
  CONTENT_URL: 'https://webassets.appliedmedical.com/content',

  // Auth0
  AUTH0DOMAIN: 'dev-kwk94vpz.us.auth0.com',
  AUTH0APPCLIENTID: 'GwXhjxVhk68wWRmVe2sE1uFUMzybkX44',
  AUTH0AUDIENCE: 'https://dev-kwk94vpz.us.auth0.com/api/v2/',
  ARC_AUTH0_DOMAIN: 'dev-kwk94vpz.us.auth0.com',
  ARC_AUTH0_CLIENTID: '9UnnoXpcg6S70WwSuxz9z9leogPgsAEJ',
  ARC_WEBAPI_AUDIENCE: 'https://api.appliedmedical.com/ARC',

  IT_BASE_URL: 'https://apistg.appliedmed.com/ITProjects-Playground',
  // internal PG api
  // IT_BASE_URL: 'https://internal-apiqa.appliedmed.com/ITProjects-Playground',
  IT_MVC_BASE_URL: 'https://app.appliedmed.com/ITProjects',
  GRAPHQL_ENDPOINT: 'https://corp-feserverless-funcapp-prod-eastus2.azurewebsites.net/api',
  DECISIONS_URL: 'https://decisionsqa3sa.appliedmed.com/Primary',
  SHAREPOINT_DRIVE_ID: 'b!FsZQ2jwwB0icMs1_hhQEgNs4eWYw5wxFqyo4vr5Pt6w-I_sjPeHdQLeJlj-IJtiM'
};
