import {
  DEFAULTFOLDER,
  IBatchPayload,
  IRoles,
  IUploadFiles,
  ROLES
} from 'components/FileTypes/Files.types';
import { getEnv } from 'services/apiEnv.service';
import {
  get,
  sharePointGet,
  sharePointPut,
  sharePointPost,
  sharePointDelete,
  sharePointPatch
} from 'services/request.service';

export const getFiles = async ({ path }: { path: string | null }) => {
  return (
    await sharePointGet({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/root:/${path}:/children`
    })
  ).data;
};

export const getItem = async ({ path }: { path: string | null }) => {
  return (
    await sharePointGet({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv('SHAREPOINT_DRIVE_ID')}/root:/${path}`
    })
  ).data;
};

export const getFilesModal = async ({ path }: { path: string | null }) => {
  return (
    await sharePointGet({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/root:/${path}:/children`
    })
  ).data;
};

export const getAllFiles = async ({ path }: { path: string | null }) => {
  return (
    await sharePointGet({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/root:/${path}:/children`
    })
  ).data;
};

export const batch = async ({ data }: { data: { requests: IBatchPayload[] } }) => {
  return (
    await sharePointPost({
      url: 'https://graph.microsoft.com/v1.0/$batch',
      data
    })
  ).data;
};

export const uploadFiles = async ({
  path,
  data,
  fileName,
  headers,
  fileIsOpenErrorToast
}: IUploadFiles) => {
  try {
    return (
      await sharePointPut({
        url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
          'SHAREPOINT_DRIVE_ID'
        )}/root:/${path}/${fileName}:/content`,
        data,
        headers
      })
    ).data;
  } catch (e) {
    if (!e.response) {
      fileIsOpenErrorToast();
      throw {
        message: 'Network Error. No Response',
        status: 444,
        response: null,
        isLoading: false
      };
    } else {
      throw e;
    }
  }
};

export const createFolder = async ({
  data,
  path
}: {
  data: { name: string; folder: {} };
  path: string;
}) => {
  return (
    await sharePointPost({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/root:/${path}:/children`,
      data,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  ).data;
};

export const getLink = async ({ fileId }: { fileId: string }) => {
  return (
    await sharePointGet({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/items/${fileId}/?select=id,@microsoft.graph.downloadUrl`,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    })
  ).data;
};

// might not need downloadFile since the url is provided in the files object from sharepoint
export const downloadFile = async ({ link }: { link: string }) => {
  return await get({
    url: link,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
  });
};

export const deleteFileNode = async ({ fileId }: { fileId: string }) => {
  return (
    await sharePointDelete({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/items/${fileId}`
    })
  ).data;
};

export const renameFile = async ({ fileId, data }: { fileId: string; data: object }) => {
  return (
    await sharePointPut({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/items/${fileId}`,
      data
    })
  ).data;
};

export const moveFile = async ({ fileId, parentId }: { fileId: string; parentId: string }) => {
  return (
    await sharePointPatch({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/items/${fileId}`,
      data: { parentReference: { id: parentId } }
    })
  ).data;
};

export const copyFile = async ({ fileId, parentId }: { fileId: string; parentId: string }) => {
  return (
    await sharePointPost({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/items/${fileId}/copy`,
      data: { parentReference: { id: parentId } }
    })
  ).data;
};

export const sendInvite = async ({
  fileId,
  emails,
  sendInvitation
}: {
  fileId: string;
  emails: { email: string }[];
  sendInvitation: boolean;
}) => {
  const data: IRoles = {
    requireSignIn: true,
    sendInvitation: sendInvitation,
    roles: [ROLES.Write],
    recipients: emails
  };
  return await sharePointPost({
    url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
      'SHAREPOINT_DRIVE_ID'
    )}/items/${fileId}/invite`,
    data
  });
};

export const getPermissions = async ({ fileId }: { fileId: string }) => {
  return (
    await sharePointGet({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/items/${fileId}/permissions`
    })
  ).data;
};

export const modifyPermissions = async ({
  fileId,
  permissionId,
  role
}: {
  fileId: string;
  permissionId: string;
  role: ROLES;
}) => {
  return (
    await sharePointPatch({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/items/${fileId}/permissions/${permissionId}`,
      data: {
        roles: role
      }
    })
  ).data;
};

export const deletePermission = async ({
  fileId,
  permissionId
}: {
  fileId: string;
  permissionId: string;
}) => {
  return (
    await sharePointDelete({
      url: `https://graph.microsoft.com/v1.0/drives/${getEnv(
        'SHAREPOINT_DRIVE_ID'
      )}/items/${fileId}/permissions/${permissionId}`
    })
  ).data;
};
