import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { opts } from '../../../config/auth0.config';
import { updateGlobalJWT } from 'services/auth.service';
import Loading from 'components/common/Loading/Loading';

const AuthWrapper = ({ children }) => {
  const {
    isLoading: auth0isLoading,
    isAuthenticated,
    error,
    user: auth0User,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect
  } = useAuth0();
  const [exchangingToken, setExchangingToken] = useState(false);

  const isTest = window?.Cypress ? true : false;

  useEffect(() => {
    async function auth() {
      if (isTest) {
        return;
      }
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          // console.log('auth0token ---- \n\n\n', `https://jwt.io/?value=${token}`);
          await updateGlobalJWT(token);
        }
      } catch (e) {
        console.log('Caught Authenticaton Error -> ', e?.error);
        if (e.error === 'consent_required') {
          const token = getAccessTokenWithPopup({
            authorizationParams: { ...opts, prompt: 'consent', display: 'popup' }
          });
          await updateGlobalJWT(token);
        } else if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
          const currPath = window.location.pathname + (window.location.search ?? '');
          setExchangingToken(true);
          loginWithRedirect({
            appState: {
              returnTo: currPath
            }
          });
        } else {
          console.log(e.error);
        }
      }
    }
    auth();

    // periodically checks and refreshes the token if necessary
    const refreshInterval = setInterval(auth, 60000);

    return () => clearInterval(refreshInterval);
  }, [getAccessTokenSilently, isAuthenticated]);

  if (auth0isLoading || exchangingToken) {
    return <Loading />;
  }
  if (error) {
    return (
      <div>
        <h1>{error.message}</h1>
      </div>
    );
  }
  if (!auth0isLoading) {
    return <>{children}</>;
  }
};

export default AuthWrapper;
