import { useQuery, useQueryClient } from 'react-query';
import { AdminAPI } from 'api';
import { TQuery } from 'api/api.types';

export const useGetDepartments = (config: TQuery = {}) => {
  return useQuery('departments', AdminAPI.getDepartments, { staleTime: 1000 * 60 * 5, ...config });
};

export const useGetDepartment = ({ args, config }: TQuery = {}) => {
  return useQuery(['department', args], () => AdminAPI.getDepartment(args), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetSubDepartments = ({ args, config }: TQuery = {}) => {
  return useQuery(['sub-departments', args], () => AdminAPI.getSubDepartments(args), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetSubDepartment = ({ args, config }: TQuery = {}) => {
  return useQuery(['sub-department', args], () => AdminAPI.getSubDepartment(args), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetAreas = ({ args, config }: TQuery = {}) => {
  return useQuery(['areas', args], () => AdminAPI.getAreas(args), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetArea = ({ args, config }: TQuery = {}) => {
  return useQuery(['area', args], () => AdminAPI.getArea(args), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetTeams = ({ args, config }: TQuery = {}) => {
  return useQuery(['teams', args], () => AdminAPI.getTeams(args), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetSubDepartmentMembers = ({ args, config }: TQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async () => {
    const { items, count } = await AdminAPI.getSubDepartmentMembers(args);
    queryClient.setQueryData(
      ['sub-department-members-headers', { subDeptId: args.subDeptId }],
      count
    );
    return items;
  };
  return useQuery(['sub-department-members', args], queryFn, {
    staleTime: Infinity,
    enabled: !!args.subDeptId,
    ...config
  });
};

export const useGetAreaMembers = ({ args, config }: TQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async () => {
    const { items, count } = await AdminAPI.getAreaMembers(args);
    queryClient.setQueryData(['area-members-headers', { areaId: args.areaId }], count);
    return items;
  };
  return useQuery(['area-members', args], queryFn, {
    staleTime: Infinity,
    enabled: !!args.areaId,
    ...config
  });
};

export const useGetSubDepartmentCandidates = ({ args, config }: TQuery = {}) => {
  return useQuery(
    ['sub-department-candidates', args],
    () => AdminAPI.getSubDepartmentCandidateList(args),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!args.subDeptId,
      ...config
    }
  );
};

export const useGetAreaCandidates = ({ args, config }: TQuery = {}) => {
  return useQuery(['area-candidates', args], () => AdminAPI.getAreaCandidateList(args), {
    staleTime: 1000 * 60 * 5,
    enabled: !!args.areaId,
    ...config
  });
};

export const useGetTeamsMembers = ({ args, config }: TQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async () => {
    const { items, count } = await AdminAPI.getTeamsMembers(args);
    queryClient.setQueryData(['teams-members-headers', { teamId: args.teamId }], count);
    return items;
  };
  return useQuery(['teams-members', args], queryFn, {
    staleTime: Infinity,
    enabled: !!args.teamId,
    ...config
  });
};

export const useGetTeamsCandidates = ({ args, config }: TQuery = {}) => {
  return useQuery(['teams-candidates', args], () => AdminAPI.getTeamsCandidateList(args), {
    staleTime: 1000 * 60 * 5,
    enabled: !!args.teamId,
    ...config
  });
};
