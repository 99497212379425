import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MaintenanceErrorIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 400 387" {...props}>
    <g clip-path="url(#clip0_1588_42742)">
      <path
        d="M378.135 259.399C377.563 258.713 377.148 257.91 376.92 257.046C376.692 256.182 376.655 255.278 376.813 254.399C376.971 253.519 377.319 252.685 377.833 251.955C378.348 251.224 379.016 250.616 379.79 250.173L375.797 228.818L386.183 233.191L388.383 252.799C389.04 254.152 389.179 255.7 388.774 257.149C388.369 258.598 387.447 259.847 386.184 260.661C384.921 261.474 383.404 261.795 381.92 261.563C380.437 261.33 379.09 260.561 378.135 259.399V259.399Z"
        fill="#FFB6B6"
      />
      <path
        d="M398.142 12.5273H1.72681C1.17452 12.5273 0.726807 12.9751 0.726807 13.5273V228.362C0.726807 228.915 1.17452 229.362 1.72681 229.362H398.142C398.694 229.362 399.142 228.915 399.142 228.362V13.5273C399.142 12.9751 398.694 12.5273 398.142 12.5273Z"
        fill="#E6E6E6"
      />
      <path d="M386.103 31.1211H13.7661V212.033H386.103V31.1211Z" fill="white" />
      <path
        d="M397.947 0H1.53271C0.98043 0 0.532715 0.447715 0.532715 1V18.413C0.532715 18.9653 0.980432 19.413 1.53272 19.413H397.947C398.5 19.413 398.947 18.9653 398.947 18.413V1C398.947 0.447715 398.5 0 397.947 0Z"
        fill="#4DA1A8"
      />
      <path d="M398.947 12.5996H0.5354V19.7996H398.947V12.5996Z" fill="#4DA1A8" />
      <path
        d="M14.9286 12.5771C16.9116 12.5771 18.5191 10.9662 18.5191 8.97898C18.5191 6.99179 16.9116 5.38086 14.9286 5.38086C12.9456 5.38086 11.3381 6.99179 11.3381 8.97898C11.3381 10.9662 12.9456 12.5771 14.9286 12.5771Z"
        fill="white"
      />
      <path
        d="M28.5568 12.5771C30.5397 12.5771 32.1472 10.9662 32.1472 8.97898C32.1472 6.99179 30.5397 5.38086 28.5568 5.38086C26.5738 5.38086 24.9663 6.99179 24.9663 8.97898C24.9663 10.9662 26.5738 12.5771 28.5568 12.5771Z"
        fill="white"
      />
      <path
        d="M42.1854 12.5771C44.1684 12.5771 45.7759 10.9662 45.7759 8.97898C45.7759 6.99179 44.1684 5.38086 42.1854 5.38086C40.2025 5.38086 38.595 6.99179 38.595 8.97898C38.595 10.9662 40.2025 12.5771 42.1854 12.5771Z"
        fill="white"
      />
      <path
        d="M38.9088 147.516C38.4103 147.517 37.9328 147.717 37.5809 148.071C37.2291 148.425 37.0315 148.904 37.0315 149.404C37.0315 149.903 37.2291 150.382 37.5809 150.736C37.9328 151.09 38.4103 151.29 38.9088 151.292H142.291C142.79 151.29 143.267 151.09 143.619 150.736C143.971 150.382 144.168 149.903 144.168 149.404C144.168 148.904 143.971 148.425 143.619 148.071C143.267 147.717 142.79 147.517 142.291 147.516H38.9088Z"
        fill="#E6E6E6"
      />
      <path
        d="M38.9088 158.846C38.4103 158.847 37.9328 159.047 37.5809 159.401C37.2291 159.755 37.0315 160.234 37.0315 160.734C37.0315 161.233 37.2291 161.713 37.5809 162.066C37.9328 162.42 38.4103 162.62 38.9088 162.622H91.6875C92.186 162.62 92.6635 162.42 93.0154 162.066C93.3673 161.713 93.5648 161.233 93.5648 160.734C93.5648 160.234 93.3673 159.755 93.0154 159.401C92.6635 159.047 92.186 158.847 91.6875 158.846H38.9088Z"
        fill="#E6E6E6"
      />
      <path
        d="M142.421 180.235H103.15C102.642 180.234 102.154 180.031 101.794 179.671C101.435 179.311 101.232 178.822 101.232 178.312V172.007C101.232 171.497 101.435 171.008 101.794 170.648C102.154 170.287 102.642 170.085 103.15 170.084H142.421C142.93 170.085 143.418 170.287 143.777 170.648C144.137 171.008 144.339 171.497 144.34 172.007V178.312C144.339 178.822 144.137 179.311 143.777 179.671C143.418 180.031 142.93 180.234 142.421 180.235V180.235Z"
        fill="#4DA1A8"
      />
      <path
        d="M142.421 114.573H103.15C102.642 114.572 102.154 114.369 101.794 114.009C101.435 113.648 101.232 113.16 101.232 112.65V106.344C101.232 105.835 101.435 105.346 101.794 104.986C102.154 104.625 102.642 104.422 103.15 104.422H142.421C142.93 104.422 143.418 104.625 143.777 104.986C144.137 105.346 144.339 105.835 144.34 106.344V112.65C144.339 113.16 144.137 113.648 143.777 114.009C143.418 114.369 142.93 114.572 142.421 114.573V114.573Z"
        fill="#007983"
      />
      <path
        d="M210.969 62.7617C210.496 62.7627 210.042 63.0228 209.708 63.4847C209.373 63.9466 209.186 64.5726 209.186 65.2254C209.186 65.8781 209.373 66.5041 209.708 66.9661C210.042 67.428 210.496 67.688 210.969 67.689H360.352C360.825 67.688 361.279 67.428 361.613 66.9661C361.947 66.5041 362.135 65.8781 362.135 65.2254C362.135 64.5726 361.947 63.9466 361.613 63.4847C361.279 63.0228 360.825 62.7627 360.352 62.7617H210.969Z"
        fill="#E6E6E6"
      />
      <path
        d="M210.662 77.5449C210.27 77.5459 209.895 77.8059 209.618 78.2679C209.341 78.7298 209.186 79.3558 209.186 80.0086C209.186 80.6613 209.341 81.2873 209.618 81.7493C209.895 82.2112 210.27 82.4712 210.662 82.4722H294.629C295.021 82.4712 295.396 82.2112 295.673 81.7493C295.95 81.2873 296.106 80.6613 296.106 80.0086C296.106 79.3558 295.95 78.7298 295.673 78.2679C295.396 77.8059 295.021 77.5459 294.629 77.5449H210.662Z"
        fill="#E6E6E6"
      />
      <path
        d="M210.969 91.9941C210.496 91.9952 210.042 92.2552 209.708 92.7171C209.373 93.179 209.186 93.805 209.186 94.4578C209.186 95.1105 209.373 95.7366 209.708 96.1985C210.042 96.6604 210.496 96.9204 210.969 96.9214H360.352C360.825 96.9204 361.279 96.6604 361.613 96.1985C361.947 95.7366 362.135 95.1105 362.135 94.4578C362.135 93.805 361.947 93.179 361.613 92.7171C361.279 92.2552 360.825 91.9952 360.352 91.9941H210.969Z"
        fill="#E6E6E6"
      />
      <path
        d="M210.662 106.777C210.27 106.778 209.895 107.038 209.618 107.5C209.341 107.962 209.186 108.588 209.186 109.241C209.186 109.894 209.341 110.52 209.618 110.982C209.895 111.444 210.27 111.704 210.662 111.705H294.629C295.021 111.704 295.396 111.444 295.673 110.982C295.95 110.52 296.106 109.894 296.106 109.241C296.106 108.588 295.95 107.962 295.673 107.5C295.396 107.038 295.021 106.778 294.629 106.777H210.662Z"
        fill="#E6E6E6"
      />
      <path
        d="M300.204 138.75H260.933C260.424 138.75 259.937 138.547 259.577 138.187C259.217 137.826 259.015 137.337 259.014 136.828V130.522C259.015 130.012 259.217 129.524 259.577 129.163C259.937 128.803 260.424 128.6 260.933 128.6H300.204C300.713 128.6 301.201 128.803 301.56 129.163C301.92 129.524 302.122 130.012 302.123 130.522V136.828C302.122 137.337 301.92 137.826 301.56 138.187C301.201 138.547 300.713 138.75 300.204 138.75V138.75Z"
        fill="#007983"
      />
      <path
        d="M83.435 65.4297C82.7372 65.4319 82.1727 66.3399 82.1741 67.4578C82.1755 68.5726 82.7392 69.4758 83.435 69.478H142.999C143.696 69.4758 144.261 68.5677 144.26 67.4498C144.258 66.3351 143.695 65.4319 142.999 65.4297H83.435Z"
        fill="#E4E4E4"
      />
      <path
        d="M83.435 77.1602C82.7372 77.1624 82.1727 78.0704 82.1741 79.1883C82.1755 80.3031 82.7392 81.2062 83.435 81.2085H142.999C143.696 81.2062 144.261 80.2982 144.26 79.1803C144.258 78.0655 143.695 77.1624 142.999 77.1602H83.435Z"
        fill="#E4E4E4"
      />
      <path
        d="M83.435 88.748C82.7372 88.7503 82.1727 89.6583 82.1741 90.7762C82.1755 91.891 82.7392 92.7941 83.435 92.7963H109.065C109.763 92.7941 110.328 91.8861 110.326 90.7682C110.325 89.6534 109.761 88.7502 109.065 88.748H83.435Z"
        fill="#E4E4E4"
      />
      <path
        d="M53.192 95.157C62.1186 95.157 69.3551 87.9051 69.3551 78.9594C69.3551 70.0137 62.1186 62.7617 53.192 62.7617C44.2653 62.7617 37.0288 70.0137 37.0288 78.9594C37.0288 87.9051 44.2653 95.157 53.192 95.157Z"
        fill="#E4E4E4"
      />
      <path
        d="M284.615 135.542C284.501 136.428 284.582 137.329 284.852 138.181C285.122 139.032 285.576 139.814 286.181 140.471C286.785 141.128 287.526 141.645 288.352 141.984C289.177 142.322 290.066 142.476 290.957 142.433L299.035 162.595L305.452 153.315L296.797 135.592C296.626 134.097 295.914 132.717 294.796 131.713C293.677 130.709 292.23 130.151 290.728 130.145C289.227 130.138 287.775 130.684 286.648 131.679C285.522 132.674 284.798 134.048 284.615 135.542Z"
        fill="#FFB6B6"
      />
      <path
        d="M375.693 378.02H368.256L364.717 349.27L375.695 349.27L375.693 378.02Z"
        fill="#FFB6B6"
      />
      <path
        d="M377.59 385.243L353.607 385.242V384.938C353.608 382.458 354.591 380.078 356.342 378.324C358.092 376.57 360.466 375.584 362.942 375.584H362.943L377.59 375.585L377.59 385.243Z"
        fill="#2F2E41"
      />
      <path
        d="M351.354 377.782L344.263 375.532L349.548 347.053L360.013 350.374L351.354 377.782Z"
        fill="#FFB6B6"
      />
      <path
        d="M350.987 385.245L328.123 377.991L328.214 377.701C328.961 375.336 330.616 373.365 332.813 372.222C335.01 371.079 337.57 370.857 339.931 371.606L339.931 371.606L353.896 376.037L350.987 385.245Z"
        fill="#2F2E41"
      />
      <path
        d="M342.545 237.507C342.545 237.507 331.509 254.872 335.409 270.37C339.308 285.869 343.555 315.66 343.555 315.66L364.699 366.088L380.461 365.443L364.809 305.987L365.428 266.234C365.428 266.234 376.307 247.235 374.188 241.321C372.069 235.407 342.545 237.507 342.545 237.507Z"
        fill="#1C1F21"
      />
      <path
        d="M378.598 243.714C378.598 243.714 379.956 260.941 375.709 268.237C371.463 275.532 366.938 303.301 366.938 303.301L359.72 358.837L344.769 353.961L357.518 291.739L367.538 242.795L378.598 243.714Z"
        fill="#1C1F21"
      />
      <path
        d="M383.9 160.322L372.235 152.286L354.483 151.77L348.301 157.843L338.867 162.671L337.993 241.321C337.993 241.321 366.244 254.891 381.442 248.341L388.753 187.073C391.787 174.913 383.9 160.322 383.9 160.322Z"
        fill="#99C9CD"
      />
      <path
        d="M341.236 183.689H310.121L289.678 148.425L302.971 143.148L314.228 163.216L330.522 161.739L340.695 161.246C342.326 161.167 343.954 161.445 345.465 162.063C346.977 162.68 348.335 163.621 349.446 164.82C350.557 166.018 351.393 167.445 351.896 169.002C352.399 170.558 352.556 172.205 352.357 173.829V173.829C352.021 176.548 350.706 179.051 348.658 180.867C346.61 182.682 343.971 183.686 341.236 183.689Z"
        fill="#99C9CD"
      />
      <path
        d="M364.806 145.292C373.307 145.292 380.198 138.386 380.198 129.868C380.198 121.349 373.307 114.443 364.806 114.443C356.306 114.443 349.415 121.349 349.415 129.868C349.415 138.386 356.306 145.292 364.806 145.292Z"
        fill="#FFB6B6"
      />
      <path
        d="M383.352 126.359C382.879 122.973 380.591 119.757 377.336 118.732C376.043 114.969 372.618 112.199 368.835 110.992C365.331 109.872 361.555 109.95 358.1 111.212C354.645 112.475 351.706 114.852 349.744 117.969C349.455 118.425 348.564 119.546 348.589 120.091C348.621 120.782 349.677 121.496 350.207 121.932C351.521 122.95 352.939 123.827 354.436 124.548C359.97 127.423 358.128 131.942 358.053 137.063C358.016 139.552 358.845 141.676 360.963 143.098C364.019 145.149 368.392 144.333 371.717 143.51C375.49 142.575 378.627 139.254 380.779 136.051C382.684 133.215 383.825 129.745 383.352 126.359Z"
        fill="#2F2E41"
      />
      <path d="M383.459 180.402L389.448 246.236L370.206 245.202L383.459 180.402Z" fill="#99C9CD" />
      <path
        d="M399.711 387H305.427C305.35 386.999 305.277 386.915 305.223 386.767C305.169 386.619 305.138 386.419 305.138 386.21C305.138 386.001 305.169 385.8 305.223 385.652C305.277 385.504 305.35 385.421 305.427 385.42H399.711C399.788 385.42 399.861 385.503 399.915 385.651C399.97 385.799 400 386 400 386.21C400 386.419 399.97 386.62 399.915 386.768C399.861 386.916 399.788 387 399.711 387V387Z"
        fill="#CACACA"
      />
      <path d="M14.6518 379.782H20.4208L23.1654 357.482H14.6506L14.6518 379.782Z" fill="#9E616A" />
      <path
        d="M13.1802 377.895L24.5418 377.895H24.5422C26.4625 377.895 28.304 378.659 29.6619 380.02C31.0197 381.381 31.7826 383.226 31.7827 385.15V385.386L13.1805 385.387L13.1802 377.895Z"
        fill="#2F2E41"
      />
      <path d="M67.8264 379.782H73.5954L76.34 357.482H67.8252L67.8264 379.782Z" fill="#9E616A" />
      <path
        d="M66.3545 377.895L77.7161 377.895H77.7165C79.6368 377.895 81.4783 378.659 82.8362 380.02C84.194 381.381 84.9569 383.226 84.957 385.15V385.386L66.3548 385.387L66.3545 377.895Z"
        fill="#2F2E41"
      />
      <path
        d="M60.8604 200.631L64.6249 224.681L28.8616 225.153L33.567 202.046L60.8604 200.631Z"
        fill="#3F3D56"
      />
      <path
        d="M63.5875 218.809L74.978 249.203L76.3898 375.163H66.743L43.6848 273.961L22.5089 372.521H13.0974L19.6855 261.7C19.6855 261.7 17.7427 239.457 27.6852 226.332L30.1053 219.325L63.5875 218.809Z"
        fill="#1C1F21"
      />
      <path
        d="M14.7286 242.503C15.7244 242.231 16.6486 241.743 17.436 241.075C18.2235 240.406 18.8551 239.572 19.2863 238.633C19.7176 237.693 19.938 236.67 19.932 235.636C19.9261 234.602 19.6939 233.581 19.2519 232.646L34.4663 182.938L23.2755 180.715L9.36042 229.44C7.84286 230.288 6.68852 231.665 6.11612 233.309C5.54373 234.953 5.59305 236.75 6.25475 238.361C6.91645 239.971 8.14455 241.282 9.70633 242.045C11.2681 242.809 13.0551 242.971 14.7286 242.503Z"
        fill="#9E616A"
      />
      <path
        d="M34.7947 213.913C34.5859 213.913 34.3776 213.892 34.1728 213.851V213.851C33.4842 213.713 32.8596 213.353 32.3945 212.826C31.9293 212.299 31.6491 211.633 31.5968 210.932C31.243 206.078 29.4902 197.629 26.3869 185.819C25.3393 181.805 25.5714 177.564 27.0505 173.689C28.5296 169.814 31.1816 166.5 34.6348 164.211C38.088 161.923 42.1692 160.775 46.3064 160.928C50.4435 161.081 54.4292 162.527 57.7046 165.065C59.8018 166.675 61.5522 168.693 62.8503 170.999C64.1484 173.305 64.9674 175.85 65.258 178.481C66.8003 192.092 63.8926 205.758 62.571 211.002C62.3973 211.687 62.0045 212.295 61.4527 212.735C60.901 213.174 60.2207 213.42 59.516 213.435L34.8632 213.912C34.8403 213.913 34.8176 213.913 34.7947 213.913Z"
        fill="#3F3D56"
      />
      <path
        d="M103.67 170.249C103.522 170.582 103.401 170.926 103.306 171.278L72.4481 181.786L66.4853 176.566L57.4478 184.555L67.1074 194.824C67.8878 195.654 68.9157 196.207 70.0367 196.402C71.1577 196.596 72.3114 196.421 73.3246 195.902L106.345 178.999C107.539 179.733 108.928 180.086 110.327 180.009C111.725 179.933 113.068 179.432 114.176 178.572C115.283 177.713 116.103 176.536 116.527 175.198C116.951 173.86 116.958 172.424 116.548 171.082C116.137 169.739 115.329 168.554 114.23 167.683C113.131 166.813 111.794 166.298 110.396 166.208C108.998 166.117 107.606 166.456 106.404 167.178C105.203 167.9 104.249 168.971 103.67 170.249V170.249Z"
        fill="#9E616A"
      />
      <path
        d="M71.3719 182.36L60.1228 192.206C59.8027 192.487 59.4261 192.694 59.0187 192.815C58.6113 192.937 58.1826 192.968 57.7619 192.909C57.3411 192.849 56.9382 192.699 56.5805 192.469C56.2228 192.239 55.9188 191.934 55.6891 191.576L47.6428 179.02C46.2202 177.371 45.5085 175.223 45.6638 173.049C45.8191 170.875 46.8287 168.851 48.4712 167.422C50.1137 165.993 52.2548 165.275 54.4248 165.426C56.5947 165.577 58.6163 166.585 60.0459 168.227L71.3449 177.873C71.6683 178.149 71.9285 178.492 72.1078 178.878C72.2871 179.264 72.3812 179.684 72.3838 180.11C72.3863 180.536 72.2973 180.957 72.1227 181.346C71.9481 181.734 71.692 182.08 71.372 182.36H71.3719Z"
        fill="#3F3D56"
      />
      <path
        d="M32.6887 195.665L18.787 190.192C18.3915 190.036 18.0341 189.797 17.7392 189.49C17.4443 189.184 17.2188 188.817 17.078 188.415C16.9373 188.013 16.8846 187.586 16.9236 187.162C16.9626 186.738 17.0923 186.327 17.304 185.958L24.7211 173.018C25.5274 170.995 27.1017 169.374 29.0987 168.512C31.0957 167.649 33.3524 167.616 35.374 168.419C37.3955 169.221 39.0169 170.795 39.8825 172.794C40.7482 174.793 40.7875 177.054 39.9918 179.082L36.6512 193.575C36.5554 193.99 36.3715 194.379 36.112 194.716C35.8526 195.053 35.5236 195.331 35.1476 195.529C34.7715 195.728 34.3572 195.842 33.9329 195.866C33.5086 195.889 33.0842 195.821 32.6887 195.665V195.665Z"
        fill="#3F3D56"
      />
      <path
        d="M42.9941 157.864C51.3455 157.864 58.1156 151.08 58.1156 142.71C58.1156 134.341 51.3455 127.557 42.9941 127.557C34.6427 127.557 27.8726 134.341 27.8726 142.71C27.8726 151.08 34.6427 157.864 42.9941 157.864Z"
        fill="#9E616A"
      />
      <path
        d="M110.316 387H-5.96867C-6.06306 386.999 -6.1535 386.915 -6.22014 386.767C-6.28679 386.619 -6.32422 386.419 -6.32422 386.21C-6.32422 386.001 -6.28679 385.8 -6.22014 385.652C-6.1535 385.504 -6.06306 385.421 -5.96867 385.42H110.316C110.411 385.421 110.501 385.504 110.568 385.652C110.634 385.8 110.672 386.001 110.672 386.21C110.672 386.419 110.634 386.619 110.568 386.767C110.501 386.915 110.411 386.999 110.316 387V387Z"
        fill="#CACACA"
      />
      <path
        d="M53.3964 136.619C56.6407 136.601 59.8851 136.582 63.1294 136.564C63.3602 134.348 62.3761 132.144 60.9423 130.441C59.4313 128.803 57.708 127.375 55.8184 126.196C53.9575 124.916 52.0296 123.608 49.8173 123.16C47.9629 122.785 46.0463 123.045 44.1729 123.307C41.6884 123.653 39.204 123.999 36.7198 124.345C33.9926 124.725 31.1393 125.155 28.9244 126.794C25.9289 129.01 24.7831 132.894 23.8206 136.499L18.4929 156.45L49.9619 159.758C51.1002 159.954 52.2663 159.92 53.3914 159.658C53.9516 159.517 54.4582 159.214 54.8481 158.787C55.238 158.359 55.494 157.827 55.5844 157.255C55.675 155.969 54.6569 154.916 53.8301 153.928C51.8578 151.479 50.6739 148.488 50.435 145.35C50.1962 142.212 50.9137 139.075 52.4926 136.354L53.3964 136.619Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <clipPath id="clip0_1588_42742">
        <rect width="400" height="387" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MaintenanceErrorIcon;
