import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PasteIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M11.1895 0.904167H9.67453C9.50555 0.379167 9.03357 0 8.48002 0H5.45588C4.8965 0 4.43036 0.379167 4.25555 0.904167H2.81632C2.2278 0.904167 1.75 1.41167 1.75 2.03583V12.8683C1.75 13.4925 2.2278 14 2.81632 14H11.1837C11.7605 14 12.25 13.4808 12.25 12.8683V2.03583C12.2383 1.41167 11.7664 0.904167 11.1895 0.904167ZM5.03635 1.35333C5.03635 1.1025 5.22281 0.8925 5.44423 0.8925H8.46837C8.69562 0.8925 8.88208 1.09667 8.88208 1.35333V1.925H5.03635V1.35333ZM11.3818 12.8683C11.3818 12.9908 11.2827 13.1075 11.172 13.1075H2.81632C2.7056 13.1075 2.60655 12.9967 2.60655 12.8683V2.03583C2.60655 1.9075 2.69978 1.79667 2.81632 1.79667H4.18563V2.21083L4.19145 2.24583C4.23807 2.5725 4.50028 2.81167 4.8091 2.81167H9.14428C9.48224 2.81167 9.7561 2.52583 9.7561 2.17V1.79667H11.1953C11.2944 1.79667 11.3876 1.9075 11.3876 2.03583V12.8683H11.3818Z"
      fill="#7D858C"
    />
  </SvgIcon>
);

export default PasteIcon;
