import { useMutation, UseMutationOptions } from 'react-query';
import { RequestStatusAPI } from 'api';

export const useUpdateRequestStatus = (config: UseMutationOptions<any>) => {
  return useMutation(RequestStatusAPI.updateRequestStatus, { ...config });
};

export const useUpdateServiceDeskTransfer = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestStatusAPI.updateServiceDeskTransfer, { ...config });
};

export const useUpdateRequestStatusApproval = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestStatusAPI.updateRequestStatusApproval, { ...config });
};
