import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const TaskIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M11.5733 1.225H10.1325V1.785C10.1325 2.22833 9.78833 2.59 9.36833 2.59H4.63167C4.20583 2.59 3.8675 2.22833 3.8675 1.785V1.225H2.44417C2.065 1.225 1.75 1.55167 1.75 1.9425V13.2825C1.75 13.6733 2.065 14 2.44417 14H11.5617C11.935 14 12.2558 13.6733 12.2558 13.2825V1.9425C12.25 1.54583 11.9467 1.225 11.5733 1.225ZM9.24 6.7025L6.41083 9.51417C6.2475 9.6775 6.11333 9.6775 5.93833 9.51417C5.54167 9.12917 5.15667 8.715 4.77167 8.3125C4.4625 8.015 4.935 7.5425 5.2325 7.85167L6.195 8.79667L8.7675 6.22417C9.07667 5.915 9.53167 6.3875 9.24 6.7025ZM9.5375 0.950833V1.81417C9.5375 1.925 9.45 2.01833 9.33917 2.01833H4.62C4.515 2.01833 4.4275 1.93667 4.41 1.82V0.950833C4.41 0.425833 4.81833 0 5.32583 0H8.62167C9.12333 0 9.5375 0.425833 9.5375 0.950833Z"
      fill="#008BC7"
    />
  </SvgIcon>
);

export default TaskIcon;
