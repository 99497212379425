// import * as api from './draft.api';
import * as queries from './draft.queries';
import * as api from './draft.api';
import * as mutations from './draft.mutations';

export * from './draft.api';
export * from './draft.queries';
export * from './draft.mutations';

export default { ...api, ...queries, ...mutations };
