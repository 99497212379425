import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const FieldSeparatorIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 32 1" fill="none" {...props}>
    <line
      x1="0.5"
      y1="0.5"
      x2="31.5"
      y2="0.5"
      stroke="#AEB3B7"
      strokeLinecap="square"
      strokeDasharray="4 4"
    />
  </SvgIcon>
);

export default FieldSeparatorIcon;
