import { useEffect, useRef } from 'react';

// Only use if you're using a strictly only dismount action
const useUnmount = (fn: () => any): void => {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => () => fnRef.current(), []);
};

export default useUnmount;
