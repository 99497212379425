import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CustomCheckboxIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.201 8.94624C4.56672 8.28025 3.55188 9.29509 4.21787 9.92937C5.04243 10.7856 5.86699 11.6736 6.72326 12.4982C7.10383 12.847 7.38926 12.847 7.73811 12.4982L13.7955 6.47256C14.4297 5.80657 13.4466 4.79172 12.7806 5.45772L7.2624 10.9759L5.201 8.94624Z"
    />
  </SvgIcon>
);

export default CustomCheckboxIcon;
