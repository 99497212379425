import * as api from './programs.api';
// import * as constants from './programs.constants';
import * as queries from './programs.queries';
import * as mutations from './programs.mutations';
// import * as types from './programs.types';

export * from './programs.api';
// export * from './programs.constants';
export * from './programs.queries';
export * from './programs.mutations';
// export * from './programs.types';
// export * from './programs.constants';

export default { ...api, ...mutations, ...queries };
// export default { ...api, ...constants, ...queries, ...mutations, types };
