import { useState, useEffect } from 'react';

const getWidth = () => {
  return Math.max(window?.document?.documentElement?.clientWidth, window.innerWidth) || 0;
};

const getHeight = () => {
  return Math.max(window?.document?.documentElement?.clientHeight, window.innerHeight) || 0;
};

const useViewport = () => {
  const [state, setState] = useState({ width: getWidth(), height: getHeight() });

  useEffect(() => {
    const handleResize = () => {
      setState({ width: getWidth(), height: getHeight() });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return state;
};

export default useViewport;
